/*eslint-disable*/
import React, { ReactElement } from 'react';
import { TableBody, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Row from './TableRow';
import Cell, { ICellProps } from './TableCell';
import { IColumn } from './types';
import Skeleton from '../Skeleton';
import { selectedRow } from './utils';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  stateText: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: '150%',
    color: '#425364',
    textTransform: 'capitalize',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.typography.pxToRem(30),
  },
  textDetails: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(16),
    color: '#464665',
    textTransform: 'capitalize',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.typography.pxToRem(10),
  },
  emptyState: {
    marginLeft: theme.typography.pxToRem(20),
  },
  alignCenter: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginLeft: '-2rem',
    [theme.breakpoints.only('md')]: {
      position: 'relative',
      left: '4.5rem',
    },
    [theme.breakpoints.only('sm')]: {
      position: 'relative',
      left: '3rem',
    },
    [theme.breakpoints.only('xs')]: {
      position: 'relative',
      left: '-3.5rem',
    },
  },
  row: {
    cursor: ({ onClick }: { onClick: (row: unknown) => void }) =>
      onClick && 'pointer',
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: '#F9FAFB',
    },
  },
}));
interface ITableBodyProps<T> extends ICellProps {
  data: T[];
  columns: IColumn<T>[];
  loading: boolean;
  sorting?: any;
  stateText?: string;
  EmptyState?: string;
  textDetails?: string;
  className?: string;
  onClick?: (row: T) => void;
  onMouseEnterRow?: (row: T) => void;
  onMouseLeaveRow?: (row: T) => void;
}

export default function TableBodyRow<T>({
  data,
  columns,
  loading,
  stateText,
  EmptyState,
  textDetails,
  type,
  className,
  onClick,
  onMouseEnterRow,
  onMouseLeaveRow,
}: ITableBodyProps<T>): ReactElement {
  const classes = useStyles({ onClick });
  const { t } = useTranslation();

  const formatValue = (row, id, format) => {
    const value = (row as any)[id];
    if (format) {
      const formattedValue = format(value, row);
      return typeof formattedValue === 'string'
        ? t(formattedValue)
        : formattedValue;
    }
    return value;
  };

  const Rows =
    data.length > 0 ? (
      data.map((row: T, index: number) => (
        <Row
          data-cy="row"
          key={`${row}${index}`}
          type={type}
          className={classes.row}
          onMouseEnter={() => onMouseEnterRow(row)}
          onMouseLeave={() => onMouseLeaveRow(null)}
        >
          {columns.map(({ id, format, align }: IColumn<T>) => (
            <Cell
              data-cy="table-row"
              key={id}
              type={type}
              align={align}
              size="small"
              borderTop={selectedRow(row)}
              borderBottom={selectedRow(row)}
              borderRight={selectedRow(row)}
              borderLeft={selectedRow(row)}
              onClick={() => onClick(row)}
            >
              <Skeleton loading={loading}>
                <Box>{formatValue(row, id, format)}</Box>
              </Skeleton>
            </Cell>
          ))}
        </Row>
      ))
    ) : (
      <Row data-cy="row" type={type} className={classes.row}>
        <Cell
          data-cy="table-row"
          type={'cellWidthOutBorder'}
          align="center"
          className={clsx(className, classes.tableCell)}
        >
          <img src={EmptyState} className={classes.emptyState} />
          <Typography className={classes.stateText}>{stateText}</Typography>
          <span className={classes.textDetails}>{textDetails}</span>
        </Cell>
      </Row>
    );
  if (loading)
    return (
      <>
        {[1, 2, 3].map(() => (
          <Row
            data-cy="row"
            type={type}
            style={{ width: '100%' }}
            className={classes.row}
          >
            {columns?.map(({ id, format, align }: any) => (
              <Cell
                data-cy="table-row"
                key={id}
                type={type}
                align={align}
                size="small"
              >
                <Skeleton loading={loading} height={30} width="100%">
                  <Typography />
                </Skeleton>
              </Cell>
            ))}
          </Row>
        ))}
      </>
    );
  return <TableBody>{Rows}</TableBody>;
}
