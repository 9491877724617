import { gql, useMutation } from '@apollo/client';
import { STRIPE_ACCOUNT } from 'pages/AdminPage/Payment/Payouts';
import { GET_DOCTOR } from 'pages/DoctorProfilePage/graphql';

export const CREATE_STRIPE_ACCOUNT = gql`
  mutation createStripeAccountDoctor($adressInput: DoctorEditInput) {
    createStripeAccountDoctor(adressInput: $adressInput) {
      stripeAccountId
    }
  }
`;

export default function useCreateStripeAccount(DoctorId) {
  const [createStripeAccountDoctor, { data, error, loading }] = useMutation(
    CREATE_STRIPE_ACCOUNT,
    {
      refetchQueries: [
        { query: GET_DOCTOR, variables: { DoctorId } },
        { query: STRIPE_ACCOUNT, variables: { doctor: DoctorId } },
      ],
    }
  );

  return { createStripeAccountDoctor, data, error, loading };
}
