import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import ErrorIcon from '@material-ui/icons/Error';
import { InfoBlock } from 'pages/AdminPage/TabsContent/InfoBlock';
import Skeleton from 'components/ui/Skeleton';
import { IinputBoxProps } from 'pages/AdminPage/TabsContent/types';
import Input from 'components/ui/Inputs';
import clsx from 'clsx';
import { useStyles } from 'pages/AdminPage/TabsContent/style';
import { yupResolver } from '@hookform/resolvers';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import { useMutation, useQuery } from '@apollo/client';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { BlockNames } from 'pages/AdminPage/TabsContent/PersonalInformation';
import { timeConverter } from 'pages/AdminPage/TimeConverter';
import { EDIT_PATIENT_OWNER } from 'pages/PatientProfile/GeneralInfoPatient';
import { GET_PATIENT_AUTH } from 'contexts/AuthContext';
import { useSnackbar } from 'hooks/useSnackbar';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';

export const legalNameSchema = yup.object().shape({
  firstname: yup.string().required('First Name is a required field'),
  lastname: yup.string().required('Last Name is a required field'),
});

export const emailSchema = yup.object().shape({
  email: yup.string().email().required('Email is a required field'),
});

export const addressSchema = yup.object().shape({
  country: yup.string().required('Country is a required field'),
  city: yup.string().required('City is a required field'),
  zipcode: yup.string().required('Zip code is a required field'),
});

export const extraSchema = yup.object().shape({
  birthday: yup.string().required('Date of Birth is a required field'),
});
export const birthSchema = yup.object().shape({
  birthday: yup.string().required('Date of Birth is a required field'),
});
export const ContactInfo = () => {
  const { patient } = useAuth();
  const { data, loading } = useQuery(GET_PATIENT, {
    variables: { patientId: patient?._id },
  });
  const {
    description,
    email,
    phone,
    firstname,
    lastname,
    birthday,
    middlename,
    country,
    city,
    suite,
    picture,
    createdAt,
    zipcode,
    completed,
    bloodType,
    state,
    address,
    gender,
    emergencyName,
    emergencyEmail,
    emergencyPhone,
    religion,
    relationshipStatus,
    employmentStatus,
    relationship,
    preferredLanguage,
    timeZone: timezone,
    pronouns,
    raceAndEthnicity,
  } = data?.patient || {};

  const classes = useStyles();
  const { t } = useTranslation();
  const [editableBlock, setEditableBlock] = useState(null);
  const [currentSchema, setCurrentSchema] = useState<any>();
  const { register, control, handleSubmit, reset, errors, watch } = useForm({
    // resolver: yupResolver(currentSchema),
    defaultValues: {
      description,
      timezone,
      email,
      phone,
      firstname,
      lastname,
      // birthday,
      middlename,
      country,
      city,
      suite,
      picture,
      createdAt,
      zipcode,
      completed,
      bloodType,
      state,
      address,
      gender,
      emergencyName,
      emergencyEmail,
      emergencyPhone,
      religion,
      relationshipStatus,
      employmentStatus,
      raceAndEthnicity,
      relationship,
      preferredLanguage,
      pronouns,
    },
  });
  useEffect(() => {
    if (data?.patient) {
      reset({
        description,
        email,
        phone,
        firstname,
        lastname,
        timezone,
        // birthday,
        middlename,
        country,
        city,
        suite,
        picture,
        createdAt,
        zipcode,
        completed,
        bloodType,
        state,
        address,
        gender,
        emergencyName,
        emergencyEmail,
        emergencyPhone,
        religion,
        relationshipStatus,
        employmentStatus,
        raceAndEthnicity,
        relationship,
        preferredLanguage,
        pronouns,
      });
    }
  }, [reset, data?.patient, t]);
  // useEffect(() => {
  //   switch (editableBlock) {
  //     case BlockNames.legal:
  //       setCurrentSchema(legalNameSchema);
  //       break;
  //     case BlockNames.email:
  //       setCurrentSchema(emailSchema);
  //       break;
  //     case BlockNames.address:
  //       setCurrentSchema(addressSchema);
  //       break;
  //     case BlockNames.extra:
  //       setCurrentSchema(extraSchema);
  //       break;
  //     case BlockNames.birth:
  //       setCurrentSchema(birthSchema);
  //       break;
  //     default:
  //       setCurrentSchema(yup.object().shape({}));
  //   }
  // }, [editableBlock]);
  const commonInputProps = {
    inputRef: register,
    variant: 'filled',
    control,
  };
  const InputBox = ({
    labelName,
    type,
    name,
    datacy,
    className,
    defaultValue,
    options,
    ...props
  }: IinputBoxProps) => {
    return (
      <Box>
        <Skeleton loading={loading}>
          <InputLabel className={clsx(className, classes.label)}>
            {labelName}
          </InputLabel>
        </Skeleton>
        <Skeleton loading={loading}>
          <Input
            className={clsx(classes.input, classes.marginTop)}
            type={type}
            name={name}
            data-cy={datacy}
            {...commonInputProps}
            defaultValue={defaultValue}
            options={options}
            helperText={
              (errors as any)[name] && (
                <FormHelperText className={classes.error}>
                  <ErrorIcon className={classes.errorIcon} />
                  {(errors as any)[name].message}
                </FormHelperText>
              )
            }
            {...props}
          />
        </Skeleton>
      </Box>
    );
  };
  const defaultValuesCountry = {
    label: data?.patient?.country,
  };
  const { triggerSnack } = useSnackbar();

  const [editPatient, { loading: loadingMutation }] = useMutation(
    EDIT_PATIENT_OWNER,
    {
      refetchQueries: [{ query: GET_PATIENT_AUTH }],
      awaitRefetchQueries: false,
    }
  );
  const onSubmit = async (formData) => {
    try {
      await editPatient({
        variables: {
          patientId: patient?._id,
          EditPatientInput: formData,
          picture: undefined,
        },
      });
      setEditableBlock(null);
      triggerSnack();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Box mt={'1rem'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={10}>
          <Grid item xl={6} lg={6}>
            <Text
              i18nKey="contactInformation"
              className={classes.titleContainer}
            >
              Contact information
            </Text>
            <InfoBlock
              title={t('Legal name')}
              description={t(
                'This is the name as it appears on your official documents'
              )}
              content={`${firstname || '-'} ${lastname || '-'}`}
              blockName={BlockNames.legal}
              isEditable={editableBlock === BlockNames.legal}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              buttonLoading={loadingMutation}
            >
              <Grid container spacing={1}>
                <Grid item xl={6} lg={6}>
                  <InputBox
                    labelName={t('First name')}
                    type="text"
                    name="firstname"
                    datacy="firstname-input"
                  />
                </Grid>
                <Grid item xl={6} lg={6}>
                  <InputBox
                    labelName={t('Last name')}
                    type="text"
                    name="lastname"
                    datacy="firstname-input"
                  />
                </Grid>
              </Grid>
            </InfoBlock>
            <InfoBlock
              title={t('Email')}
              content={`${email || '-'}`}
              blockName={BlockNames.email}
              isEditable={editableBlock === BlockNames.email}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              buttonLoading={loadingMutation}
            >
              <Grid container spacing={1}>
                <Grid item xl={12} lg={12}>
                  <InputBox
                    labelName={t('Email')}
                    type="text"
                    name="email"
                    datacy="firstname-input"
                  />
                </Grid>
              </Grid>
            </InfoBlock>
            <InfoBlock
              title={t('Phone number')}
              content={phone}
              blockName={BlockNames.phone}
              isEditable={editableBlock === BlockNames.phone}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              buttonLoading={loadingMutation}
            >
              <Input
                type="phone"
                name="phone"
                data-cy="phone-input"
                {...commonInputProps}
                helperText={
                  errors.phone && (
                    <FormHelperText className={classes.error}>
                      <ErrorIcon className={classes.errorIcon} />
                      {errors?.phone?.message}
                    </FormHelperText>
                  )
                }
              />
            </InfoBlock>
            <InfoBlock
              title={t('Address')}
              content={
                !address && !city && !suite && !zipcode && !state
                  ? '-'
                  : `${address || ''} ${city || ''} ${suite || ''} ${
                      zipcode || ''
                    } ${state || ''}`
              }
              extraContent={`${country || '-'}`}
              description={t(
                'Use a permanent address where you can receive mail.'
              )}
              blockName={BlockNames.address}
              isEditable={editableBlock === BlockNames.address}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              buttonLoading={loadingMutation}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                  <Skeleton loading={loading}>
                    <InputLabel className={clsx(classes.label)}>
                      <Text className={classes.label} i18nKey="country">
                        Country
                      </Text>
                    </InputLabel>
                  </Skeleton>
                  <Skeleton loading={loading}>
                    <Input
                      className={clsx(classes.input, classes.marginTop)}
                      type="country"
                      name="country"
                      data-cy="country-input"
                      {...commonInputProps}
                      defaultValue={defaultValuesCountry}
                      required
                    />
                  </Skeleton>
                  <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                    <Skeleton loading={loading}>
                      <InputLabel
                        className={clsx(classes.label, classes.marginTop2)}
                      >
                        <Text
                          className={classes.label}
                          i18nKey="Street address 1"
                        >
                          Street address 1
                        </Text>
                      </InputLabel>
                    </Skeleton>
                    <Skeleton loading={loading}>
                      <Input
                        className={clsx(classes.longInput, classes.marginTop)}
                        type="text"
                        name="address"
                        {...commonInputProps}
                      />
                    </Skeleton>
                  </Grid>
                  <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                    <Skeleton loading={loading}>
                      <InputLabel
                        className={clsx(classes.label, classes.marginTop2)}
                      >
                        <Text
                          className={classes.label}
                          i18nKey="Street address 2"
                        >
                          Street address 2
                        </Text>
                      </InputLabel>
                    </Skeleton>
                    <Skeleton loading={loading}>
                      <Input
                        className={clsx(classes.longInput, classes.marginTop)}
                        type="text"
                        name="suite"
                        {...commonInputProps}
                      />
                    </Skeleton>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                      <InputBox
                        labelName={t('City')}
                        className={classes.marginTop2}
                        type="text"
                        name="city"
                        datacy="city-input"
                        defaultValue={city}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                      <InputBox
                        labelName={t('State/Province')}
                        className={classes.marginTop2}
                        type="text"
                        name="state"
                        datacy="provinceState-input"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                    <InputBox
                      labelName={t('Zip code')}
                      className={classes.marginTop2}
                      type="number"
                      name="zipcode"
                      datacy="zipcode-input"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </InfoBlock>
            <InfoBlock
              title={t('Preferred language')}
              content={t(preferredLanguage)}
              blockName={BlockNames.language}
              isEditable={editableBlock === BlockNames.language}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              buttonLoading={loadingMutation}

              // isBorderBottom={false}
            >
              <>
                <Skeleton loading={loading}>
                  <InputLabel
                    style={{
                      marginBottom: '12.04px',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 500,
                      fontStyle: 'normal',
                      fontSize: '1rem',
                      color: '#425364',
                    }}
                  >
                    <Text className={classes.label} i18nKey="preferredLanguage">
                      Preferred language
                    </Text>
                  </InputLabel>
                </Skeleton>
                <Skeleton loading={loading}>
                  <Input
                    type="select"
                    name="preferredLanguage"
                    data-cy="gender-input"
                    {...commonInputProps}
                    options={[
                      'english',
                      'spanish',
                      'french',
                      'german',
                      'chinese',
                      'hindi',
                      'arabic',
                      'russian',
                      'portuguese',
                      'japanese',
                      'korean',
                      'italian',
                      'other',
                    ]}
                    // defaultValue={preferredLanguage}
                  />
                </Skeleton>
              </>
            </InfoBlock>
            <InfoBlock
              title={t('Emergency contact')}
              content={emergencyName}
              extraContent={emergencyPhone}
              blockName={BlockNames.extra}
              isEditable={editableBlock === BlockNames.extra}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              isBorderBottom={false}
              buttonLoading={loadingMutation}
            >
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6}>
                  <InputBox
                    labelName={t('Name')}
                    type="text"
                    name="emergencyName"
                    datacy="firstname-input"
                  />
                </Grid>
                <Grid item xl={6} lg={6}>
                  <InputBox
                    labelName={t('Email')}
                    type="text"
                    name="emergencyEmail"
                    datacy="firstname-input"
                  />
                </Grid>
                <Grid item xl={6} lg={6}>
                  <Skeleton loading={loading}>
                    <InputLabel
                      style={{
                        marginBottom: '12.04px',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        fontSize: '1rem',
                        color: '#425364',
                      }}
                    >
                      <Text className={classes.label} i18nKey="phoneNumber">
                        Phone number
                      </Text>
                    </InputLabel>
                  </Skeleton>
                  <Input
                    type="phone"
                    name="emergencyPhone"
                    data-cy="phone-input"
                    {...commonInputProps}
                    helperText={
                      errors.emergencyPhone && (
                        <FormHelperText className={classes.error}>
                          <ErrorIcon className={classes.errorIcon} />
                          {errors?.emergencyPhone?.message}
                        </FormHelperText>
                      )
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6}>
                  <Skeleton loading={loading}>
                    <InputLabel
                      style={{
                        marginBottom: '12.04px',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        fontSize: '1rem',
                        color: '#425364',
                      }}
                    >
                      <Text className={classes.label} i18nKey="relationship">
                        Relationship
                      </Text>
                    </InputLabel>
                  </Skeleton>
                  <Input
                    type="select"
                    name="relationship"
                    data-cy="gender-input"
                    {...commonInputProps}
                    options={[
                      'spouse',
                      'parent',
                      'child',
                      'sibling',
                      'partner',
                      'friend',
                      'guardian',
                      'relative',
                      'neighbor',
                      'colleague',
                      'healthcare proxy',
                    ]}
                    // defaultValue={relationship}
                  />
                </Grid>
              </Grid>
            </InfoBlock>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
