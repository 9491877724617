import useCreateQuestionnaire from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreateQuestionnaire';
import { IQuestion } from 'pages/FormsPage/FormsPage';
import { DOCTOR_TEMPLATES, QUESTIONNAIRE_BY_FILTER } from './Questionaires';
import useCreateQuestionnairebyPatient from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreateQuestionnaireByPatient';
import { DOCTOR_TEMPLATES_BY_FORM } from 'pages/AdminPage/TabsContent/ManageTemplates';

function useAssignQuestionaire(isOne = true) {
  const [handleCreateQuestionnaire] = useCreateQuestionnaire();
  const [handleCreateQuestionnaireByPatient, { loading }] =
    useCreateQuestionnairebyPatient();

  const handleAssignQuestionaire = async (
    doctorId: string,
    patientId: string,
    title: string,
    description: string,
    formType?: string,
    blocks?: string,
    templateId?: string,
    patients?: [string]
  ) => {
    const payload = {
      title,
      description,
      assignedAt: new Date(),
      doctor: doctorId,
      patient: patientId,
      formType,
      blocks,
      templateId,
    };
    if (isOne) {
      await handleCreateQuestionnaire({
        variables: { questionnaireInput: payload },
        refetchQueries: [
          {
            query: QUESTIONNAIRE_BY_FILTER,
            variables: { patientId, page: '1', limit: '5' },
          },
        ],
      });
    } else {
      await handleCreateQuestionnaireByPatient({
        variables: { questionnaireInput: payload, patients },
        refetchQueries: [
          {
            query: QUESTIONNAIRE_BY_FILTER,
            variables: { patientId, page: '1', limit: '5' },
          },
          {
            query: DOCTOR_TEMPLATES,
            variables: {
              isAll: true,
            },
          },
          {
            query: DOCTOR_TEMPLATES_BY_FORM,
            variables: {
              isAll: true,
            },
          },
        ],
      });
    }
  };
  return { handleAssignQuestionaire, loading };
}

export default useAssignQuestionaire;
