import React, { ReactElement, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { FormInput } from 'components/ui/Inputs/FormInput';
import { Box, Typography, Avatar, InputAdornment } from '@material-ui/core';
import { ReactComponent as ExpandMore } from 'assets/dashboard/down.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 221,
      fontSize: 13,
    },
    chip: {
      backgroundColor: 'white',
      color: '#344054',
      border: '1px solid #D0D5DD',
      borderRadius: '6px',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      marginRight: theme.spacing(1),
      '& .MuiChip-deleteIcon': {
        fill: '#425364',
      },
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
    },
    inputRoot: {
      border: '1px solid #D0D5DD',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      maxHeight: '44px',
      minHeight: '44px',
      borderRadius: '8px',
      color: '#425364',
      textTransform: 'capitalize',
      position: 'relative',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'left',
      marginTop: '5px',
      justifyContent: 'space-between',
      width: '100%',
      '&.Mui-focused': {
        border: '1px solid #D0D5DD',
      },
      '& .MuiFilledInput-underline:before': {
        border: '1px solid #D0D5DD',
      },
      '& .MuiFilledInput-underline:after': {
        border: '1px solid #D0D5DD',
      },
    },
    inputFocused: {
      border: '1px solid #D0D5DD',
    },
  })
);

export interface Option {
  _id: string;
  picture: string;
  firstname: string;
  lastname: string;
}

export interface IAutoCompleteAddUsersProps {
  values: Option[];
  setValues: (values: Option[]) => void;
  options: Option[];
  renderPreviewWrapper?: (content: any) => ReactElement;
  hasErrors?: boolean;
  multiple?: boolean;
  placeholder?: string;
}

const filter = createFilterOptions<Option>();

export default function AutoCompleteAddUsers({
  values,
  setValues,
  options,
  renderPreviewWrapper,
  hasErrors,
  multiple = false,
  placeholder,
  ...props
}: IAutoCompleteAddUsersProps): ReactElement {
  const classes = useStyles();
  const [pendingValue, setPendingValue] = React.useState<any[]>(values);

  useEffect(() => {
    setPendingValue(values);
  }, [values]);

  const handleChipDelete = (deletedOption: Option) => {
    const newPendingValues =
      pendingValue &&
      pendingValue.filter((option) => option?._id !== deletedOption?._id);
    setPendingValue(newPendingValues);
    setValues(newPendingValues);
  };

  return (
    <>
      <Autocomplete
        id="autocomplete-users"
        disableClearable
        multiple={multiple}
        onClose={() => setValues(pendingValue)}
        renderTags={(value: Option[]) =>
          value.map((option) => (
            <Chip
              style={{
                marginTop: '3px',
              }}
              avatar={<Avatar src={option?.picture} />}
              label={
                <Box display="flex" alignItems="center">
                  <Typography>{`${option?.firstname} ${option?.lastname}`}</Typography>
                </Box>
              }
              key={option?._id}
              onDelete={() => handleChipDelete(option)}
              className={classes.chip}
            />
          ))
        }
        classes={{
          option: classes.option,
        }}
        value={pendingValue}
        onChange={(event, newValue) => {
          setPendingValue(newValue);
        }}
        disableCloseOnSelect
        getOptionSelected={(option, value) => option?._id === value?._id}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return filtered;
        }}
        renderOption={(option, { selected }) => (
          <Box display="flex" alignItems="center">
            <Avatar src={option?.picture} style={{ marginRight: 8 }} />
            <Typography color={selected ? 'primary' : 'textPrimary'}>
              {`${option?.firstname} ${option?.lastname}`}
            </Typography>
          </Box>
        )}
        options={options}
        getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`}
        renderInput={(params) => (
          <FormInput
            ref={params.InputProps.ref}
            variant="filled"
            error={hasErrors}
            {...params}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: classes.inputRoot,
                focused: classes.inputFocused,
              },
              placeholder: placeholder,
              endAdornment: (
                <InputAdornment position="end" style={{ marginRight: '-2rem' }}>
                  <ExpandMore />
                </InputAdornment>
              ),
            }}
          />
        )}
        {...props}
      />
    </>
  );
}
