import { gql, useMutation } from '@apollo/client';
import { GET_CURRENT_USER } from 'pages/AdminPage/index';
import useAuth from 'graphql/operations/doctorOperations/useAuth';

export const UPDATE_USER = gql`
  mutation updateUser($userId: ID!, $updateUser: UpdateUser) {
    updateUser(userId: $userId, updateUser: $updateUser) {
      firstname
      middlename
      lastname
      country
      phoneNumber
      city
      address
      zipcode
      birthday
      gender
      description
      MartialStatus
      streetadress2
      streetadress1
      provinceState
      language
    }
  }
`;
export default function useEdituser() {
  const { user } = useAuth();
  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    refetchQueries: [
      {
        query: GET_CURRENT_USER,
        variables: { userId: user._id || user?.user?._id },
      },
    ],
    awaitRefetchQueries: false,
  });
  return { updateUser, loading };
}
