import React, { useState } from 'react';
import {
  BoxProps,
  Grid,
  makeStyles,
  Box,
  IconButton,
  Typography,
  InputAdornment,
  FormLabel,
  Chip,
  FormHelperText,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { gql, useQuery } from '@apollo/client';
import { log } from 'config';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import EditButtons from 'components/EditButtons';
import {
  convertToRawAndStringify,
  convertStringToEditorState,
} from '@eggmed/common/utils/DraftUtils';
import { formatToFullDateWithoutTime } from 'utils';
import ListItemWithActions from 'components/ui/ListItemWithActions';
import { ReactComponent as FolderIcon } from 'assets/icons/foldericon.svg';
import clsx from 'clsx';
import {
  convertObjectsFormat,
  convertObjectsFormatStaff,
  convertObjectsFormatCurrentUser,
} from 'pages/SchedulePage/AddEventPage/utils';
import AssignedTo from 'components/ui/AssignedTo';
import PriorityChip from '../components/PriorityChip';
import { RegisteredSelect } from '../components/CustomSelect';
import { taskInput } from '../../../../__generated__/globalTypes';
import AttachmentInput from '../components/AttachmentInput';
import useStyles from './styles';
import TaskInput from './TaskInput';
import ScrollBar from 'components/ui/ScrollBar';
import { EditorState } from 'draft-js';
import { useToast } from 'hooks/useToast';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { convertObjFormat } from './utils';
import { useProvider } from '../state/Provider';
import { convertString } from '../List';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';

const USERS_QUERY = gql`
  query getAllUsersForTaskAdd {
    users {
      _id
      username
      picture
      firstname
      middlename
      lastname
      createdAt
      updatedAt
      role
      organizationId
      doctorId
    }
  }
`;
interface ITaskListProps {
  name: string;
  _id: string;
  tasks: string[];
}

interface ITaskAddProps extends BoxProps {
  closeModal: () => void;
  handleAddTask: (data: taskInput, file: File[], listId?: string) => void;
  loadingTask: boolean;
  taskList: ITaskListProps[];
}

const schema = yup.object().shape({
  title: yup
    .string()
    .min(2, 'Title must be at least 2 characters')
    .required('Title is a required field'),
  status: yup.string().required('Status is a required field'),
  priority: yup.string().required('Priority is a required field'),
});

export default function TaskAdd({
  closeModal,
  handleAddTask,
  loadingTask,
  taskList,
  ...props
}: ITaskAddProps): JSX.Element {
  const { t } = useTranslation();
  const { doctor, user } = useAuth();
  const { doctors, patients, users } = useProvider();
  const { openSuccess, triggerToast } = useToast();
  const [files, setFiles] = useState<any>([]);
  const [values, setValues] = useState<any[]>(convertObjectsFormat([user], ''));
  const { triggerSnack } = useSnackbar();

  const [patientValues, setPatientValues] = useState([]);
  const {
    register,
    control,
    errors,
    getValues,
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const classes = useStyles({ openSuccess });
  async function onSubmit(data: any) {
    data.assignedTo = values.map(({ id }) => id);
    data.patients = patientValues.map(({ id }) => id);
    try {
      if (values.length > 0) {
        await handleAddTask(
          {
            ...data,
            description:
              data?.description && convertToRawAndStringify(data.description),
          },
          files,
          taskList?.find((task) => task?.name == data?.status)?._id
        );
        triggerSnack();
      }
    } catch (error) {
      log('error', error);
    }
  }
  const commonProps = { register, control, errors, variant: 'filled' };

  return (
    <Box {...props} className={classes.responsiveMobile}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ScrollBar className={classes.scroll}>
          <Box px="2rem">
            <Box mt={-2}>
              <TaskInput
                {...commonProps}
                type="text"
                data-cy="task-title"
                name="title"
                label={t('Title')}
              />
            </Box>
            <Box className={classes.labelSpacingAssigned}>
              <Text
                i18nKey="assignTo"
                className={clsx(classes.label, classes.spacingIcon)}
              >
                Assign to
              </Text>
            </Box>
            <Box style={{ marginTop: '-6px' }}>
              <Symptoms
                type=""
                disableCustomFilter={true}
                options={users}
                values={values}
                setValues={setValues}
                hasErrors={values.length === 0}
              />
              <Box>
                {values.length === 0 && (
                  <FormHelperText className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {t('Assign to is a required field')}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box className={classes.labelSpacingAssigned}>
              <Text
                i18nKey="relatedClients"
                className={clsx(classes.label, classes.spacingIcon)}
              >
                Clients
              </Text>
            </Box>
            <Box style={{ marginTop: '-6px' }}>
              <Symptoms
                disableCustomFilter={true}
                options={convertObjectsFormat(patients, '')}
                values={patientValues}
                setValues={setPatientValues}
              />
            </Box>
            <Box mt={-1}>
              <TaskInput
                type="date"
                name="dueDate"
                label={t('Due date')}
                minDate={new Date()}
                defaultValue={null}
                className={classes.inputDate}
                customInput={
                  <Box className={classes.inputWrapperCustom}>
                    <PriorityChip
                      data-cy="date-view"
                      className={
                        watch()?.dueDate ? classes.dateChip : classes.noDate
                      }
                      priority={
                        watch()?.dueDate &&
                        formatToFullDateWithoutTime(watch()?.dueDate)
                      }
                    />
                  </Box>
                }
                {...commonProps}
              />
            </Box>
            <Box className={classes.container}>
              <Box className={classes.equal}>
                <Box display="flex" mt={4}>
                  <FormLabel className={clsx(classes.label)}>
                    <Text className={clsx(classes.label)} i18nKey="priority">
                      Priority
                    </Text>
                  </FormLabel>
                </Box>
                <RegisteredSelect
                  data-cy="task-priority"
                  renderValue={(value: any) => (
                    <PriorityChip
                      data-cy="priority-view"
                      className={classes.chip}
                      priority={value}
                    />
                  )}
                  name="priority"
                  renderOption={(value: any) => (
                    <PriorityChip className={classes.chip} priority={value} />
                  )}
                  options={['high', 'low', 'medium']}
                  control={control}
                  register={register}
                  style={{ marginTop: '10px', width: '100%' }}
                  error={!!errors?.priority}
                />
                {errors && errors['priority'] && (
                  <FormHelperText className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {t((errors as any)['priority'].message)}
                  </FormHelperText>
                )}
              </Box>
              <Box className={classes.equal}>
                <Box display="flex" mt={4} pl="5px">
                  <FormLabel
                    className={clsx(classes.label, classes.labelMargin)}
                  >
                    <Text
                      className={clsx(classes.label, classes.labelMargin)}
                      i18nKey="status"
                    >
                      Status
                    </Text>
                  </FormLabel>
                </Box>
                <RegisteredSelect
                  data-cy="status-select"
                  renderValue={(value: any) => (
                    <PriorityChip
                      data-cy="status-view"
                      style={{
                        height: '24px',
                        cursor: 'pointer',
                      }}
                      priority={convertString[value]}
                    />
                  )}
                  name="status"
                  renderOption={(value: any) => (
                    <PriorityChip
                      data-cy="status-option"
                      style={{
                        height: '24px',
                        cursor: 'pointer',
                      }}
                      priority={convertString[value]}
                    />
                  )}
                  options={['overdue', 'pending', 'completed']}
                  control={control}
                  register={register}
                  style={{ marginTop: '10px', width: '100%' }}
                  error={!!errors?.status}
                />
                {errors && errors['status'] && (
                  <FormHelperText className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {t((errors as any)['status'].message)}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <TaskInput
              {...commonProps}
              className={classes.draftjsInput}
              type="draftjs"
              name="description"
              label={t('Description')}
            />
            <Box display="flex" alignItems="center" mt={3} mb={-1}>
              <FormLabel className={clsx(classes.label)}>
                <Text className={clsx(classes.label)} i18nKey="files">
                  Files
                </Text>
              </FormLabel>
            </Box>
            <Box>
              <AttachmentInput
                data-cy="file-add"
                my={2}
                name={t('Upload a file')}
                isActionable
                time={files.length > 0}
                createdAt={new Date()}
                handleAction={(event: any) => {
                  const newList = [...files, event.target?.files[0]];

                  setFiles(newList);
                }}
              />
            </Box>
            {files.map((file: any) => {
              return (
                <ListItemWithActions
                  primaryData={file.name}
                  secondaryData={file.type}
                  AvatarIcon={<FolderIcon />}
                  type="normal"
                />
              );
            })}
          </Box>
        </ScrollBar>
        <Box
          height="76px"
          display="flex"
          alignItems="center"
          width="100%"
          borderTop="1px solid #EAECF0"
        >
          <EditButtons
            handleOpen={(e) => {
              e.preventDefault();
              closeModal();
            }}
            editable
            loading={loadingTask}
            px="2rem"
            isSlide
          />
        </Box>
      </form>
    </Box>
  );
}
