import { gql, useMutation } from '@apollo/client';
import { GET_INSURANCES } from './useGetInsurances';

export const ADD_INSURANCE = gql`
  mutation addInsurance($insuranceInput: InsuranceInput) {
    addInsurance(insuranceInput: $insuranceInput) {
      patient {
        firstname
        lastname
      }
      relationShipType
      firstname
      middlename
      lastname
      sex
      birthday
      phone
      address
      city
      state
      zipCode
      country
      insuranceType
      payer
      memberID
      groupID
      planID
      copay
      deductible
      startDate
      endDate
    }
  }
`;

export default function useAddInsurance(patient) {
  const [addInsurance, { data, error, loading }] = useMutation(ADD_INSURANCE, {
    refetchQueries: [{ query: GET_INSURANCES, variables: { patient } }],
    awaitRefetchQueries: true,
  });

  return { addInsurance, data, error, loading };
}
