import React, { useRef, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import down from 'assets/dashboard/down.svg';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import { Box, Typography } from '@material-ui/core';
import plusIcon from 'assets/staff/plus.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #D0D5DD',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '44px',
    minHeight: '44px',
    borderRadius: '8px',
    color: '#425364',
    textTransform: 'capitalize',
    position: 'relative',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'left',
    marginTop: '5px',
    justifyContent: 'space-between',
    width: '100%',
  },
  down: {
    position: 'absolute',
    right: '5px',
  },
  paper: {
    marginTop: theme.spacing(6),
  },
  placeHolder: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    color: '#98A2B3',
  },
}));

interface IMenuProps {
  defaultMenuName: string;
  setValue: (value: string) => void;
  options: string[];
  hasAddOption?: boolean;
  onClickAddOption?: () => void;
  addOptionText?: string;
  hasDefaultAll?: boolean;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
}

export default function DropDownSelect({
  defaultMenuName,
  setValue,
  options,
  hasAddOption,
  onClickAddOption,
  addOptionText = 'Add New',
  hasDefaultAll,
  value,
  placeholder,
  disabled,
}: IMenuProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuName, setMenuName] = React.useState(defaultMenuName);
  const [dropdownWidth, setDropdownWidth] = useState<string | undefined>();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // Set the dropdown width based on the button width
    if (buttonRef.current) {
      setDropdownWidth(`${buttonRef.current.offsetWidth}px`);
    }
  }, []);

  return (
    <Box position="relative">
      <Button
        ref={buttonRef}
        className={classes.root}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
      >
        {placeholder && !value && !menuName && (
          <Typography className={classes.placeHolder}>
            {t(placeholder)}
          </Typography>
        )}
        {!!value ? t(value) : t(menuName)}
        <img src={down} className={classes.down} alt="down" />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PopoverClasses={{ paper: classes.paper }}
        PaperProps={{
          style: {
            minWidth: dropdownWidth,
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disableScrollLock={false}
      >
        {hasDefaultAll && (
          <MenuItem
            onClick={() => {
              setMenuName(defaultMenuName);
              setValue('');
              handleClose();
            }}
          >
            <Text i18nKey="all">All</Text>
          </MenuItem>
        )}
        {options.map((value: string) => (
          <MenuItem
            key={value}
            value={value}
            onClick={() => {
              setMenuName(value);
              setValue(value);
              handleClose();
            }}
          >
            <Text i18nKey={value}>{value}</Text>
          </MenuItem>
        ))}
        {hasAddOption && (
          <MenuItem
            onClick={() => {
              onClickAddOption && onClickAddOption();
              handleClose();
            }}
          >
            <img src={plusIcon} alt="down" />
            <Text
              i18nKey={addOptionText}
              style={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 500,
                color: '#147AF3',
                marginLeft: '10px',
              }}
            >
              {addOptionText}
            </Text>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}
