import { Box, Typography } from '@material-ui/core';
import { FormPhone } from 'pages/PatientActivitiesPage/CreateEditForm/FormPhone';
import React from 'react';
import { useForm } from 'react-hook-form';

const PhoneResponse = ({ data, updateResponse }: any) => {
  const { question, description, options,responses } = data?.questions[0];
  const [newData, setNewData] = React.useState(data);
  React.useEffect(() => {
    if (data) {
      setNewData(data);
    }
  }, [data]);
  const handleInputChange = (index, value) => {
    setNewData((prevData) => {
      const updatedQuestions = prevData.questions.map((q, i) => {
        return {
          ...q,
          responses: value,
        };
      });

      const updatedData = {
        ...prevData,
        questions: updatedQuestions,
      };

      updateResponse(updatedData);
      return updatedData;
    });
  };
  const { register, control, errors } = useForm();

  return (
    <Box mt={2}>
      <FormPhone
        name={'phone'}
        register={register}
        control={control}
        errors={errors}
        question={question}
        handleInputChange={handleInputChange}
        questionIndex={0}
        responses={responses}
      />
    </Box>
  );
};

export default PhoneResponse;
