import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { CustomAccordion } from '../invoice/CustomAccordion';
import { useForm, useFormContext } from 'react-hook-form';
import InputBlock from 'pages/AdminPage/Payment/InputBlock';

const ChangeBill = () => {
  const { control, register, errors } = useFormContext();
  const commonProps = { control, register, errors };
  return (
    <Box px="2rem">
      <CustomAccordion
        title={'Bill to'}
        AccordionSummary={<></>}
        AccordionContent={
          <Grid container spacing={2}>
            <Grid item xl={4} lg={4}>
              <InputBlock
                label={'First name'}
                {...commonProps}
                name="firstname"
                type="text"
              />
            </Grid>
            <Grid item xl={4} lg={4}>
              <InputBlock
                label={'Middle name'}
                {...commonProps}
                name="middlename"
                type="text"
              />
            </Grid>
            <Grid item xl={4} lg={4}>
              <InputBlock
                label={'Last name'}
                {...commonProps}
                name="lastname"
                type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6}>
              <InputBlock
                label={'Address'}
                {...commonProps}
                name="address"
                type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6}>
              <InputBlock
                label={'City'}
                {...commonProps}
                name="city"
                type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6}>
              <InputBlock
                label={'State'}
                {...commonProps}
                name="state"
                type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6}>
              <InputBlock
                label={'ZIP code'}
                {...commonProps}
                name="zipcode"
                type="text"
              />
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default ChangeBill;
