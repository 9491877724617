import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import EmptyTab from '../EmptyTabs';
import { ReactComponent as MoodIcon } from 'assets/patientActivities/emptyMood.svg';
import { IPatientData } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as EyeIcon } from 'assets/patientActivities/smallEye.svg';
import { ReactComponent as MeesageIcon } from 'assets/patientActivities/message.svg';
import { ReactComponent as SaveIcon } from 'assets/patientActivities/thinCopy.svg';
import Menu from 'components/ui/Menu';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import TrashIcon from 'assets/TrashIcon';
import Table from 'components/ui/Table';
import { GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID } from '../index';
import useDeletePatientActivities from '@eggmed/graphql-client/operations/patientActivitiesOperations/useDeletePatientActivity';
import useGeneratePatientActivityPdf from '@eggmed/graphql-client/operations/patientActivitiesOperations/useGeneratePatientActivityPDF';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { IEmoji } from './Form';
import { ReactComponent as ReflectionsIcon } from 'assets/patientActivities/emptyReflection.svg';
import { ReactComponent as SleepIcon } from 'assets/patientActivities/emptySleep.svg';
import { ReactComponent as AllActivity } from 'assets/emptyState/allAct.svg';
import ActivityModal from './ActivityModal';
import useHandlePatientActivities from './useHandlePatientActivities';
import { useProvider } from 'pages/InboxPage/state/Provider';
import AssignModal from 'pages/AppointmentPage/tabs/AssignModal';
import ActivityColumns from './Columns';
import AppointmentColumns from 'pages/AppointmentPage/tabs/Columns';
import ResultModal from './ResultModal';
import { gql, useQuery } from '@apollo/client';
import { TypeEnum as ActivityTypeEnum } from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreatePatientActivity';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { useProvider as useFormProvider } from 'pages/PatientActivities/Questionaires/ActivityProvider';
import FormModal from 'pages/PatientActivities/Questionaires/FormModal';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { ViewModal } from 'pages/PatientActivitiesPage/ViewModal';
import { IActivitySource, IActivityToOpen } from 'pages/PatientActivitiesPage';
import { ActivitiesTypes } from '../RecentActivityCard';
import { is } from 'date-fns/locale';

export enum TypeEnum {
  MOOD = 'Mood',
  SLEEP = 'Sleep',
  REFLECTION = 'reflection',
  FORM = 'FORM',
}
interface IActivityProps {
  patient: IPatientData;
  doctorAndPatientId: { doctor: string; patient: string };
  emojis?: IEmoji[];
  type: TypeEnum;
  activityType?: ActivityTypeEnum;
  appointmentActivity?: boolean;
  limit?: number;
  openAssign?: boolean;
  setOpenAssign?: (openAssign: boolean) => void;
  openActivity?: TypeEnum;
  setOpenActivity?: (openActivity: TypeEnum) => void;
  isFromPatient?: boolean;
  handleOpenCreateEdit?: () => void;
  setActivityToOpen?: React.Dispatch<React.SetStateAction<IActivityToOpen>>;
}

function Activity({
  patient,
  doctorAndPatientId,
  type,
  activityType,
  emojis,
  appointmentActivity = false,
  openAssign,
  setOpenAssign,
  openActivity,
  setOpenActivity,
  isFromPatient = false,
  handleOpenCreateEdit,
  setActivityToOpen,
}: IActivityProps) {
  const methods = useForm();
  const { handleOpenCreateNewThreadModal } = useProvider();
  const { data, openAssignModal, handleDeleteModal } = useFormProvider();
  const classes = useStyles({ selected: false });
  function handleCloseAssign() {
    setOpenAssign(null);
    setSelectedTab(null);
  }
  const [open, setOpen] = React.useState(false);
  const [menuType, setMenuType] = React.useState(false);
  const [limit, setLimit] = React.useState<number>(5);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [pdfUrl, setPdfUrl] = React.useState(null);
  const [edited, setEdited] = React.useState(false);
  const [bookmark, setBookmark] = React.useState(false);
  const [typeValue, setTypeValue] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [openResult, setOpenResult] = React.useState(false);
  const { t } = useTranslation();
  const [formTitle, setFormTitle] = React.useState<string>(t('Form'));
  const { data: activity, loading } = useQuery(
    GET_PATIENT_ACTIVITIES_BY_FILTER,
    {
      variables: {
        patientId: doctorAndPatientId.patient,
        doctorId: doctorAndPatientId.doctor,
        filter: activityType,
        page: page.toString(),
        limit: limit.toString(),
      },
    }
  );
  function handlePagination(e: any, page: number) {
    setPage(page);
  }
  const refetchQueries = [
    {
      query: GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID,
      variables: {
        patientId: doctorAndPatientId.patient,
        doctorId: doctorAndPatientId.doctor,
      },
    },
    {
      query: GET_PATIENT_ACTIVITIES_BY_FILTER,
      variables: {
        patientId: doctorAndPatientId.patient,
        doctorId: doctorAndPatientId.doctor,
        filter: activityType,
        page: page.toString(),
        limit: limit.toString(),
      },
    },
  ];
  const [handleDeletePatientActivity] = useDeletePatientActivities();
  const [handleGeneratePatientActivityPdf] = useGeneratePatientActivityPdf();
  const handleGenerate = async (id) => {
    return await handleGeneratePatientActivityPdf({
      variables: {
        id,
      },
      refetchQueries,
    });
  };
  const openDownloadMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuType(true);
    setAnchorEl(event.currentTarget);
  };
  const openActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuType(false);
    setAnchorEl(event.currentTarget);
  };
  const handleEmoji = (emoji: string, emojiArray) => {
    return emojiArray.find(
      (el) => el.label.toUpperCase() === emoji?.toUpperCase()
    )?.icon;
  };
  const handleOpen = () => {
    setDeleteId(null);
    setOpen(true);
    setAnchorEl(null);
  };
  const handleDelete = () => {
    handleDeletePatientActivity({
      variables: { id: deleteId },
      refetchQueries,
    });
    setOpenDeleteModal(false);
    setAnchorEl(null);
  };
  const handleDownload = async () => {
    if (edited) {
      setAnchorEl(null);
      const pdf = await handleGenerate(deleteId);
      const url = pdf.data.generatePatientActivityPDF.activityUrl;
      window.open(url, '_blank');
    } else {
      window.open(pdfUrl, '_blank');
      setAnchorEl(null);
    }
  };
  const DownloadMenuElements = [
    {
      name: 'Export as PDF',
      onClick: handleDownload,
      avatar: <EyeIcon />,
    },
  ];
  const ActionsMenuElements = [
    {
      name: bookmark ? 'Remove entry' : 'Save entry',
      onClick: () => {
        handleBookMark(deleteId, !bookmark);
        setAnchorEl(null);
      },
      avatar: <SaveIcon />,
    },
    {
      name: 'Message',
      onClick: () => {
        handleOpenCreateNewThreadModal();
        setAnchorEl(null);
      },
      avatar: <MeesageIcon />,
    },
    {
      name: 'Edit',
      onClick: () => {
        setOpen(true);
        setAnchorEl(null);
      },
      avatar: <EditIcon />,
    },
    {
      name: 'Delete',
      onClick: () => {
        setOpenDeleteModal(true);
        setAnchorEl(null);
      },
      avatar: <TrashIcon width="18px" height="18px" />,
    },
  ];
  function handleEdit() {
    setOpen(true);
    setAnchorEl(null);
  }
  function handleDeleteRow() {
    setOpenDeleteModal(true);
    setAnchorEl(null);
  }
  const defaultValues = React.useMemo(() => {
    if (activity?.patientActivitiesfilter?.result && deleteId) {
      return activity?.patientActivitiesfilter?.result.find(
        (item) => item._id === deleteId
      );
    }
    return null;
  }, [activity?.patientActivitiesfilter?.result, deleteId]);

  const modalType = {
    [TypeEnum.MOOD]: () => (
      <ActivityModal
        name={patient?.firstname}
        handleClose={() => setOpen(false)}
        doctorAndPatientId={doctorAndPatientId}
        defaultValues={defaultValues}
        type={TypeEnum.MOOD}
      />
    ),
    [TypeEnum.SLEEP]: () => (
      <ActivityModal
        name={patient?.firstname}
        handleClose={() => setOpen(false)}
        doctorAndPatientId={doctorAndPatientId}
        defaultValues={defaultValues}
        type={TypeEnum.SLEEP}
      />
    ),
    [TypeEnum.REFLECTION]: () => (
      <ActivityModal
        name={patient?.firstname}
        handleClose={() => setOpen(false)}
        defaultValues={defaultValues}
        doctorAndPatientId={doctorAndPatientId}
        type={TypeEnum.REFLECTION}
      />
    ),
    [TypeEnum.FORM]: () => (
      <FormModal
        setFormTitle={setFormTitle}
        handleClose={handleClose}
        title={`Assign form to ${patient?.firstname}`}
        patientId={patient?._id}
        patient={patient}
        assignText={`Assign this form to ${patient?.firstname} ${patient?.lastname}?`}
        doctorTemplates={data}
        openAssignModal={openAssignModal}
        openDeleteModal={handleDeleteModal}
      />
    ),
  };

  const emptyStateTitle = {
    [TypeEnum.MOOD]: {
      title: `${t('Ask')} ${capitalizeFirstLetter(patient?.firstname)} ${t(
        'to log a mood check-in'
      )}`,
    },
    [TypeEnum.SLEEP]: {
      title: `${t('Ask')} ${capitalizeFirstLetter(patient?.firstname)} ${t(
        'to log a sleep check-in'
      )}`,
    },
    [TypeEnum.REFLECTION]: {
      title: `${t('Ask')} ${capitalizeFirstLetter(patient?.firstname)} ${t(
        'to log a reflection'
      )}`,
    },
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { handleBookMark } = useHandlePatientActivities({
    defaultValues,
    type,
    doctorAndPatientId,
    handleClose,
  });
  const columns = appointmentActivity ? AppointmentColumns : ActivityColumns;
  const tableColumns = columns({
    type,
    handleEmoji,
    emojis,
    openDownloadMenu,
    setEdited,
    setPdfUrl,
    setDeleteId,
    openActionsMenu,
    setBookmark,
    handleBookMark,
    setTypeValue,
    setOpenResult,
    handleEdit,
    handleDeleteRow,
    isFromPatient,
    handleOpenCreateEdit,
    setActivityToOpen,
  });
  const [selectedTab, setSelectedTab] = React.useState<number>(null);
  const selectedActivity = activity?.patientActivitiesfilter?.result?.filter(
    (el) => el._id === deleteId
  )[0];
  return (
    <Box width="100%">
      {/* {!appointmentActivity && data && data.length > 0 && (
        <PatientListHeader
          handleOpen={handleOpen}
          limit={limit}
          setLimit={setLimit}
          disableEntries
        />
      )} */}
      <Table
        fullData={false}
        loading={loading}
        page={page}
        count={activity?.patientActivitiesfilter?.count}
        columns={tableColumns}
        limit={limit}
        handlePagination={handlePagination}
        data={activity?.patientActivitiesfilter?.result || []}
        pagination
        sorting
        EmptyState={
          <Box py="5rem">
            {isFromPatient ? (
              <Box style={{ textAlign: 'center' }}>
                <AllActivity />
                <Typography className={classes.title}>
                  {t('No activity yet')}
                </Typography>
              </Box>
            ) : (
              <EmptyState
                icon={<AllActivity />}
                title={emptyStateTitle[type]?.title || ''}
                text="to send your first message."
                onClick={handleOpen}
                isText={false}
                buttonText={t('Assign activity')}
              />
            )}
          </Box>
        }
      />
      <Menu<any>
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={!!anchorEl}
        handleMenuClose={() => setAnchorEl(null)}
        MenuElements={menuType ? DownloadMenuElements : ActionsMenuElements}
        className={classes.menu}
      />

      <BasicModal
        isRegularModal
        isSlide
        titlePadding="0"
        open={open}
        onClose={() => {
          setOpen(false);
          setOpenActivity(null);
        }}
        handleClose={() => {
          setOpen(false);
          setOpenActivity(null);
        }}
      >
        {modalType[typeValue || type]()}
      </BasicModal>
      <BasicModal
        isRegularModal
        isSlide
        divider={openActivity === TypeEnum.FORM}
        titlePadding={openActivity === TypeEnum.FORM ? '0' : '0'}
        title={''}
        open={!!openActivity}
        onClose={() => setOpenActivity(null)}
        handleClose={() => setOpenActivity(null)}
        isIcon={null}
      >
        {modalType[openActivity || type]()}
      </BasicModal>
      <BasicModal
        isRegularModal
        isSlide
        titlePadding={selectedTab === 0 ? '2rem' : '0'}
        title={selectedTab === 0 && 'Forms'}
        divider
        open={openAssign}
        onClose={handleCloseAssign}
        handleClose={handleCloseAssign}
      >
        <AssignModal
          doctorAndPatientId={doctorAndPatientId}
          defaultValues={null}
          patientName={patient?.firstname}
          handleCloseAssign={handleCloseAssign}
          patient={patient}
          setSelectedTab={setSelectedTab}
        />
      </BasicModal>
      <BasicModal
        isRegularModal
        isSlide
        open={!isFromPatient && openResult}
        onClose={() => setOpenResult(false)}
        handleClose={() => setOpenResult(false)}
      >
        <ResultModal
          activities={activity?.patientActivitiesfilter?.result}
          activityType={typeValue || type}
          selectedDay={defaultValues?.frequency?.startDate}
          handleEmoji={handleEmoji}
          emojis={emojis}
          currentRowId={deleteId}
          currentPdf={pdfUrl}
          onClose={() => setOpenResult(false)}
          handleBookMark={handleBookMark}
          doctorAndPatientId={doctorAndPatientId}
        />
      </BasicModal>
      <FormProvider {...methods}>
        <BasicModal
          isFromModal
          isSlide
          divider
          onSubmit={() => {
            setActivityToOpen({
              source: IActivitySource.EDIT,
              type: selectedActivity?.type as ActivitiesTypes,
              activity: selectedActivity,
            });
            handleOpenCreateEdit();
          }}
          loading={false}
          hasTitle={false}
          open={isFromPatient && openResult}
          onClose={() => setOpenResult(false)}
          handleClose={() => setOpenResult(false)}
          submitText="Edit"
          deleteText="Delete"
          handleDelete={() => {
            handleDeleteRow();
            setOpenResult(false);
          }}
          editMode
        >
          <Box
            sx={{
              width: {
                xs: '50vw',
                lg: '43vw',
                xl: '35vw',
              },
            }}
          >
            <ViewModal
              activities={activity?.patientActivitiesfilter?.result}
              activityType={typeValue || type}
              currentId={deleteId}
            />
          </Box>
        </BasicModal>
      </FormProvider>
      <ModalDelete
        text={type === TypeEnum.REFLECTION ? 'reflection' : `${type} check-in`}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={handleDelete}
      />
    </Box>
  );
}

export default Activity;

export const GET_PATIENT_ACTIVITIES_BY_FILTER = gql`
  query patientActivitiesfilter(
    $page: String
    $limit: String
    $filter: String
    $patientId: ID!
    $doctorId: ID!
  ) {
    patientActivitiesfilter(
      page: $page
      limit: $limit
      filter: $filter
      patientId: $patientId
      doctorId: $doctorId
    ) {
      count
      result {
        _id
        reflectionType
        question
        options
        type
        notes
        sleep
        photo
        assignedAt
        activityUrl
        isEdited
        bookmarked
        patient {
          _id
          firstname
          lastname
        }
        doctor {
          _id
        }
        frequency {
          startDate
          endDate
          frequencyType
          repetition {
            value
            unit
          }
          repeatOn
        }
        patientResponse {
          _id
          question
          response
          image
          sleepHours
          wakeHours
          notes
          createdAt
          tags
        }
      }
    }
  }
`;
