import React from 'react';
import useAssignQuestionaire from './useAssignQuestionaire';
import { useProvider as useFormProvider } from './ActivityProvider';
import BasicModal from 'components/ui/Modal';
import FormModal from './FormModal';
import AssignModal from './AssignModal';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { IPatientData } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';
import { Typography } from '@material-ui/core';

function FormModals({ patient }: { patient: IPatientData }) {
  const { handleAssignQuestionaire } = useAssignQuestionaire(true);
  const { t } = useTranslation();
  const [formTitle, setFormTitle] = React.useState<string>(t('Form'));
  const {
    openForm,
    triggerToast,
    formToAssign,
    setAssignOpen,
    assignOpen,
    doctor,
    handleClose,
    data,
    openAssignModal,
    handleDeleteModal,
    openDeleteModal,
    setOpenDeleteModal,
    handleDelete,
    loading,
  } = useFormProvider();
  const { triggerSnack } = useSnackbar();

  const handleSubmit = async () => {
    await handleAssignQuestionaire(
      formToAssign?.doctor?._id || doctor?._id,
      patient?._id,
      formToAssign?.title,
      formToAssign?.description,
      formToAssign?.formType,
      formToAssign?.blocks,
      formToAssign?._id
    );
    triggerSnack();
    setTimeout(() => setAssignOpen(false), 500);
  };
  return (
    <>
      <BasicModal
        isRegularModal
        isSlide
        title={formTitle}
        open={openForm}
        onClose={handleClose}
        handleClose={handleClose}
      >
        <FormModal
          setFormTitle={setFormTitle}
          handleClose={handleClose}
          title={`Assign form to ${patient?.firstname}`}
          patientId={patient?._id}
          patient={patient}
          assignText={`Assign this form to ${patient?.firstname} ${patient?.lastname}?`}
          doctorTemplates={data}
          openAssignModal={openAssignModal}
          openDeleteModal={handleDeleteModal}
          loading={loading}
        />
      </BasicModal>
      <BasicModal
        isRegularModal
        isNew
        open={assignOpen}
        onClose={() => setAssignOpen(false)}
        handleClose={() => setAssignOpen(false)}
      >
        <AssignModal
          handleClose={() => setAssignOpen(false)}
          handleSubmit={handleSubmit}
          text={
            <Typography>
              {`Assign `}
              <Typography component="span" style={{ fontWeight: 'bold' }}>
                {formToAssign?.title}
              </Typography>
              {` to ${patient?.firstname} ${patient?.lastname}?`}
            </Typography>
          }
        />
      </BasicModal>
      <ModalDelete
        text="Template"
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={handleDelete}
      />
    </>
  );
}

export default FormModals;
