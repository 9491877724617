import { Box, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FormHead } from './FormHead';
import { AttachImages } from './AttachImages';
import { Controller, useFormContext } from 'react-hook-form';
import { useStyles } from '../styles';
import Input from 'components/ui/Inputs';
import ErrorIcon from '@material-ui/icons/Error';
import { Options } from './Options';
import { EmojiResponse } from './EmojiResponse';
import { UncontrollerCustomTime } from 'components/ui/Inputs/CustomTime';
import { ActivitiesTypes } from 'pages/PatientActivities/RecentActivityCard';
import { moodTags, sleepTags } from './tags';
import { IActivitySource, IActivityToOpen } from '..';
import { FormList } from './FormList';
import { FormQuestion } from './FormQuestion';
import { FormRadio } from './FormRadio';
import { FormCheckbox } from './FormCheckbox';
import { FormBar } from './FormBar';
import Text from 'Text';
import { ReactComponent as PatientSignature } from 'assets/PatientSignature.svg';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';
import { DateForm } from './DateForm';
import { FormAddress } from './FormAddress';
import TableForm from './TableForm';
import ListForm from './ListForm';
import { FormEmail } from './FormEmail';
import { FormPhone } from './FormPhone';
import DateRange from 'components/ui/Inputs/DateRange';
import { transformDate } from 'pages/AdminPage/utils';
import { DateRangeForm } from './DateRangeForm';
import HeaderForm from './HeaderForm';
import HorizantaleLine from 'pages/FormsPage/HorizantaleLine';
import ParagraphForm from './ParagraphForm';
import BasicModal from 'components/ui/Modal';
import PracticeInformation from 'pages/AdminPage/TabsContent/PracticeInformation';
import ProgressLoader from 'components/ui/ProgressLoader';
import Skeleton from 'components/ui/Skeleton';

type ICreateEditFormProps = {
  activityType: IActivityToOpen;
  isEditMode?: boolean;
  formResponse: any;
  error?: string;
  setFormResponse: React.Dispatch<React.SetStateAction<any>>;
} & React.ComponentProps<any>;

export const CreateEditForm = ({
  activityType,
  formResponse,
  setFormResponse,
  isEditMode,
  error,
  ...props
}: ICreateEditFormProps) => {
  const { patient } = useAuth();
  const history = useHistory();
  const { register, control, errors, setValue, watch } = useFormContext();
  const { data, loading } = useQuery(GET_PATIENT, {
    variables: { patientId: patient?._id },
  });
  const [openSignatureModal, setOpenSignatureModal] = React.useState(false);
  function handleCloseSignaturemodal() {
    setOpenSignatureModal(false);
  }
  const classes = useStyles();
  const response = isEditMode ? activityType?.activity?.patientResponse[0] : {};
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const defaultBedTime = new Date();
  const defaultWakeup = new Date();
  defaultBedTime.setHours(22, 0, 0, 0);
  defaultWakeup.setHours(5, 0, 0, 0);
  const { open, toggleSignature } = useEditorContext();
  // const [open, setOpen] = React.useState(false);
  const handleInputChange = (questionIndex: number, value: string) => {
    setFormResponse((prevQuestions) => {
      const updatedQuestions = [...JSON.parse(prevQuestions.blocks)];
      const currentQuestion = updatedQuestions[questionIndex];
      const currentResponses =
        updatedQuestions[questionIndex]?.data?.questions[0]?.responses || [];
      const updatedResponses = value; // Add the option if it's not selected
      const updatedQuestion = {
        ...updatedQuestions[questionIndex],
        data: {
          ...currentQuestion?.data,
          questions: [
            {
              ...currentQuestion.data.questions[0],
              responses: updatedResponses,
            },
          ],
        },
      };
      updatedQuestions[questionIndex] = updatedQuestion;
      return { ...prevQuestions, blocks: JSON.stringify(updatedQuestions) };
    });
  };
  const handleCheckboxChange = (questionIndex: number, option: string) => {
    setFormResponse((prevQuestions) => {
      const updatedQuestions = [...JSON.parse(prevQuestions.blocks)];
      const currentQuestion = updatedQuestions[questionIndex];
      const currentResponses =
        updatedQuestions[questionIndex]?.data?.questions[0]?.responses || [];

      const updatedResponses = currentResponses.includes(option)
        ? currentResponses.filter((item) => item !== option) // Remove the option if it's already selected
        : [...currentResponses, option]; // Add the option if it's not selected
      const updatedQuestion = {
        ...updatedQuestions[questionIndex],
        data: {
          ...currentQuestion?.data,
          questions: [
            {
              ...currentQuestion.data.questions[0],
              responses: updatedResponses,
            },
          ],
        },
      };
      updatedQuestions[questionIndex] = updatedQuestion;
      return { ...prevQuestions, blocks: JSON.stringify(updatedQuestions) };
    });
  };
  const handleMultipleChange = (questionIndex: number, option: string) => {
    setFormResponse((prevQuestions) => {
      const updatedQuestions = [...JSON.parse(prevQuestions.blocks)];
      const currentQuestion = updatedQuestions[questionIndex];
      const currentResponses =
        updatedQuestions[questionIndex]?.data?.questions[0]?.responses || [];

      const updatedResponses = [option]; // Add the option if it's not selected
      const updatedQuestion = {
        ...updatedQuestions[questionIndex],
        data: {
          ...currentQuestion?.data,
          questions: [
            {
              ...currentQuestion.data.questions[0],
              responses: updatedResponses,
            },
          ],
        },
      };
      updatedQuestions[questionIndex] = updatedQuestion;

      return { ...prevQuestions, blocks: JSON.stringify(updatedQuestions) };
    });
  };
  const handleLinkClick = (link) => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  const handleAddress = (
    questionIndex: number,
    value: string,
    field: string
  ) => {
    setFormResponse((prevQuestions) => {
      const updatedQuestions = [...JSON.parse(prevQuestions.blocks)];
      const currentQuestion = updatedQuestions[questionIndex];
      const currentResponses =
        updatedQuestions[questionIndex]?.data?.questions[0]?.responses || [];

      const updatedResponses = {
        ...currentResponses,
        address: {
          ...currentResponses.address,
          [field]: value,
        },
      };
      const updatedQuestion = {
        ...updatedQuestions[questionIndex],
        data: {
          ...currentQuestion?.data,
          questions: [
            {
              ...currentQuestion.data.questions[0],
              responses: updatedResponses,
            },
          ],
        },
      };
      updatedQuestions[questionIndex] = updatedQuestion;
      return { ...prevQuestions, blocks: JSON.stringify(updatedQuestions) };
    });
  };

  if (activityType?.type === ActivitiesTypes.FORM) {
    const componentType = (activity, index) => {
      const currentResponses =
        [...JSON.parse(formResponse.blocks)][index]?.data?.questions ?? [];
      switch (activity?.type) {
        case 'Short_answer':
          return (
            <FormQuestion
              name={activity?.data?.questions[0]?.question}
              register={register}
              control={control}
              errors={errors}
              question={activity?.data?.questions[0]?.question}
              isShort={true}
              handleInputChange={handleInputChange}
              questionIndex={index}
              responses={currentResponses[0]?.responses}
            />
          );
        case 'Long_answer':
          return (
            <FormQuestion
              name={activity?.data?.questions[0]?.question}
              register={register}
              control={control}
              errors={errors}
              question={activity?.data?.questions[0]?.question}
              handleInputChange={handleInputChange}
              questionIndex={index}
              responses={currentResponses[0]?.responses}
            />
          );
        case 'Single_choice':
          return (
            <FormRadio
              name={activity?.data?.questions[0]?.question}
              register={register}
              control={control}
              question={activity?.data?.questions[0]?.question}
              options={activity?.data?.questions[0]?.options}
              handleInputChange={handleMultipleChange}
              questionIndex={index}
              responses={
                currentResponses[0]?.responses &&
                currentResponses[0]?.responses?.length > 0
                  ? currentResponses[0]?.responses[0]
                  : ''
              }
            />
          );
        case 'Multiple_choice':
          return (
            <FormCheckbox
              name={activity?.data?.questions[0]?.question}
              register={register}
              control={control}
              question={activity?.data?.questions[0]?.question}
              options={activity?.data?.questions[0]?.options}
              handleCheckboxChange={handleCheckboxChange}
              questionIndex={index}
              responses={currentResponses[0]?.responses}
            />
          );
        case 'DropDown':
          return (
            <FormList
              question={activity?.data?.questions[0]?.question}
              options={activity?.data?.questions[0]?.options}
              handleInputChange={handleInputChange}
              questionIndex={index}
              responses={currentResponses[0]?.responses}
            />
          );
        case 'Date':
          return (
            <DateForm
              register={register}
              control={control}
              question={activity?.data?.questions[0]?.question}
              name={activity?.data?.questions[0]?.question}
              questionIndex={index}
              handleInputChange={handleInputChange}
              responses={currentResponses[0]?.responses}
            />
          );
        case 'DateRange':
          return (
            <DateRangeForm
              register={register}
              control={control}
              question={activity?.data?.questions[0]?.question}
              name={activity?.data?.questions[0]?.question}
              questionIndex={index}
              handleInputChange={handleInputChange}
              responses={currentResponses[0]?.responses}
            />
          );
        case 'table':
          return <TableForm data={activity?.data?.content} />;
        case 'list':
          return (
            <ListForm
              data={activity?.data?.questions[0]?.options}
              type="unordered"
            />
          );
        case 'Orderedlist':
          return (
            <ListForm
              data={activity?.data?.questions[0]?.options}
              type="ordered"
            />
          );
        case 'Email':
          return (
            <FormEmail
              name={activity?.data?.questions[0]?.question}
              register={register}
              control={control}
              errors={errors}
              question={activity?.data?.questions[0]?.question}
              handleInputChange={handleInputChange}
              questionIndex={index}
              responses={currentResponses[0]?.responses}
            />
          );
        case 'Phone':
          return (
            <FormPhone
              name={activity?.data?.questions[0]?.question}
              register={register}
              control={control}
              errors={errors}
              question={activity?.data?.questions[0]?.question}
              handleInputChange={handleInputChange}
              questionIndex={index}
              responses={currentResponses[0]?.responses}
            />
          );
        case 'LineaireScale':
          return (
            <FormBar
              register={register}
              control={control}
              question={activity?.data?.questions[0]?.question}
              options={activity?.data?.questions[0]?.options}
              range={activity?.data?.questions[0]?.range}
              handleInputChange={handleInputChange}
              questionIndex={index}
              name={activity?.data?.questions[0]?.question}
              responses={currentResponses[0]?.responses}
            />
          );
        case 'paragraph':
          return <ParagraphForm data={activity?.data?.text} />;
        case 'Link':
          return (
            <Box onClick={() => handleLinkClick(activity?.data?.link)}>
              <Input
                className={classes.link}
                variant="outlined"
                multiline
                rows={2}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                  readOnly: true,
                }}
                value={activity?.data?.link}
                placeholder={'Link'}
                readOnly
              />
            </Box>
          );
        case 'Address':
          return (
            <FormAddress
              name={activity?.data?.questions[0]?.question}
              register={register}
              control={control}
              errors={errors}
              question={activity?.data?.questions[0]?.question}
              handleAddress={handleAddress}
              questionIndex={index}
              response={currentResponses[0]?.responses}
            />
          );
        case 'header':
          return (
            <HeaderForm
              data={activity?.data?.text}
              level={activity?.data?.level}
            />
          );
        case 'Image':
          return (
            <Box>
              <img src={activity?.data?.imageFile} width="80%" height="80%" />
            </Box>
          );
        case 'HorizantaleLine':
          return <HorizantaleLine />;

        case 'Signature':
          return (
            <>
              <Box
                display="flex"
                justifyContent={open ? 'flex-start' : 'center'}
                alignItems={open ? 'flex-start' : 'center'}
                flexDirection="column"
                style={{
                  backgroundColor: '#F0F9FF',
                  cursor: !open ? 'pointer' : 'default',
                }}
                width="100%"
                py={'2rem'}
                onClick={() => {
                  if (!data?.patient?.signature) {
                    setOpenSignatureModal(true);
                  } else {
                    toggleSignature(true);
                  }
                }}
              >
                {!open && (
                  <>
                    <PatientSignature style={{ marginBottom: '5px' }} />
                    <Typography className={classes.signText}>
                      Sign here
                    </Typography>
                    <Box mt={1}>
                      <Typography className={classes.signDesc}>
                        By Inserting your signature
                      </Typography>
                      <Typography className={classes.signDesc}>
                        you are e-signing this form
                      </Typography>
                    </Box>
                  </>
                )}
                <Box
                  display={!open ? 'none' : 'flex'}
                  justifyContent="space-between"
                  flexDirection="row-reverse"
                  width="100%"
                >
                  <Box
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push('/patient/settings?tab=6');
                    }}
                    pr={2}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: '16px',
                        color: '#0054B6',
                        textDecoration: 'underline',
                      }}
                    >
                      Edit
                    </Typography>
                  </Box>
                  <Box px={2} mt={-3}>
                    <img
                      src={data?.patient?.signature?.picture}
                      style={{ marginLeft: '-8px' }}
                    />
                    <Box mt={-4}>
                      <Typography
                        style={{
                          fontWeight: 700,
                          fontSize: '14px',
                          color: '#0054B6',
                        }}
                      >
                        Signed by {patient?.firstname} {patient?.lastname}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: '14px',
                          color: '#0054B6',
                        }}
                      >
                        {dayjs(data?.patient?.signature?.updatedAt).format(
                          'MMM D, YYYY h:mm A'
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          );
        default:
          return;
      }
    };
    return (
      <Box
        px="2rem"
        pb={3}
        display="flex"
        flexDirection="column"
        gridGap="2rem"
      >
        <FormHead
          activityType={activityType}
          control={control}
          watch={watch}
          assignedAt={activityType?.activity?.assignedAt}
        />
        {JSON.parse(activityType?.activity?.blocks).map((el, index) => (
          <>{componentType(el, index)}</>
        ))}
        <div style={{ color: 'red' }}>{error && error}</div>
        <BasicModal
          open={openSignatureModal}
          onClose={handleCloseSignaturemodal}
          handleClose={handleCloseSignaturemodal}
          isSlide
        >
          <Box
            sx={{
              width: {
                xs: '50vw',
                lg: '25vw',
                xl: '25vw',
              },
              height: '100vh',
            }}
            style={{ backgroundColor: 'white' }}
            p={4}
          >
            <PracticeInformation
              isPatient
              isEditable
              handleCloseSignaturemodal={handleCloseSignaturemodal}
              isPracticeSetting={false}
            />
          </Box>
        </BasicModal>
      </Box>
    );
  }

  return (
    <Box px="2rem" pb={3}>
      <FormHead activityType={activityType} control={control} watch={watch} />
      {/* Reflection */}
      {activityType?.type === ActivitiesTypes.REFLECTION && (
        <Box>
          <Box my="1rem">
            <Input
              inputRef={register}
              control={control}
              name={'entry'}
              variant="filled"
              type="text"
              defaultValue={isEditMode ? response.response : ''}
              helperText={
                errors &&
                errors['entry'] && (
                  <Box
                    display="flex"
                    alignItems="center"
                    data-cy="error-message"
                  >
                    <ErrorIcon className={classes.errorIcon} />
                    {errors['entry']?.message}
                  </Box>
                )
              }
              error={errors && !!errors['entry']}
              {...props}
            />
          </Box>
          <Box mb="1rem">
            <Controller
              defaultValue={isEditMode ? response.notes : ''}
              register={register}
              control={control}
              errors={errors}
              name={'notes'}
              render={({ onChange, value }) => (
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  multiline
                  minRows={15}
                  maxRows={15}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Box>
        </Box>
      )}
      {/* Sleep */}
      {activityType?.type === ActivitiesTypes.SLEEP && (
        <Box>
          <Box mt={3}>
            <Text
              i18nKey="How did you sleep last night?"
              className={classes.label}
            >
              How did you sleep last night?
            </Text>
            <Controller
              name="selectedEmoji"
              control={control}
              render={({ onChange, value }) => (
                <EmojiResponse
                  value={value}
                  onChange={onChange}
                  setIsEditable={setIsEditable}
                />
              )}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gridGap="2rem"
            px={4}
            mb={3}
          >
            <Box>
              <Text i18nKey="bedtime" className={classes.label2}>
                Bedtime
              </Text>
              <Controller
                defaultValue={
                  isEditMode ? new Date(response.sleepHours) : defaultBedTime
                }
                control={control}
                name="bedtime"
                {...props}
                render={({ onChange, onBlur, value }) => (
                  <UncontrollerCustomTime
                    name="bedtime"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    minTime={new Date(new Date().setHours(0, 0, 0, 0))}
                    maxTime={new Date(new Date().setHours(23, 0, 0, 0))}
                    showTimeSelect
                    dateFormat="hh:mm a"
                    {...props}
                  />
                )}
              />
            </Box>
            <Box>
              <Text i18nKey="wakeUpTime" className={classes.label2}>
                Wake-up time
              </Text>
              <Controller
                defaultValue={
                  isEditMode ? new Date(response.wakeHours) : defaultWakeup
                }
                control={control}
                name="wakeup"
                {...props}
                render={({ onChange, onBlur, value }) => (
                  <UncontrollerCustomTime
                    name="wakeup"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    minTime={new Date(new Date().setHours(0, 0, 0, 0))}
                    maxTime={new Date(new Date().setHours(23, 0, 0, 0))}
                    showTimeSelect
                    dateFormat="hh:mm a"
                    {...props}
                  />
                )}
              />
            </Box>
          </Box>
          <Box>
            <Text i18nKey="What impacted your sleep?" className={classes.label}>
              What impacted your sleep?
            </Text>
            <Options
              register={register}
              control={control}
              watch={watch}
              setValue={setValue}
              options={sleepTags}
              response={response?.tags}
              isEditMode={isEditMode}
              isEditable={isEditable}
            />
          </Box>
          {(activityType?.activity?.notes ||
            activityType?.source == IActivitySource.LOG) && (
            <Box mb="1rem">
              <Typography className={classes.label}>Notes</Typography>
              <Controller
                register={register}
                control={control}
                errors={errors}
                name="notes"
                defaultValue={isEditMode ? response.notes : ''}
                render={({ onChange, value }) => (
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    multiline
                    minRows={4}
                    maxRows={4}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Box>
          )}
        </Box>
      )}
      {/* Mood */}
      {activityType?.type === ActivitiesTypes.MOOD && (
        <Box>
          <Box mt={3}>
            <Text
              i18nKey="How are you feeling today?"
              className={classes.label}
            >
              How are you feeling today?
            </Text>
            <Controller
              name="selectedEmoji"
              control={control}
              render={({ onChange, value }) => (
                <EmojiResponse
                  value={value}
                  onChange={onChange}
                  setIsEditable={setIsEditable}
                />
              )}
            />
          </Box>
          <Box>
            <Text
              i18nKey="Which words best capture this feeling?"
              className={classes.label}
            >
              Which words best capture this feeling?
            </Text>
            <Options
              register={register}
              control={control}
              watch={watch}
              setValue={setValue}
              options={moodTags}
              response={response?.tags}
              isEditMode={isEditMode}
              isEditable={isEditable}
            />
          </Box>
          {(activityType?.activity?.notes ||
            activityType?.source == IActivitySource.LOG) && (
            <Box mb="1rem">
              <Text i18nKey="notes" className={classes.label}>
                Notes
              </Text>
              <Controller
                register={register}
                control={control}
                errors={errors}
                name="notes"
                defaultValue={isEditMode ? response.notes : ''}
                render={({ onChange, value }) => (
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    multiline
                    minRows={4}
                    maxRows={4}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Box>
          )}
        </Box>
      )}
      {(activityType?.activity?.photo ||
        activityType?.source == IActivitySource.LOG) && (
        <AttachImages control={control} setValue={setValue} watch={watch} />
      )}
    </Box>
  );
};
