import React from 'react';

function SpouseRelationShip({ color = '#344054' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={18}
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        d="M18.334 16.5v-1.667a3.335 3.335 0 00-2.5-3.228m-2.917-9.863a3.334 3.334 0 010 6.182m1.25 8.576c0-1.553 0-2.33-.254-2.942a3.334 3.334 0 00-1.804-1.804c-.612-.254-1.389-.254-2.942-.254h-2.5c-1.553 0-2.33 0-2.942.254a3.333 3.333 0 00-1.804 1.804c-.254.612-.254 1.389-.254 2.942M11.25 4.833a3.333 3.333 0 11-6.666 0 3.333 3.333 0 016.666 0z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SpouseRelationShip;
