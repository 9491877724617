import { gql, useMutation } from '@apollo/client';

const EDIT_DOCTOR_TEMPLATE = gql`
  mutation updateDoctorTemplateById(
    $id: ID
    $doctorTemplateInput: DoctorTemplateInput
  ) {
    updateDoctorTemplateById(
      id: $id
      doctorTemplateInput: $doctorTemplateInput
    ) {
      _id
      title
      description
      blocks
      formType
      type
    }
  }
`;

const useEditDoctorTemplate = () => {
  return useMutation(EDIT_DOCTOR_TEMPLATE);
};

export default useEditDoctorTemplate;
