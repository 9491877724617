import { Box, Typography, makeStyles, Grid } from '@material-ui/core';
import Button from 'components/ui/Button';
import React from 'react';
import useToggleDemo from '@eggmed/graphql-client/operations/doctorOperations/useToggleDemo';
import useAuth from 'graphql/operations/doctorOperations/useAuth';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
    fontSize: '20px',
    color: '#344054',
    fontFamily: 'Inter, sans-serif',
  },
  description: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#667085',
    fontFamily: 'Inter, sans-serif',
  },
  clientName: {
    fontWeight: 500,
    fontSize: '18px',
    color: '#101828',
    fontFamily: 'Inter, sans-serif',
  },
  clientInfo: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
    fontFamily: 'Inter, sans-serif',
  },
  btn: {
    backgroundColor: ({ isDemo }: { isDemo: boolean }) =>
      isDemo ? '#D92D20' : '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: ({ isDemo }: { isDemo: boolean }) =>
        isDemo ? '#D92D20' : '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
}));

const DemoClientPage = () => {
  const { toggleDemo } = useToggleDemo();
  async function handleToggleDemo() {
    await toggleDemo();
    window.location.reload();
  }
  const { doctor } = useAuth();
  const isDemo = doctor?.isDemo;
  const classes = useStyles({ isDemo });

  return (
    <Grid container>
      <Grid item xl={5} lg={5}>
        <Typography className={classes.title}>Demo client</Typography>
        <Typography className={classes.description}>
          Disabling or enabling demo client won't affect your real client data.
        </Typography>
        <Box
          mt={2}
          p={4}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography className={classes.clientName}>
              Virginia Worrymore
            </Typography>
            <Typography className={classes.clientInfo}>Demo client</Typography>
          </Box>
          <Button className={classes.btn} onClick={() => handleToggleDemo()}>
            {isDemo ? 'Disable' : 'Enable'}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DemoClientPage;
