import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../Insurance';
import SelfRelationShip from 'assets/SelfRelationShip';
import SpouseRelationShip from 'assets/SpouseRelationShip';
import ParentRelationShip from 'assets/ParentRelationShip';
import OtherRelationShip from 'assets/OtherRelationShip';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import { Controller } from 'react-hook-form';

const PolicyHolder = (props) => {
  const classes = useStyles();

  const relations = [
    {
      name: 'Self',
      icon: (
        <SelfRelationShip
          color={
            props?.watch()?.relationShipType === 'Self' ? 'white' : '#182230'
          }
        />
      ),
    },
    {
      name: 'Spouse',
      icon: (
        <SpouseRelationShip
          color={
            props?.watch()?.relationShipType === 'Spouse' ? 'white' : '#182230'
          }
        />
      ),
    },
    {
      name: 'Parent',
      icon: (
        <ParentRelationShip
          color={
            props?.watch()?.relationShipType === 'Parent' ? 'white' : '#182230'
          }
        />
      ),
    },
    {
      name: 'Other',
      icon: (
        <OtherRelationShip
          color={
            props?.watch()?.relationShipType === 'Other' ? 'white' : '#182230'
          }
        />
      ),
    },
  ];
  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.relationShipText}>
          Relationship to client
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Controller
            name="relationShipType"
            control={props.control}
            defaultValue="Self"
            render={({ value, onChange }) => (
              <>
                {relations?.map((relation) => (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.relationShipBox}
                    ml={2}
                    style={{
                      border: `1px solid ${
                        value === relation?.name ? '#0265DC' : '#E0E0E0'
                      }`,
                      backgroundColor:
                        value === relation?.name ? '#0265DC' : '#fff',
                    }}
                    onClick={() => onChange(relation?.name)}
                  >
                    {relation.icon}
                    <Typography
                      className={classes.relationShipLabel}
                      style={{
                        color: value === relation?.name ? '#fff' : '#182230',
                      }}
                    >
                      {relation.name}
                    </Typography>
                  </Box>
                ))}
              </>
            )}
          />
        </Box>
      </Box>
      {props?.watch()?.relationShipType !== 'Self' && (
        <Grid container spacing={2} style={{ marginBlock: '16px' }}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'First name'}
              {...props}
              name="firstname"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Middle name'}
              {...props}
              name="middlename"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Last name'}
              {...props}
              name="lastname"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Sex'}
              {...props}
              name="sex"
              type="select"
              options={['male', 'female']}
            />
          </Grid>

          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Date of birth'}
              {...props}
              name="birthday"
              type="date"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock label={'Phone'} {...props} name="phone" type="phone" />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Address'}
              {...props}
              name="address"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock label={'City'} {...props} name="city" type="text" />
          </Grid>

          <Grid item xl={6} lg={6}>
            <InputBlock label={'State'} {...props} name="state" type="text" />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'ZIP code'}
              {...props}
              name="zipCode"
              type="number"
            />
          </Grid>
          <Grid item xl={12} lg={12}>
            <InputBlock
              label={'Country'}
              {...props}
              name="country"
              type="country"
              style={{ width: '100%' }}
              defaultValue={{ label: props?.country || '' }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default PolicyHolder;
