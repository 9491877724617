export const colors = ['Red', 'Blue', 'Pink', 'Yellow', 'Green', 'Purple'];

export const colorsMapping = {
  Red: '#EB5757',
  Blue: '#2F80ED',
  Pink: 'Pink',
  Yellow: '#F2C94C',
  Green: '#27AE60',
  Purple: '#BB6BD9',
};

export const colorsInfos = {
  Red: {
    text: '#912018',
    backgroundColor: '#FEF3F2',
    border: '1px solid #FECDCA',
  },
  Blue: {
    text: '#026AA2',
    backgroundColor: '#F5FBFF',
    border: '1px solid #B9E6FE',
  },
  Pink: {
    text: '#C11574',
    backgroundColor: '#FEF6FB',
    border: '1px solid #FCCEEE',
  },
  Yellow: {
    text: '#CA8504',
    backgroundColor: '#FEFDF0',
    border: '1px solid #FEEE95',
  },
  Green: {
    text: '#3B7C0F',
    backgroundColor: '#FAFEF5',
    border: '1px solid #D0F8AB',
  },
  Purple: {
    text: '#4A1FB8',
    backgroundColor: '#FAFAFF',
    border: '1px solid #D9D6FE',
  },
};
