import React, { useEffect, useState } from 'react';
import { team as teamConst } from '../constants';
import { Grid } from '@material-ui/core';
import TeamSettingsHeader from './TeamSettingsHeader';
import TeamSettingsTable from './TeamSettingsTable';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import { ReactComponent as PasswordIcon } from 'assets/passcode.svg';
import TrashIcon from 'assets/TrashIcon';
import Menu from 'components/ui/Menu';
import AddEditModal from './EditModal';
import PasswordModal from './PasswordModal';
import RemoveModal from './RemoveModal';
import RoleModal from './RoleModal';

function TeamSettings() {
  const [team, setTeam] = useState(teamConst);
  const [filteredTeam, setFilteredTeam] = useState(teamConst);
  const [queryState, setQuery] = useState('');
  const [role, setRole] = useState('');
  const [inviteStatus, setInviteStatus] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [newRole, setNewRole] = useState<string>('');

  useEffect(() => {
    setTeam(teamConst);
  }, [teamConst]);

  const handleOpenModal = () => {
    setSelectedRow(null);
    setEditMode(false);
    setOpenModal(true);
  };
  const handleEditFields = (row) => {
    setEditMode(true);
    setSelectedRow(row);
    setOpenModal(true);
  };
  const handleEdit = () => {
    setEditMode(true);
    setOpenModal(true);
  };

  const handleSearch = (query) => {
    setQuery(query);
    const newTeam = team.filter((item) => {
      return (
        item.firstname?.toLowerCase().includes(query.toLowerCase()) ||
        item.lastname?.toLowerCase().includes(query.toLowerCase()) ||
        item.email?.toLowerCase().includes(query.toLowerCase()) ||
        item.Supervisor?.firstname
          ?.toLowerCase()
          .includes(query.toLowerCase()) ||
        item.Supervisor?.lastname?.toLowerCase().includes(query.toLowerCase())
      );
    });
    setFilteredTeam(newTeam);
  };

  const handleClear = () => {
    setQuery('');
    setFilteredTeam(team);
  };

  const displayedTeam = filteredTeam.filter((item) => {
    const matchesRole = role ? item.role === role : true;
    const matchesInviteStatus = inviteStatus
      ? item.inviteStatus === inviteStatus
      : true;
    return matchesRole && matchesInviteStatus;
  });

  const handleResetPassword = () => {
    setOpenResetPasswordModal(true);
  };
  const handleRemove = () => {
    setOpenRemoveModal(true);
  };
  const menuElements = [
    {
      avatar: <EditIcon />,
      name: 'Edit info',
      onClick: handleEdit,
    },
    {
      avatar: <PasswordIcon />,
      name: 'Reset password',
      onClick: handleResetPassword,
    },
    {
      avatar: <TrashIcon width={18} height={18} fill="#B42318" />,
      name: 'Remove',
      onClick: handleRemove,
      isRed: true,
    },
  ];
  const handleOpenDropDown = (e, row) => {
    setAnchor(e.currentTarget);
    setSelectedRow(row);
  };
  const handleChangeRole = (row, newRole) => {
    setNewRole(newRole);
    setOpenRoleModal(true);
    setSelectedRow(row);
  };

  return (
    <Grid container>
      <TeamSettingsHeader
        query={queryState}
        handleSearch={handleSearch}
        handleClear={handleClear}
        setRole={setRole}
        setInviteStatus={setInviteStatus}
        handleOpenModal={handleOpenModal}
      />
      <TeamSettingsTable
        team={displayedTeam}
        handleOpenDropDown={handleOpenDropDown}
        handleViewFields={() => {}}
        handleEditFields={handleEditFields}
        handleChangeRole={handleChangeRole}
      />
      <Menu<any>
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchor}
        handleMenuClose={() => setAnchor(null)}
        MenuElements={menuElements}
      />
      <AddEditModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        values={selectedRow}
        editMode={editMode}
      />
      <PasswordModal
        open={openResetPasswordModal}
        user={selectedRow}
        onClose={() => setOpenResetPasswordModal(false)}
      />
      <RemoveModal
        open={openRemoveModal}
        onClose={() => setOpenRemoveModal(false)}
        user={selectedRow}
      />
      <RoleModal
        open={openRoleModal}
        onClose={() => setOpenRoleModal(false)}
        user={selectedRow}
        newRole={newRole}
      />
    </Grid>
  );
}

export default TeamSettings;
