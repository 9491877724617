import { gql, useMutation } from '@apollo/client';
import { GET_FINANCES } from './useGetFinances';
import { SESSION_BY_PERIOD_BILL } from 'pages/DoctorFinancePage/DoctorFinance';

export const DELETE_SUPERBILL = gql`
  mutation deleteSuperBill($id: ID) {
    deleteSuperBill(id: $id)
  }
`;

export default function useDeleteSuperbill() {
  const [deleteSuperBill, { data, error, loading }] = useMutation(
    DELETE_SUPERBILL,
    {
      refetchQueries: [GET_FINANCES, SESSION_BY_PERIOD_BILL],
    }
  );

  return { deleteSuperBill, data, error, loading };
}
