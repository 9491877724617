import React from 'react';

function OtherRelationShip({ color = '#344054' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={4}
      viewBox="0 0 18 4"
      fill="none"
    >
      <path
        d="M9 3a1 1 0 100-2 1 1 0 000 2zM16 3a1 1 0 100-2 1 1 0 000 2zM2 3a1 1 0 100-2 1 1 0 000 2z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default OtherRelationShip;
