import React, { useEffect } from 'react';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import { ReactComponent as TooltipIcon } from 'assets/info-circle.svg';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DropDownSelect from '../DropdownSelect';
import { supervisors } from '../constants';
import SelectUser from '../SelectUser';
import { gql, useQuery } from '@apollo/client';
import TagSelect from 'pages/AdminPage/ManageTags/TagSelect';
import { colors } from 'pages/AdminPage/ManageTags/Constant';

function TeamMemberForm({ editMode }: { editMode: boolean }) {
  const classes = useStyles();
  const { control, errors, register, setValue, watch } = useFormContext();
  const { t } = useTranslation();
  const commonProps = { control, errors, register, variant: 'filled' };
  const [selectedSupervisors, setSelectedSupervisors] = React.useState<any[]>(
    []
  );
  const [selectedPatients, setSelectedPatients] = React.useState<any[]>([]);
  const [selectedColor, setSelectedColor] = React.useState(null);
  const { data } = useQuery(PATIENTS_RELATED);
  useEffect(() => {
    if (editMode && !!watch('supervisor')) {
      setSelectedSupervisors([watch('supervisor')]);
    }
  }, [editMode]);

  return (
    <Box>
      <Box px={'2rem'} pt={2} pb="2rem">
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <InputBlock
              label={t('Email')}
              {...commonProps}
              name="email"
              type="text"
              isRequired
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('First name')}
              {...commonProps}
              name="firstname"
              type="text"
              isRequired
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('Last name')}
              {...commonProps}
              name="lastname"
              type="text"
              isRequired
            />
          </Grid>
          <Box className={classes.divider} />
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.labelText}>
              Role
              <span style={{ color: '#D92D20' }}>*</span>
            </Typography>
            <DropDownSelect
              defaultMenuName={watch('role')}
              placeholder="Select role"
              setValue={(role) => {
                setValue('role', role);
              }}
              options={['Admin', 'Provider', 'Assistant']}
              onClickAddOption={() => {}}
              addOptionText="Add a new role"
              hasAddOption
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              mb="5px"
            >
              <Typography className={classes.labelText} style={{ margin: 0 }}>
                Supervisor
              </Typography>
              <Tooltip
                placement="top-start"
                classes={{ tooltip: classes.popup }}
                title="Assign a Supervisor to oversee providers in training, ensuring compliance with supervision guidelines. Supervisors can review documentation, provide feedback, and monitor care to meet regulatory standards."
              >
                <TooltipIcon />
              </Tooltip>
            </Box>
            <SelectUser
              options={supervisors}
              values={selectedSupervisors}
              setValues={setSelectedSupervisors}
              hasErrors={supervisors.length === 0}
              placeholder="Select team member"
              multiple
            />
          </Grid>
          <Box className={classes.divider} />
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              mb="6px   "
            >
              <Typography className={classes.labelText} style={{ margin: 0 }}>
                NPI
              </Typography>
              <Tooltip
                placement="top-start"
                classes={{ tooltip: classes.popup }}
                title="This is only applicable to clinicians"
              >
                <TooltipIcon />
              </Tooltip>
            </Box>
            <InputBlock {...commonProps} name="npi" type="text" />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.labelText} style={{ margin: 0 }}>
              License number
            </Typography>
            <InputBlock {...commonProps} name="licensenumber" type="text" />
          </Grid>
          <Box className={classes.divider} />
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              mb="5px"
            >
              <Typography className={classes.labelText} style={{ margin: 0 }}>
                Assigned clients
              </Typography>
            </Box>
            <SelectUser
              options={data?.patientsRelated || []}
              values={selectedPatients}
              setValues={setSelectedPatients}
              hasErrors={data?.patientsRelated.length === 0}
              placeholder="Add assigned clients"
              multiple
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              mb="6px   "
            >
              <Typography className={classes.labelText} style={{ margin: 0 }}>
                Color on the calendar
              </Typography>
              <Tooltip
                placement="top-start"
                classes={{ tooltip: classes.popup }}
                title="Select a color to represent this team member on the calendar"
              >
                <TooltipIcon />
              </Tooltip>
            </Box>
            <TagSelect
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              values={colors}
              hasLabel={false}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default TeamMemberForm;

const PATIENTS_RELATED = gql`
  query patientsRelated {
    patientsRelated {
      _id
      firstname
      lastname
      picture
    }
  }
`;
