import React from 'react';
import { Box } from '@material-ui/core';
import Table from 'components/ui/Table';
import Columns from './Columns';

export interface ITeam {
  _id: string;
  picture: string;
  firstname: string;
  lastname: string;
  email: string;
  inviteStatus: string;
  role: string;
  lastActive: Date;
  Supervisor: {
    picture: string;
    firstname: string;
    lastname: string;
  };
}

interface ITableInterface {
  team: ITeam[];
  handleOpenDropDown(e: React.MouseEvent<HTMLElement>, row: ITeam): void;
  handleEditFields(row: ITeam): void;
  handleViewFields(row: ITeam): void;
  handleChangeRole(row: ITeam, newRole: string): void;
}

function TeamSettingsTable({
  team,
  handleOpenDropDown,
  handleEditFields,
  handleViewFields,
  handleChangeRole,
}: ITableInterface) {
  const [hoveredRowIndex, setHoveredRowIndex] = React.useState<string | null>(
    null
  );
  const columnProps = {
    hoveredRowIndex,
    handleOpenDropDown,
    handleEditFields,
    handleChangeRole,
  };
  const columns = Columns(columnProps);

  return (
    <Box width="100%">
      <Table
        loading={false}
        page={1}
        columns={columns as any}
        limit={10}
        fullData={false}
        handlePagination={() => {}}
        data={team || []}
        pagination
        sorting={false}
        onMouseEnterRow={(row) => setHoveredRowIndex(row?._id)}
        onMouseLeaveRow={() => setHoveredRowIndex(null)}
        onRowClick={(row) => handleViewFields(row)}
      />
    </Box>
  );
}

export default TeamSettingsTable;
