import { Box } from '@material-ui/core';
import React from 'react';

const ListForm = ({ data, type }) => {
  const list = {
    unordered: (
      <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
        {data.map((item, index) => (
          <Box
            display={'flex'}
            alignItems="center"
            style={{ gap: '5px' }}
            mb={1}
          >
            <div
              style={{
                backgroundColor: 'black',
                borderRadius: '50%',
                width: '6px',
                height: '6px',
                marginTop: '2px',
              }}
            />
            <li key={index}>{item}</li>
          </Box>
        ))}
      </ul>
    ),
    ordered: (
      <ol style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
        {data.map((item, index) => (
          <Box display={'flex'} style={{ gap: '5px' }} mb={1}>
            <div>{index + 1}.</div>
            <li key={index}>{item}</li>
          </Box>
        ))}
      </ol>
    ),
  };
  return list[type];
};

export default ListForm;
