import React from 'react';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as PasswordIcon } from 'assets/passcode-lock.svg';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import Button from 'components/ui/Button';
import TrashIcon from 'assets/TrashIcon';
import { IRole } from './PermissionSettings';

interface IEditProps {
  open: boolean;
  onClose: () => void;
  role: IRole;
}

function RemoveRoleModal({ open, onClose, role }: IEditProps) {
  const classes = useStyles();

  return (
    <BasicModal open={open} onClose={onClose} handleClose={onClose} isNew>
      <Box className={classes.PasswordModal}>
        <TrashIcon width="49px" height="49px" fill="#D92D20" />
        <Typography className={classes.modalTitle}>
          {`Delete ${role.role}?`}
        </Typography>
        <Box width="100%" mt="10px" mb="1.5rem">
          <Typography className={classes.modalText}>
            Are you sure you want to delete{' '}
            <span style={{ fontWeight: 700 }}>{role.role}</span>? This action
            cannot be undone.
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ gap: '1rem' }}
        >
          <Button onClick={onClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button type="submit" className={classes.saveButtonRed}>
            Delete
          </Button>
        </Box>
      </Box>
    </BasicModal>
  );
}

export default RemoveRoleModal;
