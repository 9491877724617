import React from 'react';

function ParentRelationShip({ color = '#344054' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.167 17.5c0-1.553 0-2.33-.254-2.942a3.334 3.334 0 00-1.804-1.804c-.612-.254-1.389-.254-2.942-.254h-2.5c-1.553 0-2.33 0-2.942.254a3.333 3.333 0 00-1.804 1.804c-.254.612-.254 1.389-.254 2.942M11.25 5.833a3.333 3.333 0 11-6.666 0 3.333 3.333 0 016.666 0zM19 18v-1.355c0-1.263-.785-2.324-1.846-2.625M15 11.027c.902-.402 1.538-1.376 1.538-2.514C16.526 7.343 15.9 5 13.5 5"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ParentRelationShip;
