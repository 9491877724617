import { Box } from '@material-ui/core';
import PatientDashboard from 'pages/PatientDashboard';
import React, { ReactElement } from 'react';
import { IconProps } from './patientDashboard/AppointmentIcon';

const ThreeDotsIcon = ({
  fill = '#0265DC',
  width = '100%',
  height = '100%',
  className,
  style,
}: IconProps): ReactElement => {
  return (
    <Box width={width} height={height} className={className} style={style}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99999C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16667 9.53976 9.16667 9.99999C9.16667 10.4602 9.53977 10.8333 10 10.8333Z"
          stroke={fill}
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 4.99999C10.4602 4.99999 10.8333 4.6269 10.8333 4.16666C10.8333 3.70642 10.4602 3.33333 10 3.33333C9.53977 3.33333 9.16667 3.70642 9.16667 4.16666C9.16667 4.6269 9.53977 4.99999 10 4.99999Z"
          stroke={fill}
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 10 15C9.53977 15 9.16667 15.3731 9.16667 15.8333C9.16667 16.2936 9.53977 16.6667 10 16.6667Z"
          stroke={fill}
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  );
};

export default ThreeDotsIcon;
