import React, { useEffect, useState } from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import Table from 'components/ui/Table';
import { IRole } from './PermissionSettings';
import { ReactComponent as DropDownComponent } from 'assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron-up.svg';
import { useStyles } from '../styles';
import Text from 'Text';
import { useRate } from 'pages/AdminPage/Payment/useRate';
import { ReactComponent as InPersonAppt } from 'assets/InPersonAppt.svg';
import { ReactComponent as VideoAppt } from 'assets/VideoAppt.svg';
import { ReactComponent as MoreIcon } from 'assets/patientActivities/more.svg';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import TrashIcon from 'assets/TrashIcon';
import Menu from 'components/ui/Menu';
import RemoveServiceModal from './RemoveServieModal';
import CustomButton from 'components/ui/Button';
import { ReactComponent as AddIcon } from 'assets/patientActivities/pluss.svg';
import { ReactComponent as ManageAll } from 'assets/settings-02.svg';
import AssignService from './AssignModal';

interface ITableInterface {
  role: IRole;
  isSelected: boolean;
  handleSelect: () => void;
}

function TopSection({ role, isSelected, handleSelect }: ITableInterface) {
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [RemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const handleClickDrop = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
  const handleEditFields = (row: any) => {
    setSelectedRow(row);
  };
  const { data } = useRate();
  const locationIcon = {
    'In-person': <InPersonAppt />,
    Online: <VideoAppt />,
  };

  const columns = [
    {
      id: 'session',
      label: (
        <Box fontSize="1rem">
          <Typography>Service</Typography>
        </Box>
      ),
      align: 'left',
      minWidth: 230,
      format: (value, row) => (
        <>
          {value}
          {row?.isDefault && (
            <Tooltip title="Default service">
              <Typography
                variant="caption"
                className={classes.type}
                style={{ marginLeft: '5px' }}
              >
                Default
              </Typography>
            </Tooltip>
          )}
        </>
      ),
    },

    {
      id: 'duration',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="duration">Duration</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 100,
      format: (value) => value,
    },

    {
      id: 'rate',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="pricing">Pricing</Text>
        </Box>
      ),
      minWidth: 100,
      align: 'left',
      format: (value, { currency }) => `$${value}`,
    },
    {
      id: 'isPrepayment',
      label: (
        <Box fontSize="1rem">
          <Typography>Prepayment</Typography>
        </Box>
      ),
      minWidth: 10,
      align: 'left',
      format: (value) => (
        <Box
          style={{
            backgroundColor: value ? '#FEF3F2' : '#ECFDF3',
            border: `1px solid ${value ? '#FECDCA' : '#ABEFC6'}`,
            borderRadius: '16px',
            padding: '3px 8px',
          }}
          width="60%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontWeight: 500,
              fontSize: '12px',
              color: value ? '#B42318' : '#067647',
            }}
          >
            {value ? 'Required' : 'None'}
          </Typography>
        </Box>
      ),
    },
    {
      id: 'location',
      label: (
        <Box fontSize="1rem">
          <Typography>Location</Typography>
        </Box>
      ),
      minWidth: 150,
      align: 'left',
      format: (value) => (
        <Box display="flex" alignItems="center">
          {locationIcon[value]}
          <Typography style={{ marginLeft: '5px' }}>{value || '-'}</Typography>
        </Box>
      ),
    },
    {
      id: 'actions',
      label: <Box fontSize="1rem"></Box>,
      minWidth: 10,
      align: 'left',
      format: (value, row) => (
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className={`${classes.actions} ${
              hoveredRowIndex === row?._id ? classes.actionsVisible : ''
            }`}
          >
            <EditIcon
              data-cy="edit-action"
              onClick={(e) => {
                e.stopPropagation();
                handleEditFields(row);
              }}
              style={{ cursor: 'pointer', marginRight: '1rem' }}
            />
          </Box>
          <Box onClick={(e) => handleClickDrop(e, row)}>
            <MoreIcon
              data-cy="more-action"
              style={{
                cursor: 'pointer',
                fontSize: '24px',
                color: '#425364',
              }}
            />
          </Box>
        </Box>
      ),
    },
  ];

  const handleEdit = () => {
    console.log('Edit');
  };
  const handleRemove = () => {
    setRemoveModalOpen(true);
  };

  const menuElements = [
    {
      avatar: <EditIcon />,
      name: 'Edit',
      onClick: handleEdit,
    },
    {
      avatar: <TrashIcon width={18} height={18} fill="#B42318" />,
      name: 'Remove',
      onClick: handleRemove,
      isRed: true,
    },
  ];

  return (
    <Box width="100%" mt="2rem">
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        onClick={handleSelect}
        style={{
          cursor: 'pointer',
        }}
      >
        <Box>{isSelected ? <ChevronUp /> : <DropDownComponent />}</Box>
        <Typography className={classes.sectionTitle}>
          Available services for the{' '}
          <span style={{ color: '#0054B6' }}>{role?.role}</span> role
        </Typography>
      </Box>
      {isSelected && (
        <Box width="100%" mt="1rem">
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <CustomButton
              className={classes.addBtn}
              buttonType="reset"
              onClick={() => setOpenAdd(true)}
              style={{
                border: 'none',
                backgroundColor: 'transparent',
              }}
            >
              <ManageAll />
              <Text
                i18nKey="Add team member"
                className={classes.addBtnText}
                style={{
                  color: '#147AF3',
                }}
              >
                Manage all services
              </Text>
            </CustomButton>
            <CustomButton
              className={classes.addBtn}
              buttonType="reset"
              onClick={() => setOpenAdd(true)}
            >
              <AddIcon />
              <Text i18nKey="Add team member" className={classes.addBtnText}>
                Assign service
              </Text>
            </CustomButton>
          </Box>
          <Table
            loading={false}
            page={1}
            columns={columns as any}
            limit={10}
            fullData={false}
            handlePagination={() => {}}
            data={data?.rates || []}
            pagination
            sorting={false}
            onMouseEnterRow={(row: any) => setHoveredRowIndex(row?._id)}
            onMouseLeaveRow={() => setHoveredRowIndex(null)}
          />
        </Box>
      )}
      <Menu<any>
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        handleMenuClose={() => setAnchorEl(null)}
        MenuElements={menuElements}
      />
      <RemoveServiceModal
        open={RemoveModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        role={role}
        service={selectedRow}
      />
      <AssignService
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        role={role}
      />
    </Box>
  );
}

export default TopSection;
