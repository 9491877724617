import { gql, useMutation } from '@apollo/client';
import { GET_FINANCES } from './useGetFinances';
import { SESSION_BY_PERIOD_BILL } from 'pages/DoctorFinancePage/DoctorFinance';
import { SUPER_BILL_BY_ID } from 'pages/DoctorFinancePage/superbill/SuperbillPreviewModal';

export const GENERATE_SUPERBILL_PDF = gql`
  mutation createSuperbill(
    $sessions: [ID]
    $patient: ID
    $startDate: Date
    $endDate: Date
  ) {
    createSuperbill(
      sessions: $sessions
      patient: $patient
      startDate: $startDate
      endDate: $endDate
    ) {
      superBillPdf
      _id
      insuranceRelated {
        firstname
        middlename
        lastname
        zipCode
        city
        address
        state
        relationShipType
      }
      patient {
        firstname
        middlename
        lastname
        city
        address
        state
        zipcode
      }
    }
  }
`;
export const DOWNLOAD_SUPERBILL_PDF = gql`
  mutation downloadSuperbill($id: ID) {
    generateSuperBill(id: $id) {
      superBillPdf
    }
  }
`;
export const UPDATE_SUPERBILL = gql`
  mutation updateSuperBill($id: ID, $superbillInput: editableBillInput) {
    updateSuperBill(id: $id, superbillInput: $superbillInput) {
      _id
    }
  }
`;

export function useUpdatesuperbill() {
  const [updateSuperBill, { data, error, loading }] = useMutation(
    UPDATE_SUPERBILL,
    {
      refetchQueries: [GET_FINANCES, SESSION_BY_PERIOD_BILL, SUPER_BILL_BY_ID],
    }
  );

  return { updateSuperBill, data, error, loading };
}

export default function useGenerateSuperbill() {
  const [createSuperbill, { data, error, loading }] = useMutation(
    GENERATE_SUPERBILL_PDF,
    {
      refetchQueries: [GET_FINANCES, SESSION_BY_PERIOD_BILL],
    }
  );

  return { createSuperbill, data, error, loading };
}

export function useDownloadSuperbill() {
  const [generateSuperBill, { data, error, loading }] = useMutation(
    DOWNLOAD_SUPERBILL_PDF,
    {
      refetchQueries: [GET_FINANCES, SESSION_BY_PERIOD_BILL],
    }
  );

  return { generateSuperBill, data, error, loading };
}
