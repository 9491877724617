import React from 'react';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as PasswordIcon } from 'assets/passcode-lock.svg';
import { Box, Typography } from '@material-ui/core';
import { ITeam } from './TeamSettingsTable';
import { useStyles } from '../styles';
import Input from 'components/ui/Inputs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/';
import * as yup from 'yup';
import Button from 'components/ui/Button';

interface IEditProps {
  open: boolean;
  onClose: () => void;
  user: ITeam;
}

function PasswordModal({ open, onClose, user }: IEditProps) {
  const classes = useStyles();

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Password is a required field')
      .min(8, 'Password length should be at least 8 characters')
      .matches(
        /^(?=.*[A-Z]).*$/,
        'Password must contain at least one uppercase letter'
      )
      .matches(/^(?=.*\d).*$/, 'Password must contain at least one digit')
      .matches(
        /^(?=.*[@$!%*#?&^]).*$/,
        'Password must contain at least one special character'
      ),
  });

  const { control, register, watch, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  // Watch the password field to check if it's empty
  const passwordValue = watch('password', '');

  const onSubmit = (data) => {};

  return (
    <BasicModal open={open} onClose={onClose} handleClose={onClose} isNew>
      <form className={classes.PasswordModal} onSubmit={handleSubmit(onSubmit)}>
        <PasswordIcon />
        <Typography className={classes.modalTitle}>
          {`Create a new password for ${user?.firstname}`}
        </Typography>
        <Typography className={classes.modalText}>
          Type in a new password for{' '}
          <span style={{ fontWeight: '700' }}>
            {`${user?.firstname} ${user?.lastname}`}
          </span>
          . This will send them an email with the new password.
        </Typography>
        <Box width="100%" my="1.5rem">
          <Input
            type="password"
            placeholder={`${user?.firstname}’s new password`}
            control={control}
            name="password"
            variant="filled"
            inputRef={register}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ gap: '1rem' }}
        >
          <Button onClick={onClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button type="submit" className={classes.saveButton}>
            Confirm
          </Button>
        </Box>
      </form>
    </BasicModal>
  );
}

export default PasswordModal;
