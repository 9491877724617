import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker, ChromePicker } from 'react-color';
import useStyles from './styles';
import { Box, Divider, Typography } from '@material-ui/core';
import { highlightColors, texColors } from './Utils';

// Utility function to convert HEX to RGB
const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b, a: 1 }; // Assuming full opacity
};

interface ColorPickerProps {
  open: boolean;
  onClose: () => void; // Function to close the color picker
  onColorChange: any;
  highlight?: boolean; // Boolean to determine highlight state
  setIsUseCustom?: any;
  isUseCustom: boolean;
  toggleListVisibilityList: any;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  open,
  onClose,
  onColorChange,
  highlight,
  setIsUseCustom,
  isUseCustom,
  toggleListVisibilityList,
}) => {
  const colorRef = useRef(null);
  const handleClickOutside = (event: MouseEvent | null) => {
    if (colorRef.current && !colorRef.current.contains(event?.target as Node)) {
      toggleListVisibilityList(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  const classes = useStyles({ isSession: false });
  const [color, setColor] = useState({
    hex: '#F670C7',
    rgb: { r: 246, g: 112, b: 199, a: 1 },
  });

  const predefinedColors = highlight ? highlightColors : texColors;

  const handleColorChange = (color) => {
    setColor({
      hex: color.hex,
      rgb: color.rgb,
    });
    onColorChange(color.hex); // Notify the parent about color change
  };

  const handlePredefinedColorSelect = (hex: string) => {
    const rgbColor = hexToRgb(hex);
    setColor({
      hex: hex,
      rgb: rgbColor,
    });
    onColorChange(hex);
  };

  return open ? (
    <div
      className={classes.colorPickerContainer}
      style={{ height: isUseCustom ? '300px' : '90px' }}
      ref={colorRef}
    >
      <div className={classes.predefinedColors}>
        {predefinedColors.map((preColor, index) => (
          <div
            key={index}
            className={`${classes.predefinedColor} ${
              preColor === color.hex ? 'active' : ''
            }`}
            onClick={() => handlePredefinedColorSelect(preColor)}
            style={{ backgroundColor: preColor }}
          />
        ))}
      </div>
      <Divider />
      {!isUseCustom && (
        <Box
          className={classes.customColorButton}
          onClick={() => {
            setIsUseCustom(true);
          }}
        >
          <Typography className={classes.textButtonColor}>
            Use custom{' '}
          </Typography>
        </Box>
      )}
      {isUseCustom && (
        <div className={classes.customColorPicker}>
          <ChromePicker
            color={color.rgb}
            onChange={handleColorChange}
            disableAlpha={true}
            styles={{
              default: {
                picker: {
                  boxShadow: 'none',
                  width: '197px',
                },
              },
            }}
          />
        </div>
      )}
    </div>
  ) : null;
};
export default ColorPicker;
