import { gql, useMutation } from '@apollo/client';

const CREATE_QUESTIONNAIRE_BY_PATIENT = gql`
  mutation createQuestionnaire(
    $questionnaireInput: QuestionnaireInput
    $patients: [String]
  ) {
    createQuestionnaireByPatient(
      questionnaireInput: $questionnaireInput
      patients: $patients
    ) {
      _id
    }
  }
`;

const useCreateQuestionnairebyPatient = () => {
  return useMutation(CREATE_QUESTIONNAIRE_BY_PATIENT);
};

export default useCreateQuestionnairebyPatient;
