/* eslint-disable */
import React, { ReactElement } from 'react';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import useStyles from './style';
import ScrollBar from 'components/ui/ScrollBar';
import Text from 'Text';
import Skeleton from 'components/ui/Skeleton';
import clsx from 'clsx';

interface ISidebarProps {
  menuData: {
    title: string;
    items: {
      title: string;
      content: ReactElement;
      disabled: boolean;
      index: number;
    }[];
  }[];
  customValue?: number;
  setCustomValue?: (value: number) => void;
  loadingSubscription?: boolean;
}

export default function Sidebar({
  menuData,
  customValue = -1,
  setCustomValue,
  loadingSubscription,
}: ISidebarProps): ReactElement {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (newValue: number): void => {
    customValue >= 0 ? setCustomValue(newValue) : setValue(newValue);
  };

  const tabsRender = menuData.map((category, catIndex) => (
    <Box key={catIndex} mb={catIndex < menuData.length && '1.5rem'}>
      <Skeleton loading={loadingSubscription}>
        <Text
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '20px',
            color: '#475467',
          }}
          i18nKey={category.title}
        >
          {category.title}
        </Text>
      </Skeleton>
      <List component="nav">
        {category.items.map((item) => (
          <Box
            className={clsx(
              classes.ListItem,
              item.disabled && classes.disabled
            )}
          >
            <ListItem
              button
              key={`tab-${item.index}`}
              onClick={() => handleChange(item.index)}
              disabled={item.disabled}
              style={{
                backgroundColor:
                  item.index === customValue ? '#96CEFD' : 'transparent',
                marginBlock: '2px',
              }}
            >
              <Skeleton loading={loadingSubscription}>
                <Text
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: item.index === customValue ? 600 : 400,
                    lineHeight: '20px',
                    opacity: item?.disabled && 0.5,
                  }}
                  i18nKey={item.title}
                >
                  {item.title}
                </Text>
              </Skeleton>
            </ListItem>
          </Box>
        ))}
      </List>
    </Box>
  ));

  const tabsContentRender = menuData.flatMap((category) =>
    category.items.map((item) => (
      <Box
        key={item.index}
        display={
          customValue >= 0
            ? customValue === item.index
              ? 'block'
              : 'none'
            : value === item.index
            ? 'block'
            : 'none'
        }
      >
        {item.content}
      </Box>
    ))
  );

  return (
    <Box display="flex" height="100%" overflow="hidden">
      <Box flexShrink={0} width={250} className={classes.Sidebar}>
        <ScrollBar height="100%" maxHeight="100%">
          <Box
            style={{
              paddingRight: '1rem',
              paddingLeft: '2rem',
              paddingBlock: '2rem',
              boxSizing: 'border-box',
            }}
          >
            {tabsRender}
          </Box>
        </ScrollBar>
      </Box>
      <Box
        flexGrow={1}
        pt="1rem"
        px="1rem"
        mx="2"
        height="100%"
        style={{
          overflow: 'hidden',
        }}
      >
        <Box
          height="100%"
          overflow="hidden"
          style={{ backgroundColor: 'white', borderRadius: '16px 16px 0 0' }}
        >
          <ScrollBar height="100%" maxHeight="100%">
            <Box p="30px">{tabsContentRender}</Box>
          </ScrollBar>
        </Box>
      </Box>
    </Box>
  );
}
