import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';

interface LinkData {
  link?: string;
}

interface LinkConstructorProps {
  data: LinkData;
  api: any;
  readOnly: boolean;
}

export default class Link {
  private api: any;
  private readOnly: boolean;
  private data: LinkData;
  private nodes: { holder: HTMLElement | null };

  constructor({ data, api, readOnly }: LinkConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = data || {};
    this.nodes = {
      holder: null,
    };
  }

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let linkValue = event.target.value;
    if (!linkValue.startsWith('http://') && !linkValue.startsWith('https://')) {
      linkValue = 'https://' + linkValue;
    }

    this.data.link = linkValue;
    this.updateDom();
  };

  private updateDom() {
    if (this.nodes.holder) {
      ReactDOM.render(
        this.readOnly ? (
          <a href={this.data.link} target="_blank">
            {this.data.link}
          </a>
        ) : (
          <div>
            <input
              type="url"
              placeholder="https://"
              value={this.data.link || ''}
              onChange={this.handleInputChange}
              className="link-tool__input"
            />
          </div>
        ),
        this.nodes.holder
      );
    }
  }

  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', 'link-tool');
    this.nodes.holder = rootNode;
    this.updateDom();
    return this.nodes.holder;
  }

  save(): LinkData {
    return this.data;
  }
}
