import React, { useState } from 'react';
import { Box, Chip, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { getTagColor } from 'utils';
import { useStyles } from './styles';
import TagSearchBar from './TagSearchBar';
import Skeleton from 'components/ui/Skeleton';

export interface Itag {
  _id: string;
  tag: string;
  color: string;
}

interface TagsEditBarProps {
  tags: Itag[];
  onAddTag: (newTag: Itag, isNew: boolean) => void;
  onDeleteTag: (tagId: string) => void;
  allTags: Itag[];
  dropdownAnchor: HTMLElement | null;
  dropdownRef: React.RefObject<HTMLDivElement>;
  createLoading: boolean;
}

const TagsEditBar: React.FC<TagsEditBarProps> = ({
  tags,
  onAddTag,
  onDeleteTag,
  allTags,
  dropdownAnchor,
  dropdownRef,
  createLoading,
}) => {
  const classes = useStyles();

  const handleDeleteTag = (tagId: string) => {
    onDeleteTag(tagId);
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      maxHeight="4rem"
      width="100%"
      style={{ overflowY: 'auto', alignItems: 'center' }}
    >
      {tags?.map((tag, index) => (
        <Chip
          key={index}
          label={tag.tag}
          className={classes.chip}
          style={{
            backgroundColor: getTagColor(tag.color).backgroundColor,
            border: getTagColor(tag.color).border,
            color: getTagColor(tag.color).text,
            margin: '0.1rem',
          }}
          size="small"
          onDelete={() => handleDeleteTag(tag._id)}
          deleteIcon={
            <ClearIcon
              style={{
                color: getTagColor(tag.color).text,
              }}
            />
          }
        />
      ))}
      {createLoading && (
        <Chip
          label={
            <Skeleton width={30} loading={true}>
              <Typography>.</Typography>
            </Skeleton>
          }
          className={classes.chip}
          style={{
            backgroundColor: getTagColor('blue').backgroundColor,
            border: getTagColor('blue').border,
            color: getTagColor('blue').text,
            margin: '0.1rem',
          }}
          size="small"
          onDelete={() => {}}
          deleteIcon={
            <ClearIcon
              style={{
                color: getTagColor('blue').text,
              }}
            />
          }
        />
      )}
      <Box ml="5px">
        <TagSearchBar
          onAddTag={onAddTag}
          tags={allTags}
          dropdownAnchor={dropdownAnchor}
          dropdownRef={dropdownRef}
        />
      </Box>
    </Box>
  );
};

export default TagsEditBar;
