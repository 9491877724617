import React from 'react';

const headerForm = ({ data, level }) => {
  const heading = {
    1: <h1 style={{ margin: 0, padding: 0 }}>{data}</h1>,
    2: <h2 style={{ margin: 0, padding: 0 }}>{data}</h2>,
    3: <h3 style={{ margin: 0, padding: 0 }}>{data}</h3>,
  };
  return <>{heading[level]}</>;
};

export default headerForm;
