import { Box, Typography, Grid, InputAdornment } from '@material-ui/core';
import { useStyles } from '../Insurance';
import React from 'react';
import PrimaryInsurance from 'assets/PrimaryInsurance';
import SecondaryInsurance from 'assets/SecondaryInsurance';
import OtherInsurance from 'assets/OtherInsurance';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import { Controller } from 'react-hook-form';

const InsuranceInfo = (props) => {
  const classes = useStyles();
  const insurance = [
    {
      name: 'Primary',
      icon: (
        <PrimaryInsurance
          color={
            props.watch()?.insuranceType === 'Primary' ? 'white' : '#182230'
          }
        />
      ),
    },
    {
      name: 'Secondary',
      icon: (
        <SecondaryInsurance
          color={
            props.watch()?.insuranceType === 'Secondary' ? 'white' : '#182230'
          }
        />
      ),
    },
    {
      name: 'Other',
      icon: (
        <OtherInsurance
          color={props.watch()?.insuranceType === 'Other' ? 'white' : '#182230'}
        />
      ),
    },
  ];

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.relationShipText}>
          Insurance type
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Controller
            name="insuranceType"
            control={props.control}
            defaultValue="Primary"
            render={({ value, onChange }) => (
              <>
                {insurance?.map((insurance) => (
                  <Box
                    key={insurance.name}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.relationShipBox}
                    ml={2}
                    style={{
                      border: `1px solid ${
                        value === insurance?.name ? '#0265DC' : '#E0E0E0'
                      }`,
                      backgroundColor:
                        value === insurance?.name ? '#0265DC' : '#fff',
                    }}
                    onClick={() => onChange(insurance?.name)}
                  >
                    {insurance.icon}
                    <Typography
                      className={classes.relationShipLabel}
                      style={{
                        color: value === insurance?.name ? '#fff' : '#182230',
                      }}
                    >
                      {insurance.name}
                    </Typography>
                  </Box>
                ))}
              </>
            )}
          />
        </Box>
      </Box>
      <Grid container spacing={2} style={{ marginBlock: '16px' }}>
        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Payer'}
            {...props}
            name="payer"
            type="select"
            options={['payer 1', 'payer 2']}
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Member ID'}
            {...props}
            name="memberID"
            type="text"
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Group ID'}
            {...props}
            name="groupID"
            type="text"
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock label={'Plan ID'} {...props} name="planID" type="text" />
        </Grid>

        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Copay/Coinsurance'}
            {...props}
            name="copay"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Deductible'}
            {...props}
            name="deductible"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Effective start date'}
            {...props}
            name="startDate"
            type="date"
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Effective end date'}
            {...props}
            name="endDate"
            type="date"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InsuranceInfo;
