import React from 'react';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tabs from 'components/ui/Tabs';
import { useStyles } from '../styles';
import CustomTab from './CustomTab';
import { roles } from '../constants';
import SettingTab from './SettingsTab';
import CustomButton from 'components/ui/Button';
import { ReactComponent as AddIcon } from 'assets/patientActivities/pluss.svg';
import Text from 'Text';
import AddRoleModal from './AddRoleModal';
import RemoveRoleModal from './RemoveRoleModal';

type PermissionLevel = 'None' | 'Assigned only' | 'Everything' | 'Owned only';
type PrepaymentType = 'required' | 'none';
type LocationType = 'in person' | 'online';

interface Permission {
  category: string;
  view: PermissionLevel;
  edit: PermissionLevel;
  delete: PermissionLevel;
}

interface Service {
  _id: string;
  name: string;
  duration: number;
  rate: number;
  currency: string;
  prepayment: PrepaymentType;
  location: LocationType;
}

export interface IRole {
  role: string;
  permissions: Permission[];
  services: Service[];
}

function PermissionSettings() {
  const classes = useStyles();
  const [selectedRole, setSelectedRole] = React.useState<IRole | null>(
    roles[0]
  );
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openRemove, setOpenRemove] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleSelectRole = (index: number) => {
    setSelectedTab(index);
    setSelectedRole(roles[index]);
  };
  const handleEdit = () => {
    setOpenEdit(true);
  };
  const handleRemove = () => {
    setOpenRemove(true);
  };

  return (
    <Grid container>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tabs
          tabsMode="simple"
          tabClassHeader={classes.permissionsTab}
          tabsBar={roles.map((role) => (
            <CustomTab
              isSelected={selectedRole === role}
              role={role}
              handleEdit={handleEdit}
              handleRemove={handleRemove}
            />
          ))}
          tabsContent={[]}
          setCustomValue={handleSelectRole}
          customValue={selectedTab}
        />
        <CustomButton
          className={classes.addBtn}
          buttonType="reset"
          onClick={() => setOpenAdd(true)}
        >
          <AddIcon />
          <Text i18nKey="Add team member" className={classes.addBtnText}>
            Add role
          </Text>
        </CustomButton>
      </Box>
      <SettingTab role={selectedRole} />
      <AddRoleModal open={openAdd} onClose={() => setOpenAdd(false)} />
      <RemoveRoleModal
        open={openRemove}
        onClose={() => setOpenRemove(false)}
        role={selectedRole}
      />
    </Grid>
  );
}

export default PermissionSettings;
