import React from 'react';
import ReactDOM from 'react-dom';
import MultiChoice from '../components/MultiChoice';
import FormGenerator from 'pages/FormsPage/FormGenerator';

interface TimelineData {
  events?: Array<any>;
  questions: Array<any>;
  question?: any;
}

interface TimelineConfig {
  edit?: boolean;
}

interface TimelineConstructorProps {
  data: TimelineData;
  config?: TimelineConfig;
  api: any;
  readOnly: boolean;
}

export default class MultipleChoice {
  private api: any;
  private readOnly: boolean;
  private data: TimelineData;
  private CSS: { wrapper: string };
  private nodes: { holder: HTMLElement | null };
  private edit: boolean;

  static get toolbox() {
    return {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 12L10.5 15L16.5 9M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      title: 'Multiple choice',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }: TimelineConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    this.edit = config.edit;
    const defaultQuestion = {
      question: 'Custom question',
      type: 'Multiple choice',
      options: ['Option 1', 'Option 2'],
      description: '',
      required: false,
      isDescription: false,
      range: [1, 3],
    };

    this.data = {
      questions: data.questions || [defaultQuestion],
    };

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    };

    this.nodes = {
      holder: null,
    };
  }
  // private setQuestions = (newQuestions: Array<any>) => {
  //   console.log(newQuestions, 'newQuestions');
  //   this.data = {
  //     ...this.data,
  //     questions: newQuestions,
  //   };
  // };

  private setQuestions = (
    newQuestionsOrUpdater: Array<any> | ((questions: Array<any>) => Array<any>)
  ) => {
    if (typeof newQuestionsOrUpdater === 'function') {
      // Handle functional update
      const updatedQuestions = (
        newQuestionsOrUpdater as (questions: Array<any>) => Array<any>
      )(this.data.questions);
      this.data = {
        ...this.data,
        questions: updatedQuestions,
      };
    } else {
      // Handle direct array update
      this.data = {
        ...this.data,
        questions: newQuestionsOrUpdater,
      };
    }
    this.updateDom();
  };
  private updateDom() {
    if (this.nodes.holder) {
      ReactDOM.render(
        <FormGenerator
          questions={this.data.questions}
          setQuestions={this.setQuestions}
          question={this.data.question}
          isNotes={false}
          api={this.api} // Pass the Editor.js API
          deleteBlock={this.deleteBlock}
          getBlockIndex={this.getBlockIndex}
          edit={this.edit}
        />,
        this.nodes.holder
      );
    }
  }
  // private setQuestionssss = (
  //   updateFn: (questions: Array<any>) => Array<any>
  // ) => {
  //   // Check if the input is a function
  //   if (typeof updateFn === 'function') {
  //     // Call the function with the current questions to get the updated questions
  //     const updatedQuestions = updateFn(this.data.questions);
  //     console.log(updatedQuestions, 'updatedQuestions');
  //     // Update the data with the new questions array
  //     this.data = {
  //       ...this.data,
  //       questions: updatedQuestions,
  //     };

  //     console.log(updatedQuestions, 'updated questions');
  //   } else {
  //     console.error('Expected a function that returns an array of questions');
  //   }
  // };

  private changeQuestion = (addedQuestion: string) => {
    this.data = {
      ...this.data,
      question: { ...this.data.question, question: addedQuestion },
    };
  };

  private deleteBlock = (index: number) => {
    this.api.blocks.delete(index);
  };
  private getBlockIndex = () => {
    return this.api.blocks.getCurrentBlockIndex();
  };
  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    // const onDataChange = (newData: TimelineData) => {
    //   this.data = {
    //     ...newData,
    //   };
    // };

    ReactDOM.render(
      // <MultiChoice
      //   onDataChange={onDataChange}
      //   readOnly={this.readOnly}
      //   data={this.data}
      // />,
      <FormGenerator
        questions={this.data.questions}
        api={this.api} // Pass the Editor.js API
        setQuestions={this.setQuestions}
        changeQuestion={this.changeQuestion}
        question={this.data.question}
        isNotes={false} // You can modify this based on your needs
        deleteBlock={this.deleteBlock}
        getBlockIndex={this.getBlockIndex}
        edit={this.edit}
      />,
      rootNode
    );

    return this.nodes.holder;
  }

  save(): TimelineData {
    return this.data;
  }
}
