import React from 'react';
import { Avatar, Box, Chip, Tooltip, Typography } from '@material-ui/core';
import Text from 'Text';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import { ReactComponent as MoreIcon } from 'assets/patientActivities/more.svg';
import { useStyles } from '../styles';
import DropDownSelect from '../DropdownSelect';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ITeam } from './TeamSettingsTable';

dayjs.extend(relativeTime);

const getRelativeTime = (timestamp: number): string => {
  return `${dayjs().from(dayjs(timestamp), true)} ago`;
};

const statusColor = (status: string) => {
  switch (status) {
    case 'Pending':
      return {
        color: '#B54708',
        backgroundColor: '#FFFAEB',
        border: '1px solid #FEDF89',
      };
    case 'Accepted':
      return {
        color: '#067647',
        backgroundColor: '#ECFDF3',
        border: '1px solid #ABEFC6',
      };
    case 'Expired':
      return {
        color: '#B42318',
        backgroundColor: '#FEF3F2',
        border: '1px solid #FECDCA',
      };
    default:
      return {
        color: '#B54708',
        backgroundColor: '#FFFAEB',
        border: '1px solid #FEDF89',
      };
  }
};

interface IProps {
  hoveredRowIndex: string | null;
  handleOpenDropDown: (e: React.MouseEvent<HTMLElement>, row: any) => void;
  handleEditFields: (row: ITeam) => void;
  handleChangeRole: (row: ITeam, newRole: string) => void;
}

function Columns({
  hoveredRowIndex,
  handleEditFields,
  handleOpenDropDown,
  handleChangeRole,
}: IProps) {
  const classes = useStyles();
  const columns = [
    {
      id: 'title',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="name" style={{ whiteSpace: 'noWrap' }}>
            Name
          </Text>
        </Box>
      ),
      align: 'left',
      minWidth: 10,
      format: (value, { picture, firstname, lastname }) => {
        return (
          <Box display="flex" alignItems="center">
            <Avatar
              src={picture}
              style={{
                width: '30px',
                height: '30px',
              }}
            />
            <Typography
              style={{
                fontWeight: 600,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '14px',
                marginLeft: '1rem',
                fontFamily: 'Inter',
              }}
            >
              {`${firstname} ${lastname}`}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'email',
      minWidth: 10,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Email" style={{ whiteSpace: 'noWrap' }}>
            Email
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        return (
          <Typography
            style={{
              fontWeight: 400,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontFamily: 'Inter',
            }}
          >
            {value}
          </Typography>
        );
      },
    },
    {
      id: 'Supervisor',
      minWidth: 10,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Supervisor" style={{ whiteSpace: 'noWrap' }}>
            Supervisor
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        const { picture, firstname, lastname } = value || {};
        return (
          <>
            {!!value ? (
              <Box display="flex" alignItems="center">
                <Avatar
                  src={picture}
                  style={{
                    maxWidth: '30px',
                    maxHeight: '30px',
                  }}
                />
                <Typography
                  style={{
                    fontWeight: 600,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    marginLeft: '1rem',
                    fontFamily: 'Inter',
                  }}
                >
                  {`${firstname} ${lastname}`}
                </Typography>
              </Box>
            ) : (
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  color: '#98A2B3',
                  textDecoration: 'italic',
                }}
              >
                N/A
              </Typography>
            )}
          </>
        );
      },
    },
    {
      id: 'inviteStatus',
      minWidth: 10,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="inviteStatus" style={{ whiteSpace: 'noWrap' }}>
            Invite status
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        return (
          <Chip
            label={value}
            style={{
              borderRadius: '16px',
              fontSize: '12px',
              width: 'auto',
              fontFamily: 'Inter',
              maxHeight: '22px',
              padding: '0',
              fontWeight: 500,
              color: statusColor(value).color,
              backgroundColor: statusColor(value).backgroundColor,
              border: statusColor(value).border,
            }}
          />
        );
      },
    },
    {
      id: 'role',
      minWidth: 10,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Role" style={{ whiteSpace: 'noWrap' }}>
            Role
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value, rest) => {
        return (
          <>
            {value === 'Admin' ? (
              <Typography>Admin (Owner)</Typography>
            ) : (
              <Box width="158px">
                <DropDownSelect
                  defaultMenuName={value}
                  setValue={(role) => {
                    handleChangeRole(rest, role);
                  }}
                  options={['Admin', 'Provider', 'Assistant']}
                  onClickAddOption={() => {}}
                  addOptionText="Add a new role"
                  hasAddOption
                />
              </Box>
            )}
          </>
        );
      },
    },
    {
      id: 'lastActive',
      minWidth: 10,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Role" style={{ whiteSpace: 'noWrap' }}>
            Last active
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        return (
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
              color: '#344054',
              whiteSpace: 'nowrap',
            }}
          >
            {!!value ? getRelativeTime(value) : '—'}
          </Typography>
        );
      },
    },
    {
      id: 'actions',
      label: '',
      align: 'right',
      format: (value, row) => (
        <Box>
          {row.role !== 'Admin' && (
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                className={`${classes.actions} ${
                  hoveredRowIndex === row?._id ? classes.actionsVisible : ''
                }`}
              >
                <EditIcon
                  data-cy="edit-action"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditFields(row);
                  }}
                  style={{ cursor: 'pointer', marginRight: '1rem' }}
                />
              </Box>
              <Box
                onClick={(e) => handleOpenDropDown(e, row)}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ cursor: 'pointer', width: '18px' }}
              >
                <MoreIcon data-cy="more-action" />
              </Box>
            </Box>
          )}
        </Box>
      ),
    },
  ];

  return columns;
}

export default Columns;
