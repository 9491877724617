import { Box, Checkbox, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import Text from 'Text';
import { Controller } from 'react-hook-form';
import Switch from 'components/ui/SwitchNew';

export const useStyles = makeStyles((theme) => ({
  requirePrepayContainer: {
    border: '1px solid #D0D5DD',
    borderRadius: '12px',
    padding: '12px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  requirePrepayTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 600,
    color: '#344054',
  },
  requirePrepayDesc: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
  },
}));
export const IsDefaultComponent = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.requirePrepayContainer} mb={2} mt={3}>
      <Box>
        <Typography className={classes.requirePrepayTitle}>
          {props?.title}
        </Typography>
        <Typography className={classes.requirePrepayDesc}>
          {props?.description}
        </Typography>
      </Box>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={true}
        render={({ onChange, value, ref }) => {
          if (props.type === 'switch')
            return (
              <Switch
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                inputRef={ref}
                disabled={props?.isDefault}
              />
            );
          return (
            <Checkbox
              size="medium"
              checked={value}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              checkedIcon={<CheckedIcon />}
              icon={<UncheckedIcon />}
              style={{ backgroundColor: 'transparent' }}
              disabled={props?.isDefault}
            />
          );
        }}
      />
    </Box>
  );
};
