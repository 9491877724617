import { gql, useMutation } from '@apollo/client';
import { GET_DOCTOR_BY_ID } from './useGetDoctor';
import { ME_QUERY } from 'contexts/AuthContext';

export const TOGGLE_DEMO = gql`
  mutation toggleDemo {
    toggleDemo
  }
`;

export default function useToggleDemo() {
  const [toggleDemo, { loading, error }] = useMutation(TOGGLE_DEMO, {
    refetchQueries: [ME_QUERY],
  });

  return { toggleDemo, loading, error };
}
