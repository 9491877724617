import React, { useEffect } from 'react';
import { Box, Typography, Grid, Checkbox } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import useStyles from './styles';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import ClientAdultIcon from 'assets/ClientAdultIcon';
import ClientMinor from 'assets/ClientMinorIcon';
import { ReactComponent as BellIcon } from 'assets/Bell.svg';
import Switch from 'components/ui/SwitchNew';
import CheckboxBlock from './CheckboxBlock';
import PaymentBlock from './PaymentBlock';
import SearchBar from 'pages/AdminPage/TabsContent/templatesTabs/SearchBar';
// import { templates } from 'pages/AdminPage/TabsContent/templatesTabs/constants';
import { Controller, useFormContext } from 'react-hook-form';
import { TemplateType } from 'pages/AdminPage/TabsContent/templatesTabs/Columns';
import useGetAutoSendTemplates from '@eggmed/graphql-client/operations/patientActivitiesOperations/useGetAutoSendTemplates';
import useGetSearchTemplates from '@eggmed/graphql-client/operations/patientActivitiesOperations/useGetSearchTemplates';

interface Props {
  checked: boolean;
  handleChange: (state: boolean) => void;
  commonProps: any;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}
const AddPatientModal = ({
  checked,
  handleChange,
  commonProps,
  error,
  setError,
}: Props) => {
  const classes = useStyles();
  useEffect(() => {
    return () => setError('');
  }, []);
  const { t } = useTranslation();
  const [sendIntake, setSendIntake] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [usedTemplates, setTemplates] = React.useState([]);
  const [selectedTemplates, setSelectedTemplates] = React.useState<string[]>(
    []
  );
  const { setValue } = commonProps;
  const { data } = useGetAutoSendTemplates();
  const { data: templates } = useGetSearchTemplates();
  const autoSendTemplates = data?.doctorAutoSendTemplates;
  useEffect(() => {
    setTemplates(autoSendTemplates);

    const initialSelectedTemplates = autoSendTemplates.map(
      (template) => template._id
    );
    setSelectedTemplates(initialSelectedTemplates);
    setValue('selectedTemplates', initialSelectedTemplates);
  }, [autoSendTemplates, setValue]);

  const handleTemplateSelect = (templateId: string) => {
    const currentSelected = selectedTemplates.includes(templateId);
    const updatedSelected = currentSelected
      ? selectedTemplates.filter((id: string) => id !== templateId)
      : [...selectedTemplates, templateId];
    setSelectedTemplates(updatedSelected);
    setValue('selectedTemplates', updatedSelected);
  };

  const onSearchClick = (template) => {
    if (usedTemplates.some((el) => el?._id === template?._id)) {
      const filteredTemplates = [...usedTemplates]?.filter(
        (el) => el?._id !== template?._id
      );
      setTemplates(filteredTemplates);
    } else {
      setTemplates([...usedTemplates, template]);
    }
    handleTemplateSelect(template._id);
  };

  return (
    <Box>
      <Box px={'2rem'} pt={2} pb="2rem">
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Text i18nKey="ageGroup" className={classes.agegroup}>
              Client type
            </Text>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              ml={-1}
              mt={1}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.ageBox}
                style={{
                  border: `1px solid ${
                    checked === true ? '#0265DC' : '#E0E0E0'
                  }`,
                  backgroundColor: checked === true ? '#0265DC' : '#fff',
                }}
                onClick={() => handleChange(true)}
              >
                <ClientAdultIcon
                  color={checked === true ? '#fff' : '#182230'}
                />
                <Text
                  className={classes.ageText}
                  style={{ color: checked === true ? '#fff' : '#182230' }}
                  i18nKey="adult"
                >
                  Adult
                </Text>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.ageBox}
                style={{
                  border: `1px solid ${
                    checked != true ? '#0265DC' : '#E0E0E0'
                  }`,
                  backgroundColor: checked != true ? '#0265DC' : '#fff',
                  marginLeft: '8px',
                }}
                onClick={() => handleChange(false)}
              >
                <ClientMinor color={checked != true ? '#fff' : '#182230'} />
                <Text
                  className={classes.ageText}
                  style={{ color: checked != true ? '#fff' : '#182230' }}
                  i18nKey="minor"
                >
                  Minor
                </Text>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('First name')}
              {...commonProps}
              name="firstname"
              type="text"
              isRequired
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('Middle name')}
              {...commonProps}
              name="middlename"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('Last name')}
              {...commonProps}
              name="lastname"
              type="text"
              isRequired
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('Date of birth')}
              {...commonProps}
              name="birthday"
              type="date"
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <InputBlock
              label={t('Email')}
              {...commonProps}
              name="email"
              type="text"
              isRequired
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt="8px"
            >
              <Box display="flex" alignItems="center">
                <BellIcon />
                <Text className={classes.aboutText} i18nKey="Email about">
                  Email about
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <Controller
                  name="email_about_upcoming"
                  render={({ onChange, value, ref }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      inputRef={ref}
                    />
                  )}
                  {...commonProps}
                />
                <Text
                  className={classes.switchText}
                  i18nKey="Upcoming sessions"
                >
                  Upcoming sessions
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <Controller
                  name="email_about_canceled"
                  render={({ onChange, value, ref }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      inputRef={ref}
                    />
                  )}
                  {...commonProps}
                />
                <Text
                  className={classes.switchText}
                  i18nKey="Cancelled sessions"
                >
                  Cancelled sessions
                </Text>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <InputBlock
              label={t('Phone number')}
              {...commonProps}
              name="phone"
              type="phone"
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mt="8px"
            >
              <Box display="flex" alignItems="center">
                <BellIcon />
                <Text className={classes.aboutText} i18nKey="Send texts about">
                  Send texts about
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <Controller
                  name="text_about_upcoming"
                  render={({ onChange, value, ref }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      inputRef={ref}
                    />
                  )}
                  {...commonProps}
                />
                <Text
                  className={classes.switchText}
                  i18nKey="Upcoming sessions"
                >
                  Upcoming sessions
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <Controller
                  name="text_about_canceled"
                  render={({ onChange, value, ref }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      inputRef={ref}
                    />
                  )}
                  {...commonProps}
                />
                <Text
                  className={classes.switchText}
                  i18nKey="Cancelled sessions"
                >
                  Cancelled sessions
                </Text>
              </Box>
            </Box>
          </Grid>

          <Box
            display="flex"
            flexDirection="column"
            mt="2rem"
            style={{
              width: '100%',
              borderRadius: '16px',
              border: '1px solid #D0D5DD',
              padding: '12px',
            }}
          >
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                className={classes.intakeText}
                i18nKey="Send intake documents"
              >
                Send intake documents
              </Text>
              <Controller
                name="sendIntake"
                render={({ onChange, value, ref }) => (
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    inputRef={ref}
                  />
                )}
                {...commonProps}
              />
            </Box>
            {commonProps.watch()?.sendIntake && (
              <Box display="flex" flexDirection="column" mt="1rem">
                {usedTemplates.map((template) => (
                  <Controller
                    key={template.id}
                    name="selectedTemplates"
                    render={({ value, ref }) => (
                      <CheckboxBlock
                        name={template._id}
                        text={template.title}
                        inputRef={ref}
                        onChange={() => handleTemplateSelect(template._id)}
                        checked={selectedTemplates.includes(template._id)}
                      />
                    )}
                    {...commonProps}
                  />
                ))}

                {!search ? (
                  <Text
                    onClick={() => setSearch(true)}
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '20px',
                      textAlign: 'left',
                      color: '#0265DC',
                      cursor: 'pointer',
                    }}
                    i18nKey="+ Add document"
                  >
                    + Add document
                  </Text>
                ) : (
                  <SearchBar
                    templates={templates?.doctorSearchedTemplates}
                    onSearch={() => {}}
                    onSearchClick={onSearchClick}
                  />
                )}
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            mt="2rem"
            style={{
              width: '100%',
              borderRadius: '16px',
              border: '1px solid #D0D5DD',
              padding: '12px',
            }}
          >
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                className={classes.intakeText}
                i18nKey="Add client’s payment method"
              >
                Add client’s payment method
              </Text>

              <Controller
                name="payment"
                render={({ onChange, value, ref }) => (
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    inputRef={ref}
                  />
                )}
                {...commonProps}
              />
            </Box>
            {commonProps.watch()?.payment && <PaymentBlock />}
          </Box>
        </Grid>
        {error && (
          <Box display="flex" alignItems="center" mt={1}>
            <ErrorIcon style={{ color: 'red', fontSize: '15px' }} />
            <Typography style={{ color: 'red', fontSize: '14px' }}>
              {error}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AddPatientModal;
