import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Chip, List, Popper } from '@material-ui/core';
import { useStyles } from './styles';
import Input from 'components/ui/Inputs';
import { getTagColor } from 'utils';
import AddIcon from '@material-ui/icons/Add';
import { Itag } from './TagEdit';

interface TemplateSearchBarProps {
  onAddTag: (newTag: Itag, isNew: boolean) => void;
  tags: Itag[];
  dropdownAnchor: HTMLElement | null;
  dropdownRef: React.RefObject<HTMLDivElement>;
}

const TagsSearch: React.FC<TemplateSearchBarProps> = ({
  onAddTag,
  tags,
  dropdownAnchor,
  dropdownRef,
}) => {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inputWidth, setInputWidth] = useState('auto');

  const hiddenSpanRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const filteredTags = tags.filter((tag) =>
    tag.tag.toLowerCase().includes(query.toLowerCase())
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setDropdownOpen(true);
  };

  const handleCreateTag = () => {
    if (query) {
      const newTag: Itag = {
        _id: query,
        tag: query,
        color: 'Blue',
      };
      onAddTag(newTag, true);
      setQuery('');
    }
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      focusInput();
    };
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (hiddenSpanRef.current) {
      const spanWidth = hiddenSpanRef.current.offsetWidth;
      setInputWidth(`${spanWidth}px`);
    }
  }, [query]);

  return (
    <div>
      <Box
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Input
          type="text"
          variant="filled"
          value={query}
          onChange={handleSearch}
          inputRef={inputRef}
          InputProps={{
            disableUnderline: true,
            style: {
              border: 'none',
              boxShadow: 'none',
              padding: '0',
              margin: '0',
              outline: 'none',
              borderRadius: '0',
              backgroundColor: 'transparent',
              width: inputWidth,
              boxSizing: 'border-box',
              fontSize: '12px',
              caretColor: 'transparent',
            },
            inputProps: {
              style: {
                color: 'black',
                padding: '0',
                margin: '0',
                outline: 'none',
                border: 'none',
                backgroundColor: 'transparent',
                width: 'fit-content',
                fontSize: '12px',
              },
            },
          }}
        />

        <span
          ref={hiddenSpanRef}
          style={{
            visibility: 'hidden',
            whiteSpace: 'pre',
            position: 'absolute',
            fontSize: '12px',
          }}
        >
          {query || ' '}
        </span>
        <Box
          className={classes.caret}
          style={{
            position: 'absolute',
            left: inputWidth,
          }}
        />
      </Box>
      <Popper
        open={true}
        ref={dropdownRef}
        anchorEl={dropdownAnchor}
        placement="bottom-start"
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: false,
          },
        }}
        style={{ zIndex: 1300, width: 260 }}
      >
        <Box className={classes.dropdown}>
          <Typography
            style={{
              fontSize: '12px',
              fontWeight: 400,
              color: '#98A2B3',
            }}
          >
            Search or create new tags
          </Typography>
          <List
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {filteredTags.length > 0 ? (
              filteredTags.map((tag) => (
                <Box key={tag._id} className={classes.tagListItem}>
                  <Chip
                    label={tag.tag}
                    className={classes.chip}
                    style={{
                      backgroundColor: getTagColor(tag.color).backgroundColor,
                      border: getTagColor(tag.color).border,
                      color: getTagColor(tag.color).text,
                      fontSize: '12px',
                      margin: 0,
                      padding: 0,
                    }}
                    size="small"
                  />
                  <Box
                    onClick={() => onAddTag(tag, false)}
                    className={classes.addButton}
                  >
                    <AddIcon style={{ fontSize: '14px' }} />
                    <Typography
                      style={{
                        fontSize: '12px',
                        fontWeight: 500,
                      }}
                    >
                      Add
                    </Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Box className={classes.tagListItem}>
                <Chip
                  label={query}
                  className={classes.chip}
                  style={{
                    backgroundColor: '#F5FEFF',
                    border: '1px solid #A5F0FC',
                    color: '#0E7090',
                    fontSize: '12px',
                    margin: 0,
                    padding: 0,
                  }}
                  size="small"
                />
                <Box onClick={handleCreateTag} className={classes.addButton}>
                  <AddIcon style={{ fontSize: '14px' }} />
                  <Typography
                    style={{
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  >
                    Create
                  </Typography>
                </Box>
              </Box>
            )}
          </List>
        </Box>
      </Popper>
    </div>
  );
};

export default TagsSearch;
