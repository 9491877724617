import React, { useEffect, useState } from 'react';
import BasicModal from 'components/ui/Modal';
import ScrollBar from 'components/ui/ScrollBar';
import { ITeam } from './TeamSettingsTable';
import TeamMemberForm from './TeamMemberForm';
import { FormProvider, useForm } from 'react-hook-form';

interface IEditProps {
  open: boolean;
  onClose: () => void;
  values: ITeam;
  editMode: boolean;
}

function EditModal({ open, onClose, values, editMode }: IEditProps) {
  const methods = useForm({
    defaultValues: {
      firstname: values?.firstname,
      lastname: values?.lastname,
      email: values?.email,
      role: values?.role,
      supervisor: values?.Supervisor,
    },
  });
  const { reset } = methods;
  useEffect(() => {
    if (values) {
      reset({
        firstname: values?.firstname,
        lastname: values?.lastname,
        email: values?.email,
        role: values?.role,
        supervisor: values?.Supervisor,
      });
    }
  }, [reset, values]);
  return (
    <FormProvider {...methods}>
      <BasicModal
        open={open}
        title={editMode ? 'Edit info' : 'Add a team member'}
        titlePadding="3rem"
        onClose={onClose}
        handleClose={onClose}
        isFromModal
        isSlide
        divider
      >
        <ScrollBar
          maxHeight="99vh"
          width={'40vw'}
          paddingX={'1rem'}
          paddingBottom={'1rem'}
        >
          <TeamMemberForm editMode={editMode} />
        </ScrollBar>
      </BasicModal>
    </FormProvider>
  );
}

export default EditModal;
