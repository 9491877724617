import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Chip,
  List,
  ListItem,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Itemplate } from './AllTemplates';
import { ReactComponent as SearchIcon } from 'assets/searchIcon.svg';
import { useStyles } from './styles';
import Input from 'components/ui/Inputs';
import { getTagColor } from 'utils';

interface TemplateSearchBarProps {
  templates: Itemplate[];
  onSearch: (query: string) => void;
  onSearchClick?: (template: Itemplate) => void;
}

const TemplateSearchBar: React.FC<TemplateSearchBarProps> = ({
  templates,
  onSearch,
  onSearchClick,
}) => {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch(query);
      setDropdownOpen(false);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setDropdownOpen(true);
  };

  const handleClickAway = (event: MouseEvent | null) => {
    if (ref.current && !ref.current.contains(event?.target as Node)) {
      setDropdownOpen(false);
    }
  };

  const handleClear = () => {
    setQuery('');
    onSearch('');
    setDropdownOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway);
    return () => document.removeEventListener('mousedown', handleClickAway);
  }, []);

  // Group templates by tag title
  const templatesByTag: { [key: string]: Itemplate[] } = {};
  templates.forEach((template) => {
    template?.tags?.forEach((tag) => {
      if (tag?.tag?.toLowerCase()?.includes(query.toLowerCase())) {
        if (!templatesByTag[tag.tag]) {
          templatesByTag[tag.tag] = [];
        }
        templatesByTag[tag.tag].push(template);
      }
    });
  });

  const filteredTemplates = templates.filter((template) =>
    template?.title?.toLowerCase().includes(query.toLowerCase())
  );

  const highlightText = (text: string, query: string) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part?.toLowerCase() === query?.toLowerCase() ? (
        <span
          key={index}
          style={{
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: '#3892F3',
            borderRadius: '2px',
          }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div
      style={{
        position: 'relative',
        maxWidth: '500px',
      }}
      ref={ref}
    >
      <Input
        type="text"
        placeholder="Search templates by name or tag"
        variant="filled"
        style={{
          maxHeight: '40px',
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
        value={query}
        onChange={handleSearch}
        onKeyDown={handleKeyPress}
        onClick={() => setDropdownOpen(true)}
        InputProps={{
          endAdornment: query && (
            <IconButton
              className={classes.clearButton}
              onClick={handleClear}
              size="small"
            >
              <ClearIcon />
            </IconButton>
          ),
          startAdornment: <SearchIcon />,
        }}
      />
      {dropdownOpen && query && (
        <Box className={classes.dropdown}>
          <Box className={classes.titleBox}>
            <Typography className={classes.sectionTitle}>Templates</Typography>
            <Box className={classes.countBox}>{filteredTemplates.length}</Box>
          </Box>
          <List>
            {filteredTemplates.map((template) => (
              <Box
                onClick={() => onSearchClick(template)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <ListItem key={template.id} className={classes.templateItem}>
                  <Typography className={classes.templateTitle}>
                    {highlightText(template.title, query)}
                  </Typography>
                  <Typography className={classes.templateType}>
                    {template.type}
                  </Typography>
                </ListItem>
              </Box>
            ))}
          </List>

          <Box className={classes.titleBox}>
            <Typography className={classes.sectionTitle}>Tags</Typography>
            <Box className={classes.countBox}>
              {Object.keys(templatesByTag).length}
            </Box>
          </Box>

          <List>
            {Object.entries(templatesByTag).map(
              ([tagTitle, relatedTemplates], index) => (
                <ListItem key={index} className={classes.tagItem}>
                  <Box display="flex" alignItems="center">
                    <Chip
                      key={index}
                      label={highlightText(tagTitle, query)}
                      className={classes.chip}
                      style={{
                        backgroundColor: getTagColor(
                          relatedTemplates[0].tags.find(
                            (tag) => tag.tag === tagTitle
                          )?.color || ''
                        ).backgroundColor,
                        border: getTagColor(
                          relatedTemplates[0].tags.find(
                            (tag) => tag.tag === tagTitle
                          )?.color || ''
                        ).border,
                        color: getTagColor(
                          relatedTemplates[0].tags.find(
                            (tag) => tag.tag === tagTitle
                          )?.color || ''
                        ).text,
                        fontSize: '14px',
                      }}
                      size="small"
                    />
                    <Box className={classes.tagCountBox}>
                      {relatedTemplates.length}
                    </Box>
                  </Box>
                  <List>
                    {relatedTemplates.map((template) => (
                      <ListItem
                        key={template.id}
                        className={classes.templateItem}
                      >
                        <Typography className={classes.templateTitle}>
                          {highlightText(template.title, query)}
                        </Typography>
                        <Typography className={classes.templateType}>
                          {template.type}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
              )
            )}
          </List>
        </Box>
      )}
    </div>
  );
};

export default TemplateSearchBar;
