import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import React from 'react';

export const useStyles = makeStyles((theme) => ({
  text: {
    display: '-webkit-box',
    WebkitLineClamp: 2, // Limits content to 2 lines
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis', // Adds ellipsis (...) for overflow
    whiteSpace: 'normal',
  },
}));

const ParagraphForm = ({ data }) => {
  const classes = useStyles();
  const isHtmlContent =
    typeof data === 'string' && /<\/?[a-z][\s\S]*>/i.test(data);

  return (
    <Typography>
      {isHtmlContent ? (
        <span dangerouslySetInnerHTML={{ __html: data }} />
      ) : (
        data
      )}
    </Typography>
  );
};

export default ParagraphForm;
