import React, { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/';
import * as yup from 'yup';
import clsx from 'clsx';
import {
  InputAdornment,
  IconButton,
  makeStyles,
  FormHelperText,
  InputLabel,
  Box,
  Typography,
} from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import Input from 'components/ui/Inputs';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useEditPasswordUser from 'graphql/operations/userOperations/useUpdatePasswordUser';
import Button from 'components/ui/Button';
import { useToast } from 'hooks/useToast';
import { InfoBlock } from './InfoBlock';
import useCheckPassword from '@eggmed/graphql-client/operations/userOperations/useCheckPassword';

import { BlockNames } from './PersonalInformation';
import { gql, useQuery } from '@apollo/client';
import { getLastUpdatedString } from '../utils';
import dayjs from 'dayjs';
import { GET_CURRENT_USER } from '..';
import BasicModal from 'components/ui/Modal';
import DeleteAccountModal from './DeleteAccountModal';
import useDeleteAccount from '@eggmed/graphql-client/operations/userOperations/useDeleteAccount';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';

export interface IStyleProps {
  isPatient: boolean;
  editableBlock: BlockNames | null;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.44px',
    color: '#425364',
  },
  deleteModal: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  h3: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#344054',
  },
  divider: {
    marginTop: '-15px',
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
  },
  label: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#344054',
    marginTop: '20px',
    [theme.breakpoints.only('md')]: {
      fontSize: '16px',
    },
  },
  marginTop: {
    marginTop: '80px',
  },
  input: {
    background: '#F8FAFB',
    borderRadius: '8px',
  },
  marginTopp: { marginTop: '12.04px' },
  button: {
    width: '139px',
    height: '36px',
    borderRadius: '5px',
  },
  p: {
    width: '316px',
    fontFamily: 'Roboto',
    fontStyle: 'normale',
    fontSize: '20px',
    lineHeight: '39px',
    color: '#425364',
  },
  requiredField: {
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
  },
  error: {
    color: 'red',
    width: '100%',
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  p1: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '23px',
    color: '#425364',
    marginTop: '18px',
    width: '273px',
    height: '17px',
  },
  gridContainerBilling: {
    padding: '0px 0px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  formBottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  buttonBox: {
    marginLeft: 'auto',

    [theme.breakpoints.up('md')]: {
      marginTop: '1rem',
    },
  },
  cancel: {
    marginRight: theme.spacing(2),
    backgroundColor: ({ isPatient }: IStyleProps) =>
      isPatient ? 'rgba(215, 227, 241, 1)' : 'transparent',
    border: ({ isPatient }: IStyleProps) =>
      isPatient
        ? '1px solid rgba(215, 227, 241, 1)'
        : '1px solid rgba(130, 130, 130, 1)',
    color: ({ isPatient }: IStyleProps) =>
      isPatient ? 'rgba(31, 97, 220, 1)' : '#425364',
    textTransform: 'capitalize',
    fontSize: '18px',
    lineHeight: '21px',
    minWidth: '94px',
    fontWeight: 400,
    borderRadius: ({ isPatient }: IStyleProps) => (isPatient ? '25px' : '5px'),
    [theme.breakpoints.only('xl')]: {
      width: '5vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '5vw',
    },
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: 'rgba(195, 207, 221, 1)',
      border: ({ isPatient }: IStyleProps) =>
        isPatient
          ? '1px solid rgba(215, 227, 241, 1)'
          : '1px solid rgba(130, 130, 130, 1)',
    },
  },
  save: {
    backgroundColor: '#1F61DC',
    color: 'white',
    textTransform: 'none',
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 600,
    minWidth: '94px',
    border: '1px solid #1F61DC',
    borderRadius: ({ isPatient }: IStyleProps) => (isPatient ? '25px' : '5px'),
    offset: '1px 3px rgba(153, 153, 153, 0.1)',
    [theme.breakpoints.only('xl')]: {
      width: '5vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '5vw',
    },
    '&:hover': {
      backgroundColor: '#1D31AA',
    },
  },
  deactivateTxt: {
    color: '#475467',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  titleHeader: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    color: '#101828',
    fontSize: '18px',
  },
  deactivateBtn: {
    fontFamily: 'Inter, sans-serif',
    padding: '9px',
    color: 'white',
    backgroundColor: '#D92D20',
    borderRadius: '8px',
    border: '1px solid #D92D20',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    cursor: ({ editableBlock }: IStyleProps) => editableBlock && 'not-allowed',
    '&:hover': {
      backgroundColor: '#C81C10',
      border: '1px solid #C81C10',
    },
    '& svg': {
      marginRight: '0.4rem',
    },
  },
  accountSettings: {
    opacity: ({ editableBlock }: IStyleProps) => (editableBlock ? 0.5 : 1),
    cursor: ({ editableBlock }: IStyleProps) => editableBlock && 'not-allowed',
  },
}));

const Password = ({ isPatient }: { isPatient?: boolean }): ReactElement => {
  const schema = yup.object().shape({
    oldPassword: yup
      .string()
      .required('Password is a required field')
      .min(8, 'Password length should be at least 8 characters')
      .matches(
        /^(?=.*[A-Z]).*$/,
        'Password must contain at least one uppercase letter'
      )
      .matches(/^(?=.*\d).*$/, 'Password must contain at least one digit')
      .matches(
        /^(?=.*[@$!%*#?&^]).*$/,
        'Password must contain at least one special character'
      ),
    password: yup
      .string()
      .required('Password is a required field')
      .min(8, 'Password length should be at least 8 characters')
      .matches(
        /^(?=.*[A-Z]).*$/,
        'Password must contain at least one uppercase letter'
      )
      .matches(/^(?=.*\d).*$/, 'Password must contain at least one digit')
      .matches(
        /^(?=.*[@$!%*#?&^]).*$/,
        'Password must contain at least one special character'
      ),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), 'null'], 'Passwords must match'),
  });
  const schemaDelete = yup.object().shape({
    deletedPassword: yup
      .string()
      .required('Password is a required field')
      .min(8, 'Password length should be at least 8 characters')
      .matches(
        /^(?=.*[A-Z]).*$/,
        'Password must contain at least one uppercase letter'
      )
      .matches(/^(?=.*\d).*$/, 'Password must contain at least one digit')
      .matches(
        /^(?=.*[@$!%*#?&^]).*$/,
        'Password must contain at least one special character'
      ),
  });
  const { triggerSnack } = useSnackbar();
  const { deleteUser, loading } = useDeleteAccount();

  const [openDelete, setopenDelete] = React.useState(false);
  const { t } = useTranslation();

  const [showPassword, setShowpassword] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<string>('');
  const [editableBlock, setEditableBlock] = useState<BlockNames | null>(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [edit, setEdit] = useState<BlockNames | null>(null);
  const classes = useStyles({ isPatient, editableBlock });
  const { changePassword } = useEditPasswordUser();
  const { checkPassword } = useCheckPassword();
  const [error, setError] = React.useState(null);
  async function handleCheckPassword(passwordData) {
    const data = await checkPassword({
      variables: {
        password: passwordData,
      },
    });
    if (data.data.checkPassword === '') {
      setopenDelete(true);
      setError(null);
    } else {
      setError(data.data.checkPassword);
    }
  }

  async function handleDelete() {
    await deleteUser({
      variables: {
        userId: user?._id || user?.user?._id,
        isDoctor: isPatient ? false : true,
      },
    });
    setopenDelete(false);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('doctor');
    localStorage.removeItem('patient');
    window.location.reload();
  }

  const { user } = useAuth();
  const { data } = useQuery(GET_CURRENT_USER, {
    variables: { userId: user?._id || user?.user?._id },
  });
  useEffect(() => {
    if (data?.user?.passwordChanged) {
      setLastUpdated(data?.user?.passwordChanged);
    }
  }, [data]);
  const { register, control, errors, handleSubmit, formState } = useForm({
    resolver: yupResolver(
      edit === BlockNames.deleteAccount ? schemaDelete : schema
    ),
  });
  const { touched } = formState;
  function handleShowPassword() {
    setShowpassword(!showPassword);
  }
  const onSubmit = (data: any) => {
    if (edit === BlockNames.deleteAccount) {
      handleCheckPassword(data.deletedPassword);
    } else {
      const { oldPassword, password, passwordConfirm } = data;
      const editpassword = {
        oldPassword,
        password,
        passwordConfirm,
      };

      changePassword({
        variables: { passwordInput: editpassword },
      })
        .then(() => {
          triggerSnack();
          setEditableBlock(null);
          setLastUpdated(new Date());
        })
        .catch((err) => {
          setErrorPassword(err.message);
        });
    }
  };
  return (
    <Box className="cyTestPassword">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.gridContainerBilling}>
          <Grid
            item
            xs={12}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            style={{ marginTop: isPatient && '1rem' }}
          >
            <Text i18nKey="Login & security" className={classes.h3}>
              Login and security
            </Text>
          </Grid>
          <Grid container style={{ marginLeft: '2rem', marginTop: '1rem' }}>
            <Grid item xs={12} xl={4} lg={5} md={6} sm={12}>
              <InfoBlock
                title={t('Password')}
                // content={getLastUpdatedString(lastUpdated,t)}
                blockName={BlockNames.password}
                isEditable={editableBlock === BlockNames.password}
                editableBlock={editableBlock}
                setEditableBlock={setEditableBlock}
                loading={false}
              >
                <>
                  <Grid item xs={12} lg={12} xl={12} md={6} sm={12}>
                    <InputLabel className={classes.label}>
                      <Text i18nKey="currentPassword">Current password</Text>
                    </InputLabel>
                    <Input
                      className={clsx(classes.input, classes.marginTopp)}
                      name="oldPassword"
                      error={!!errors?.oldPassword}
                      inputRef={register}
                      variant="filled"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      control={control}
                      InputProps={{
                        style: {
                          height: '45px',
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleShowPassword}
                              aria-label="toggle password visibility"
                            >
                              {showPassword ? (
                                <Visibility
                                  style={{
                                    color: !touched.oldPassword
                                      ? 'gray'
                                      : errors?.oldPassword
                                      ? 'red'
                                      : '#10D57F',
                                  }}
                                />
                              ) : (
                                <VisibilityOff
                                  style={{
                                    color: !touched.oldPassword
                                      ? 'gray'
                                      : errors?.oldPassword
                                      ? 'red'
                                      : '#10D57F',
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errorPassword !== '' && (
                      <Box>
                        <FormHelperText className={classes.error}>
                          <ErrorIcon className={classes.errorIcon} />
                          {errorPassword}
                        </FormHelperText>
                      </Box>
                    )}
                  </Grid>
                  {errors.oldPassword && (
                    <Box>
                      <FormHelperText className={classes.error}>
                        <ErrorIcon className={classes.errorIcon} />
                        {errors?.oldPassword?.message}
                      </FormHelperText>
                    </Box>
                  )}
                  <Grid item xs={12} lg={12} xl={12} md={6} sm={12}>
                    <Box mt={!errors.oldPassword && 4}>
                      <InputLabel className={classes.label}>
                        <Text i18nKey="newPassword">New password</Text>
                      </InputLabel>
                      <Input
                        className={clsx(classes.input, classes.marginTopp)}
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        name="password"
                        inputRef={register}
                        variant="filled"
                        control={control}
                        error={!!errors?.password}
                        InputProps={{
                          style: {
                            height: '45px',
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleShowPassword}
                                aria-label="toggle password visibility"
                              >
                                {showPassword ? (
                                  <Visibility
                                    style={{
                                      color: !touched.password
                                        ? 'gray'
                                        : errors?.password
                                        ? 'red'
                                        : '#10D57F',
                                    }}
                                  />
                                ) : (
                                  <VisibilityOff
                                    style={{
                                      color: !touched.password
                                        ? 'gray'
                                        : errors?.password
                                        ? 'red'
                                        : '#10D57F',
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                  {errors.password && (
                    <Box>
                      <FormHelperText className={classes.error}>
                        <ErrorIcon className={classes.errorIcon} />
                        {errors?.password?.message}
                      </FormHelperText>
                    </Box>
                  )}
                  <Grid item xs={12} lg={12} xl={12} md={6} sm={12}>
                    <Box mt={!errors.password && 4}>
                      <InputLabel className={classes.label}>
                        <Text i18nKey="confirmNewPassword">
                          Confirm new password
                        </Text>
                      </InputLabel>

                      <Input
                        className={clsx(classes.input, classes.marginTopp)}
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        name="passwordConfirm"
                        inputRef={register}
                        variant="filled"
                        control={control}
                        error={!!errors?.passwordConfirm}
                        InputProps={{
                          style: {
                            height: '45px',
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleShowPassword}
                                aria-label="toggle password visibility"
                              >
                                {showPassword ? (
                                  <Visibility
                                    style={{
                                      color: !touched.passwordConfirm
                                        ? 'gray'
                                        : errors?.passwordConfirm
                                        ? 'red'
                                        : '#10D57F',
                                    }}
                                  />
                                ) : (
                                  <VisibilityOff
                                    style={{
                                      color: !touched.passwordConfirm
                                        ? 'gray'
                                        : errors?.passwordConfirm
                                        ? 'red'
                                        : '#10D57F',
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                  {errors.passwordConfirm && (
                    <Box>
                      <FormHelperText className={classes.error}>
                        <ErrorIcon className={classes.errorIcon} />
                        {errors?.passwordConfirm?.message}
                      </FormHelperText>
                    </Box>
                  )}
                </>
              </InfoBlock>
            </Grid>

            <Grid container className={classes.accountSettings}>
              <Grid item xs={12} xl={4} lg={5} md={6} sm={12}>
                <InfoBlock
                  title={t('Delete account')}
                  content={t('permanentlyDeleteYourAccount')}
                  blockName={BlockNames.deleteAccount}
                  isEditable={edit === BlockNames.deleteAccount}
                  editableBlock={edit}
                  setEditableBlock={setEdit}
                  loading={false}
                  isDelete
                >
                  <>
                    <InputLabel className={classes.label}>
                      Current password
                    </InputLabel>
                    <Input
                      className={clsx(classes.input, classes.marginTopp)}
                      name="deletedPassword"
                      // error={!!errors?.deletedPassword}
                      inputRef={register}
                      variant="filled"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      onChange={() => setError(null)}
                      control={control}
                      InputProps={{
                        style: {
                          height: '45px',
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleShowPassword}
                              aria-label="toggle password visibility"
                            >
                              {showPassword ? (
                                <Visibility
                                  style={{
                                    color: !touched.oldPassword
                                      ? 'gray'
                                      : errors?.oldPassword
                                      ? 'red'
                                      : '#10D57F',
                                  }}
                                />
                              ) : (
                                <VisibilityOff
                                  style={{
                                    color: !touched.oldPassword
                                      ? 'gray'
                                      : errors?.oldPassword
                                      ? 'red'
                                      : '#10D57F',
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {(error || errors?.deletedPassword?.message) && (
                      <Typography style={{ marginTop: '5px', color: 'red' }}>
                        {error || errors?.deletedPassword?.message}
                      </Typography>
                    )}
                  </>
                </InfoBlock>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <BasicModal
        open={openDelete}
        onClose={() => setopenDelete(false)}
        className={classes.deleteModal}
        isRegularModal
        handleClose={() => setopenDelete(false)}
      >
        <DeleteAccountModal
          label="Delete"
          description="All your data will be lost when deleting your account"
          deleteBtn="Delete my account"
          handleClose={() => setopenDelete(false)}
          handleDelete={() => {
            handleDelete();
          }}
          loading={loading}
        />
      </BasicModal>
    </Box>
  );
};

export default Password;
