import React from 'react';

function SecondaryInsurance({ color = '#344054' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.514 2.915a3.832 3.832 0 01-2.18.904 3.832 3.832 0 00-3.515 3.515 3.832 3.832 0 01-.904 2.18 3.832 3.832 0 000 4.972c.523.613.84 1.376.904 2.18a3.832 3.832 0 003.515 3.515c.804.064 1.567.38 2.18.904a3.832 3.832 0 004.972 0 3.832 3.832 0 012.18-.904 3.832 3.832 0 003.515-3.515c.064-.804.38-1.567.904-2.18a3.832 3.832 0 000-4.972 3.832 3.832 0 01-.904-2.18 3.832 3.832 0 00-3.515-3.515 3.832 3.832 0 01-2.18-.904 3.832 3.832 0 00-4.972 0z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.686 16c-.117 0-.175-.058-.175-.175l-.007-2.275c0-.047.01-.084.028-.112a.975.975 0 01.077-.112l2.212-2.695V9.21l-2.212-.854c-.07-.023-.105-.093-.105-.21V6.844c0-.117.058-.175.175-.175h1.77a.35.35 0 01.155.028l.126.063 2.31 1.015c.06.023.096.056.105.098.01.042.014.103.014.182v3.024c0 .047-.012.082-.035.105a.989.989 0 00-.084.105l-2.191 2.667v.28h2.506c.117 0 .175.058.175.175v1.414c0 .117-.058.175-.175.175h-4.67z"
        fill={color}
      />
    </svg>
  );
}

export default SecondaryInsurance;
