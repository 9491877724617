import React from 'react';
import { ReactComponent as SaveTemplateIcon } from 'assets/SaveTemplateIcon.svg';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Input from 'components/ui/Inputs';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import ProgressLoader from 'components/ui/ProgressLoader';
import { ReactComponent as AssignIcon } from 'assets/AssignClientModalIcon.svg';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import { PATIENTS_RELATED } from 'pages/PatientListPage';
import { useQuery } from '@apollo/client';
import Inputs from 'components/ui/Inputs';
import { useFormContext } from 'react-hook-form';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';

const useStyles = makeStyles((theme) => ({
  rootAssign: {
    display: 'flex',
    flexDirection: 'column',
    paddingInline: '2rem',
  },
  templateType: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#344054',
    marginTop: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '30vw',
    paddingInline: '2rem',
    boxSizing: 'border-box',
    marginTop: '-0.5rem',
  },
  containerAssign: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '1rem',
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    textAlign: 'left',
    color: '#101828',
  },
  body: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    textAlign: 'left',
    color: '#475467',
  },
  buttonBox: {
    height: '4.25rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginTop: '2rem',
  },
  button: {
    height: theme.typography.pxToRem(44),
    paddingInline: theme.typography.pxToRem(16),
    borderRadius: theme.typography.pxToRem(8),
    textTransform: 'none',
  },
  cancelButton: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#344054',
    backgroundColor: '#FFFFFF',
    marginRight: '1rem',
    border: '1px solid #D0D5DD',
    '&:hover': {
      backgroundColor: '#F7F8FA',
    },
  },
  saveButton: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#FFFFFF',
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    '&:hover': {
      backgroundColor: '#0154CB',
    },
  },
}));

function TemplateSaveModal({
  formTitle,
  isShare,
}: {
  formTitle: string;
  isShare: boolean;
}) {
  const classes = useStyles();
  const { register, control } = useFormContext();
  const { handleSelectedTemplate } = useEditorContext();
  return (
    <Box className={classes.rootAssign}>
      <Box className={classes.containerAssign} width="100%">
        <SaveTemplateIcon />
        <Box className={classes.textBox} width="100%">
          <Typography className={classes.title}>
            Save {formTitle} {isShare && 'before you can assign it'}
          </Typography>
          <Typography className={classes.templateType}>
            Template type
          </Typography>
          <Box mb={4} width="100%">
            <Inputs
              type="select"
              name="formType"
              options={[
                'assessment-form',
                'intake-form',
                'note-taking',
                'other',
              ]}
              register={register}
              control={control}
              variant="filled"
              onChange={(e) => handleSelectedTemplate(e.target.value)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TemplateSaveModal;
