import {
  editorStateFromStringifiedRaw,
  tryParseJSON,
} from '@eggmed/common/utils/DraftUtils';
import { getDayName } from 'utils';
import { colorsMapping } from '../utils';

export const editModeDefaultValues = (
  eventData: any,
  latestDoctor?: {
    firstname: string;
    lastname: string;
    middlename?: string;
  },
  isClickable?: boolean
) => {
  const {
    sessionType,
    isPrepay,
    numberOfRepeat,
    repeatEvery,
    endOn,
    repeatOn,
    endOns,
    locationLink,
  } = eventData || {};
  const result = {
    ...eventData,
    description: editorStateFromStringifiedRaw(
      eventData.description,
      !tryParseJSON(eventData.description)
    ),
    descriptionEvent: editorStateFromStringifiedRaw(
      eventData.description,
      !tryParseJSON(eventData.description)
    ),
    patient: {
      ...eventData.patient,
      name: `${eventData.patient?.firstname} ${eventData.patient?.middlename} ${eventData.patient?.lastname}`,
    },
    doctor: isClickable
      ? {
          ...latestDoctor,
          name: `${latestDoctor?.firstname} ${latestDoctor?.middlename} ${latestDoctor?.lastname}`,
        }
      : {
          ...eventData.doctor,
          name: `${eventData.doctor?.firstname} ${eventData.doctor?.middlename} ${eventData.doctor?.lastname}`,
        },
    conditionType: {
      ...eventData.conditionType,
      name: `${eventData?.conditionType?.tag}`,
    },
    members: eventData?.members?.map((member: any) => ({
      ...member,
      name: member?.email,
      id: member?._id,
    })),
    sessionType,
    isPrepay,
    numberOfRepeat,
    repeatEvery,
    endOn: endOn && new Date(endOn),
    endOns: endOns && new Date(endOns),
    repeatOn,
    activeMeeting: locationLink ? true : false,
  };
  return result;
};

export interface IConvetArrayType {
  firstname: string;
  middlename?: string | null;
  lastname: string;
  _id: string;
  email: string;
  picture?: string;
}
interface IProps {
  tag: string;
  _id: string;
  color: string;
}

export const convertObjectsFormat = (
  array: IConvetArrayType[],
  type: string
) => {
  if (array) {
    return array.map((element: IConvetArrayType) => ({
      ...element,
      name: `${element?.firstname} ${element?.lastname}`,
      id: element?._id,
      type,
      picture: element?.picture,
    }));
  }
  return [];
};

export const convertTagFormat = (array: IProps[]) => {
  if (array) {
    return array.map((element: IProps) => ({
      ...element,
      name: `${element?.tag}`,
      id: element?._id,
      color: colorsMapping[element?.color],
    }));
  }
  return [];
};

export const convertObjectsFormatStaff = (array, type) => {
  if (array) {
    return array.map((element) => ({
      name: `${element?.user?.firstname} ${element?.user?.middlename ?? ''} ${
        element?.user?.lastname
      }`,
      id: element?.user?._id,
      type,
      picture: element?.user?.picture,
    }));
  }
  return [];
};

export const convertObjectsFormatCurrentUser = (array, type) => {
  if (array) {
    return array.map((element) => ({
      name: `${element?.firstname} ${element?.middlename ?? ''} ${
        element?.lastname
      }`,
      id: element?._id,
      type,
      picture: element?.picture,
    }))[0];
  }
  return [];
};
