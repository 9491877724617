import React, { ReactElement } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import Input from 'components/ui/Inputs';
import useStyles from './styles';

function PaymentBlock() {
  const { t } = useTranslation();
  const classes = useStyles();
  const elementOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: 'white',
        },
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid red',
        padding: '40px 12px',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  const elementContainerStyle = {
    padding: '12px 16px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    marginTop: '8px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  };
  return (
    <div style={{ marginTop: '1rem' }}>
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="name" className={classes.stripeFormLabel}>
          {t('Name on card')}
          <span style={{ color: '#D92D20' }}>*</span>
        </label>
        <div style={{ marginTop: '8px' }}>
          <Input name="nameOnCard" variant="filled" />{' '}
        </div>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <label className={classes.stripeFormLabel}>
          {t('Card Number')}
          <span style={{ color: '#D92D20' }}>*</span>
        </label>
        <div style={elementContainerStyle}>
          <CardNumberElement options={elementOptions} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <div style={{ width: '48%' }}>
          <label className={classes.stripeFormLabel}>
            {t('Expiration Date')}
            <span style={{ color: '#D92D20' }}>*</span>
          </label>
          <div style={elementContainerStyle}>
            <CardExpiryElement options={elementOptions} />
          </div>
        </div>
        <div style={{ width: '48%' }}>
          <label className={classes.stripeFormLabel}>
            CVC<span style={{ color: '#D92D20' }}>*</span>
          </label>
          <div style={elementContainerStyle}>
            <CardCvcElement options={elementOptions} />
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '48%' }}>
          <label className={classes.stripeFormLabel} htmlFor="country">
            {t('Country')}
            <span style={{ color: '#D92D20' }}>*</span>
          </label>
          <div style={{ marginTop: '8px' }}>
            <Input name="country" variant="filled" type="country" />{' '}
          </div>
        </div>
        <div style={{ width: '48%' }}>
          <label className={classes.stripeFormLabel} htmlFor="zip">
            {t('Zip code')}
            <span style={{ color: '#D92D20' }}>*</span>
          </label>
          <div style={{ marginTop: '8px' }}>
            <Input name="zipCode" variant="filled" type="number" />{' '}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentBlock;
