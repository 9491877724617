import React from 'react';
import { Control, Controller } from 'react-hook-form';
import clsx from 'clsx';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import { makeStyles } from '@material-ui/core/styles';

import '../../../App.css';
import 'react-phone-input-2/lib/style.css';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .special-label': {
      display: 'none',
    },
    '&:focus': {
      border: '0.5px solid #082CAF !important',
    },
    '& .flag-dropdown': {
      // paddingLeft: '5px !important',
      zIndex: 99,
      border: ({ error }: { error: boolean }) =>
        error ? '1px solid red' : '1px solid #D0D5DD',
      borderRadius: '8px 0 0 8px',
      backgroundColor: '#FFFFFF',
      '& .arrow': {
        display: 'block',
      },
      '&:focus': {
        border: '0.5px solid #082CAF !important',
      },
      '&.open': {
        borderRadius: '8px 0 0 8px',
      },
      '&.open .selected-flag': {
        borderRadius: '8px 0 0 8px',
      },
      '& .selected-flag': {
        borderRadius: '8px 0 0 8px',
      },
    },
    '&  .country-list': {
      display: 'block',
    },
  },
  input: {
    '& .react-tel-input .form-control': {
      backgroundColor: ' #FFFFFF',
      padding: '10px 0px',
      border: '1px solid #D0D5DD',
      borderRadius: '8px',
      '&:focus': {
        border: '0px',
      },
    },
    '& .react-tel-input .form-control:hover': {
      borderColor: 'white',
    },
  },
}));

interface IPhoneNumberInputProps extends PhoneInputProps {
  control: Control<any>;
  name: string;
  label: string;
  style?: any;
  defaultValue: string;
  className: any;
  helperText?: React.ReactElement;
  error?: boolean;
}

export default function PhoneNumberInput({
  control,
  name,
  label,
  style,
  defaultValue,
  className,
  helperText,
  error,
  ...props
}: IPhoneNumberInputProps) {
  const classes = useStyles({ error });
  return (
    <>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ onChange, onBlur, value }) => (
          <PhoneInput
            containerClass={classes.root}
            inputClass={clsx(className, classes.input)}
            inputStyle={{
              fontFamily: 'Inter, sans-serif',
              width: '100%',
              backgroundColor: '#FFFFFF',
              border: error ? '1px solid red' : '1px solid #D0D5DD',
              borderRadius: '8px',
              height: '44px',
              marginTop: '12.04px !important',
              ...style,
            }}
            specialLabel={''}
            // country="tn"
            onBlur={onBlur}
            inputProps={{ name }}
            value={value}
            onChange={onChange}
            data-cy="phone-input"
            {...props}
          />
        )}
      />
      <Box mt={helperText && 0.7}>
        {helperText && (
          <Typography style={{ color: 'red', fontSize: '13px' }}>
            {helperText}
          </Typography>
        )}
      </Box>
    </>
  );
}
