import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from '../styles';
import { Controller } from 'react-hook-form';
import { ReactComponent as RadioCheckedIcon } from 'assets/Schedule/new/radio-checked.svg';
import { ReactComponent as RadioUncheckedIcon } from 'assets/Schedule/new/radio-unchecked.svg';
import Inputs from 'components/ui/Inputs';
import { FormInput } from 'components/ui/Inputs/FormInput';
import DatePicker from 'react-datepicker';
import { IconButton } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import { Select } from 'components/ui/Inputs/CustomDate';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import { useTranslation } from 'react-i18next';
import range from 'lodash.range';
import { convertDateLikeMeet } from 'utils';
import DateRange from 'components/ui/Inputs/DateRange';
import Text from 'Text';

interface IDateRangeFormProps {
  register: any;
  control: any;
  question: string;
  name: string;
  handleInputChange: any;
  questionIndex: number;
  props?: any;
  responses?: {
    startDate: string;
    endDate: string;
  };
}

export const DateRangeForm = ({
  name,
  register,
  control,
  question,
  handleInputChange,
  questionIndex,
  responses,
  ...props
}: IDateRangeFormProps) => {
  const classes = useStyles();
  const [start, setStart] = useState<Date>(
    responses ? new Date(responses?.startDate) : new Date()
  );
  const [end, setEnd] = useState<Date>(
    responses
      ? new Date(responses?.endDate)
      : new Date(new Date().setHours(new Date().getHours() + 1))
  );

  useEffect(() => {
    if (start) {
      if (!responses) {
        setEnd(
          new Date(new Date(start).setHours(new Date(start).getHours() + 1))
        );
        handleInputChange(questionIndex, {
          startDate: start,
          endDate: new Date(
            new Date(start).setHours(new Date(start).getHours() + 1)
          ),
        });
      }
    }
  }, [start, responses]);

  const handleChangeStartDate = (name: 'startDate', value: Date) => {
    setStart(value);
    handleInputChange(questionIndex, { startDate: value, endDate: end });
  };

  const handleChangeEndDate = (name: 'endDate', value: Date) => {
    setEnd(value);
    handleInputChange(questionIndex, { startDate: start, endDate: value });
  };

  return (
    <Box>
      <Typography className={classes.formQuestion}>{question}</Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography className={classes.titleStyle}>Start date</Typography>
          <DateRange
            dateFormat="MMMM d, yyyy"
            range={[start]}
            name="startDate"
            onChange={handleChangeStartDate}
            minDate={new Date()}
            date
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.titleStyle}>End date</Typography>
          <DateRange
            dateFormat="MMMM d, yyyy"
            range={[end]}
            name="endDate"
            onChange={handleChangeEndDate}
            minDate={start}
            date
          />
        </Grid>
      </Grid>
    </Box>
  );
};
