import { Box, Typography } from '@material-ui/core';
import { FormList } from 'pages/PatientActivitiesPage/CreateEditForm/FormList';
import React from 'react';
import { useForm } from 'react-hook-form';

const DropDownResponse = ({ data, updateResponse }: any) => {
  const { question, description, options,responses } = data?.questions[0];
  const [newData, setNewData] = React.useState(data);
  React.useEffect(() => {
    if (data) {
      setNewData(data);
    }
  }, [data]);
  const handleInputChange = (index, value) => {
    setNewData((prevData) => {
      const updatedQuestions = prevData.questions.map((q, i) => {
        return {
          ...q,
          responses: value,
        };
      });

      const updatedData = {
        ...prevData,
        questions: updatedQuestions,
      };

      updateResponse(updatedData);
      return updatedData;
    });
  };
  const { register, control } = useForm();

  return (
    <Box mt={2}>
      <FormList
        question={question}
        options={options}
        handleInputChange={handleInputChange}
        questionIndex={0}
        responses={responses}
      />
    </Box>
  );
};

export default DropDownResponse;
