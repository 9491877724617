import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useStyles } from '../styles';
import { FormInput } from 'pages/AdminPage/ManageTags/TagSelect';

export const FormList = ({
  question,
  options,
  handleInputChange,
  questionIndex,
  responses,
}: any) => {
  const classes = useStyles();
  const [selectedColor, setSelectedColor] = useState();

  const handleChange = (val) => {
    handleInputChange(questionIndex, val.target.value);
  };

  return (
    <Box>
      <Typography className={classes.formQuestion}>{question}</Typography>
      <FormControl style={{ width: '100%' }}>
        <Select
          value={responses ? responses : selectedColor}
          onChange={handleChange}
          input={<FormInput />}
          MenuProps={{
            disableScrollLock: false,
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple',
          }}
        >
          {options.map((value) => (
            <MenuItem key={value} value={value}>
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                style={{
                  gap: '0.5rem',
                }}
              >
                {value}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
