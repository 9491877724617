import React from 'react';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import { ReactComponent as Eye } from 'assets/templateSettings/eye.svg';
import { ReactComponent as DefaultIcon } from 'assets/templateSettings/checkIcon.svg';
import { ReactComponent as TagIcon } from 'assets/templateSettings/tagIcon.svg';
import { ReactComponent as CopyIcon } from 'assets/copyLogo.svg';
import { ReactComponent as ShareIcon } from 'assets/templateSettings/shareIcon.svg';
import { ReactComponent as StopAutoSendIcon } from 'assets/templateSettings/stopIcon.svg';
import TrashIcon from 'assets/TrashIcon';
import { ReactComponent as AssignClient } from 'assets/AssignClientIcon.svg';

interface IProps {
  handleDefaultTemplate: () => void;
  handleViewTemplate: () => void;
  handleEditTemplate: () => void;
  handleManageTags: () => void;
  handleDuplicateTemplate: () => void;
  handleDeleteTemplate: () => void;
  isDefault: boolean;
  isAutoSent: boolean;
  TemplateType: string;
  handleAutoSent: () => void;
  handleAssign: () => void;
}

function MenuElements({
  handleDefaultTemplate,
  handleViewTemplate,
  handleEditTemplate,
  handleManageTags,
  handleDuplicateTemplate,
  handleDeleteTemplate,
  isDefault,
  isAutoSent,
  TemplateType,
  handleAutoSent,
  handleAssign,
}: IProps) {
  const regularMenuElements = [
    {
      avatar: <Eye />,
      name: 'View template',
      onClick: handleViewTemplate,
    },
    {
      avatar: <EditIcon />,
      name: 'Edit template',
      onClick: handleEditTemplate,
    },
    {
      avatar: <AssignClient />,
      name: 'Assign to client',
      onClick: handleAssign,
    },
    {
      avatar: <TagIcon />,
      name: 'Manage tags',
      onClick: handleManageTags,
    },
    {
      avatar: <CopyIcon />,
      name: 'Duplicate',
      onClick: handleDuplicateTemplate,
    },
    {
      avatar: <TrashIcon width={18} height={18} fill="#B42318" />,
      name: 'Delete',
      onClick: handleDeleteTemplate,
      isRed: true,
    },
  ];
  const MenuElementsNotes = isDefault
    ? [
        ...regularMenuElements.slice(0, -1), // Take all elements except the last
        {
          avatar: (
            <TrashIcon
              width={18}
              height={18}
              fill="#B42318"
              style={{ opacity: 0.5 }}
            />
          ),
          name: 'Delete',
          onClick: null,
          isRed: true,
          isOff: true,
        },
      ]
    : [
        {
          avatar: <DefaultIcon />,
          name: 'Set as default',
          onClick: handleDefaultTemplate,
        },
        ...regularMenuElements,
      ];

  const MenuElementsIntake = [
    ...regularMenuElements.slice(0, 3),
    {
      avatar: isAutoSent ? <StopAutoSendIcon /> : <ShareIcon />,
      name: isAutoSent ? 'Remove auto-send' : 'Auto-send',
      onClick: handleAutoSent,
    },
    ...regularMenuElements.slice(3),
  ];

  const getTemplateType = () => {
    switch (TemplateType) {
      case 'note-taking':
        return [...MenuElementsNotes]?.filter(
          (el) => el?.name !== 'Assign to client'
        );
      case 'intake-form':
        return MenuElementsIntake;
      default:
        return regularMenuElements;
    }
  };

  return getTemplateType();
}

export default MenuElements;
