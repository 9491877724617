import MUITab, { TabProps } from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import PatientTabs from 'components/PatientProfile/PatientTabs';

export const SectionTab = withStyles((theme) => ({
  root: {
    '&[aria-selected="true"]': {
      color: '#2DC76D',
      backgroundColor: '#F8FAFB',
      fill: 'solid',
      border: '1px solid #F8FAFB',
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: ' 4px 4px 0px 0px',
      borderBottom: '3px solid #2DC76D',
      marginRight: '10px',
    },
    '&:focus': {
      color: '#2DC76D',
      marginRight: '10px',
    },
    backgroundColor: '#F8FAFB',
    marginRight: '10px',
    color: '#425364',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(19),
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(9),
      lineHeight: theme.typography.pxToRem(10),
    },
  },
}))((props) => <MUITab disableRipple {...props} />);
export const SimpleTab = withStyles((theme) => ({
  root: {
    '&[aria-selected="true"]': {
      color: '#0054B6',
      backgroundColor: 'white',
      fill: 'solid',
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: ' 4px 4px 0px 0px',
      borderBottom: '3px solid #0054B6',
      marginRight: '10px',
    },
    '&:focus': {
      color: '#0054B6',
      marginRight: '10px',
    },
    backgroundColor: 'white',
    marginRight: '10px',
    color: '#425364',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(19),
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(9),
      lineHeight: theme.typography.pxToRem(10),
    },
  },
}))((props) => <MUITab disableRipple {...props} />);

export const OtherTab = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    '&[aria-selected="true"]': {
      color: 'white',
      backgroundColor: '#1F61DC',
      fill: 'solid',
      border: '1px solid #1F61DC',
      fontWeight: 500,
      minWidth: '43%',
      width: '43%',
      minHeight: 40,
      height: 40,
    },
    '&:focus': {
      color: 'white',
      minWidth: '43%',
      width: '43%',
      minHeight: 40,
      height: 40,
    },
    backgroundColor: 'white',
    border: '1px solid #425364',
    color: '#425364',
    fontWeight: 700,
    minWidth: '43%',
    width: '43%',
    minHeight: 40,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(19),
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(9),
      lineHeight: theme.typography.pxToRem(10),
    },
  },
}))((props) => <MUITab disableRipple {...props} />);

export const GreenButtonsTab = withStyles((theme) => ({
  root: {
    border: '1px solid #2F80ED',
    borderRadius: theme.typography.pxToRem(22),
    backgroundColor: 'white',
    '&[aria-selected="true"]': {
      color: 'white',
      backgroundColor: '#2F80ED',
      fill: 'solid',
      fontWeight: 700,
      minHeight: '38px',
      height: '38px',
      border: '1px solid #2F80ED',
      fontSize: '16px',
    },
    minHeight: '38px',
    height: '38px',
    whiteSpace: 'nowrap',
    color: '#344054',
    fontWeight: 500,
    paddingInline: theme.typography.pxToRem(25),
    fontFamily: 'Roboto',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(30),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(15),
    },
  },
}))((props) => <MUITab disableRipple {...props} />);

export const ButtonsTab = withStyles((theme) => ({
  root: {
    border: 'none',
    borderRadius: '20px',
    '&[aria-selected="true"]': {
      color: 'black',
      backgroundColor: '#EFF6FF',
      fill: 'solid',
      fontWeight: 700,
      minWidth: '70px',
      width: '70px',
      minHeight: '25px',
      height: '25px',
    },
    '&:focus': {
      color: 'black',
      minWidth: '70px',
      width: '70px',
      minHeight: '25px',
      height: '25px',
    },
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 700,
    minWidth: '70px',
    width: '70px',
    textAlign: 'center',
    minHeight: '25px',
    height: '25px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
  },
}))((props) => <MUITab disableRipple {...props} />);

export const PatientTab = withStyles((theme) => ({
  root: {
    '&[aria-selected="true"]': {
      color: 'white',
      backgroundColor: 'rgba(86, 127, 189, 1)',
      fill: 'solid',
      border: '1px solid rgba(86, 127, 189, 1)',
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: ' 59px',
      flexGrow: 1,
    },
    '&:focus': {
      color: 'white',
      flexGrow: 1,
    },
    backgroundColor: 'transparent',
    color: 'rgba(31, 97, 220, 1)',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    flexGrow: 1,
    textTransform: 'capitalize',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(19),
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(9),
      lineHeight: theme.typography.pxToRem(10),
    },
  },
}))((props) => <MUITab disableRipple {...props} />);

export const FormTab = withStyles((theme) => ({
  root: {
    '&[aria-selected="true"]': {
      borderTop: '5px solid #0265DC',
      borderRight: '1px solid #0265DC',
      borderLeft: '1px solid #0265DC',
      borderBottom: '1px solid #fff',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      color: '#0265DC',
    },
    '&:focus': {
      color: '#0265DC',
    },
    textTransform: 'none',
    backgroundColor: 'transparent',
    color: '#98A2B3',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(9),
      lineHeight: theme.typography.pxToRem(10),
    },
  },
}))((props) => <MUITab disableRipple {...props} />);

interface ITabProps extends TabProps {
  tabsMode?:
    | 'normal'
    | 'section'
    | 'other'
    | 'greenButtons'
    | 'buttons'
    | 'patient'
    | 'form'
    | 'simple';
}

export default function Tab({
  tabsMode = 'normal',
  ...props
}: ITabProps): ReactElement {
  const tabTypes: any = {
    normal: <MUITab {...props} />,
    section: <SectionTab {...props} />,
    simple: <SimpleTab {...props} />,
    other: <OtherTab {...props} />,
    greenButtons: <GreenButtonsTab {...props} />,
    buttons: <ButtonsTab {...props} />,
    patient: <PatientTab {...props} />,
    form: <FormTab {...props} />,
  };
  return tabTypes[tabsMode];
}
