import React from 'react';
import { Control, Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FilledTextFieldProps } from '@material-ui/core';
import ReactCountryFlag from 'react-country-flag';

import country, { supportedCountriesLabel } from 'utils/countries';
import { FormInput } from './FormInput';

interface ICountryInput extends FilledTextFieldProps {
  control: Control<any>;
  defaultValue: any;
  name: string;
  isStripeSupportedCountry?: boolean;
  isAllSupported?: boolean;
}

export default function CountryInput({
  control,
  defaultValue,
  name,
  isStripeSupportedCountry = false,
  isAllSupported = false,
  ...props
}: ICountryInput) {
  const targetCountries = [
    'United States',
    'France',
    'Canada',
    'Germany',
    'United Kingdom',
    'Tunisia',
    'United Arab Emirates',
    'Saudi Arabia',
    'Lebanon',
    'Switzerland',
    'Algeria',
    'Morocco',
    'Egypt',
    'Italy',
    'Spain',
    'Netherlands',
    'Sweden',
    'Norway',
    'Poland',
    'Belgium',
  ];

  const stripeSupportedCountries = country
    .filter((country) => targetCountries.includes(country.label))
    .sort(
      (a, b) =>
        targetCountries.indexOf(a.label) - targetCountries.indexOf(b.label)
    );

  const supportedCountries = country?.filter((country) =>
    supportedCountriesLabel.includes(country.code)
  );
  return (
    <Controller
      control={control}
      name={name}
      defaultValues={defaultValue}
      render={({ onChange, onBlur }) => {
        return (
          <Autocomplete
            getOptionLabel={(option) => option.label}
            id="county-autocomplete"
            options={
              isStripeSupportedCountry
                ? stripeSupportedCountries
                : isAllSupported
                ? supportedCountries
                : country
            }
            getOptionSelected={(option, value) =>
              option?.label?.toLowerCase() ===
              value?.label?.label?.toLowerCase()
            }
            defaultValue={defaultValue}
            renderOption={(option) => (
              <>
                <ReactCountryFlag
                  countryCode={option.code}
                  svg
                  style={{ paddingRight: '10px' }}
                />
                {option.label} ({option.code})
              </>
            )}
            renderInput={(params) => (
              <FormInput
                variant={'filled'}
                placeholder=""
                name={name}
                {...params}
                ref={params.InputProps.ref}
                style={props.style}
                className={props.className}
              />
            )}
            onChange={(e, value) => onChange(value.label)}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
}
