import React from 'react';
import AutoCompleteAddSymptoms, {
  Option,
} from 'components/ui/Inputs/AutoCompleteAddSymptoms';
import { Box } from '@material-ui/core';
import OptionRender from './OptionRender';
import { useTranslation } from 'react-i18next';

interface IMembersAddProps {
  values: Option[];
  options: Option[];
  setValues: (value: any) => void;
  hasErrors?: boolean;
  disableCustomFilter?: boolean;
  type?: string;
}

export default function Symptoms({
  values,
  options,
  setValues,
  hasErrors,
  disableCustomFilter = false,
  type,
}: IMembersAddProps) {
  const { t } = useTranslation();

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <AutoCompleteAddSymptoms
        groupBy={(option: any) => option.type}
        renderPreviewWrapper={(content: any) => (
          <Box pt={2} display="flex" flexDirection="column">
            {content}
          </Box>
        )}
        type={type}
        options={[...options]}
        renderOption={(option, { selected }) => (
          <OptionRender selected={selected} {...option} />
        )}
        values={values}
        setValues={setValues}
        hasErrors={hasErrors}
        disableCustomFilter={disableCustomFilter}
      />
    </Box>
  );
}
