import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import ErrorIcon from '@material-ui/icons/Error';
import { InfoBlock } from 'pages/AdminPage/TabsContent/InfoBlock';
import Skeleton from 'components/ui/Skeleton';
import { IinputBoxProps } from 'pages/AdminPage/TabsContent/types';
import Input from 'components/ui/Inputs';
import clsx from 'clsx';
import { useStyles } from 'pages/AdminPage/TabsContent/style';
import { yupResolver } from '@hookform/resolvers';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import { useMutation, useQuery } from '@apollo/client';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { BlockNames } from 'pages/AdminPage/TabsContent/PersonalInformation';
import { timeConverter } from 'pages/AdminPage/TimeConverter';
import { EDIT_PATIENT_OWNER } from 'pages/PatientProfile/GeneralInfoPatient';
import { GET_PATIENT_AUTH } from 'contexts/AuthContext';
import { useSnackbar } from 'hooks/useSnackbar';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';

export const legalNameSchema = yup.object().shape({
  firstname: yup.string().required('First Name is a required field'),
  lastname: yup.string().required('Last Name is a required field'),
});

export const emailSchema = yup.object().shape({
  email: yup.string().email().required('Email is a required field'),
});

export const addressSchema = yup.object().shape({
  country: yup.string().required('Country is a required field'),
  city: yup.string().required('City is a required field'),
  zipcode: yup.string().required('Zip code is a required field'),
});

export const extraSchema = yup.object().shape({
  birthday: yup.string().required('Date of Birth is a required field'),
});
export const birthSchema = yup.object().shape({
  birthday: yup.string().required('Date of Birth is a required field'),
});
export const Demographics = () => {
  const { patient } = useAuth();
  const { data, loading } = useQuery(GET_PATIENT, {
    variables: { patientId: patient?._id },
  });
  const {
    description,
    email,
    phone,
    firstname,
    lastname,
    birthday,
    middlename,
    country,
    city,
    suite,
    picture,
    createdAt,
    zipcode,
    completed,
    bloodType,
    state,
    address,
    gender,
    emergencyName,
    emergencyEmail,
    emergencyPhone,
    religion,
    relationshipStatus,
    employmentStatus,
    relationship,
    preferredLanguage,
    timeZone: timezone,
    pronouns,
    raceAndEthnicity,
  } = data?.patient || {};

  const classes = useStyles();
  const { t } = useTranslation();
  const [editableBlock, setEditableBlock] = useState(null);
  const [currentSchema, setCurrentSchema] = useState<any>();
  const { register, control, handleSubmit, reset, errors, watch } = useForm({
    // resolver: yupResolver(currentSchema),
    defaultValues: {
      description,
      timezone,
      email,
      phone,
      firstname,
      lastname,
      // birthday,
      middlename,
      country,
      city,
      suite,
      picture,
      createdAt,
      zipcode,
      completed,
      bloodType,
      state,
      address,
      gender,
      emergencyName,
      emergencyEmail,
      emergencyPhone,
      religion,
      relationshipStatus,
      employmentStatus,
      raceAndEthnicity,
      relationship,
      preferredLanguage,
      pronouns,
    },
  });
  useEffect(() => {
    if (data?.patient) {
      reset({
        description,
        email,
        phone,
        firstname,
        lastname,
        timezone,
        // birthday,
        middlename,
        country,
        city,
        suite,
        picture,
        createdAt,
        zipcode,
        completed,
        bloodType,
        state,
        address,
        gender,
        emergencyName,
        emergencyEmail,
        emergencyPhone,
        religion,
        relationshipStatus,
        employmentStatus,
        raceAndEthnicity,
        relationship,
        preferredLanguage,
        pronouns,
      });
    }
  }, [reset, data?.patient, t]);
  // useEffect(() => {
  //   switch (editableBlock) {
  //     case BlockNames.legal:
  //       setCurrentSchema(legalNameSchema);
  //       break;
  //     case BlockNames.email:
  //       setCurrentSchema(emailSchema);
  //       break;
  //     case BlockNames.address:
  //       setCurrentSchema(addressSchema);
  //       break;
  //     case BlockNames.extra:
  //       setCurrentSchema(extraSchema);
  //       break;
  //     case BlockNames.birth:
  //       setCurrentSchema(birthSchema);
  //       break;
  //     default:
  //       setCurrentSchema(yup.object().shape({}));
  //   }
  // }, [editableBlock]);
  const commonInputProps = {
    inputRef: register,
    variant: 'filled',
    control,
  };
  const InputBox = ({
    labelName,
    type,
    name,
    datacy,
    className,
    defaultValue,
    options,
    ...props
  }: IinputBoxProps) => {
    return (
      <Box>
        <Skeleton loading={loading}>
          <InputLabel className={clsx(className, classes.label)}>
            {labelName}
          </InputLabel>
        </Skeleton>
        <Skeleton loading={loading}>
          <Input
            className={clsx(classes.input, classes.marginTop)}
            type={type}
            name={name}
            data-cy={datacy}
            {...commonInputProps}
            defaultValue={defaultValue}
            options={options}
            helperText={
              (errors as any)[name] && (
                <FormHelperText className={classes.error}>
                  <ErrorIcon className={classes.errorIcon} />
                  {(errors as any)[name].message}
                </FormHelperText>
              )
            }
            {...props}
          />
        </Skeleton>
      </Box>
    );
  };
  const defaultValuesCountry = {
    label: data?.patient?.country,
  };
  const { triggerSnack } = useSnackbar();

  const [editPatient, { loading: loadingMutation }] = useMutation(
    EDIT_PATIENT_OWNER,
    {
      refetchQueries: [{ query: GET_PATIENT_AUTH }],
      awaitRefetchQueries: false,
    }
  );
  const onSubmit = async (formData) => {
    try {
      await editPatient({
        variables: {
          patientId: patient?._id,
          EditPatientInput: formData,
          picture: undefined,
        },
      });
      setEditableBlock(null);
      triggerSnack();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Box mt={'1rem'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={10}>
          <Grid item xl={6} lg={6}>
            <Text i18nKey="demographics" className={classes.titleContainer}>
              Demographics
            </Text>
            <InfoBlock
              title={t('Date of birth')}
              content={
                birthday
                  ? timeConverter(
                      birthday || new Date().getMilliseconds().toString()
                    )
                  : '-'
              }
              blockName={BlockNames.birth}
              isEditable={editableBlock === BlockNames.birth}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              buttonLoading={loadingMutation}
            >
              <>
                <Skeleton loading={loading}>
                  <InputLabel className={classes.label}>
                    <Text className={classes.label} i18nKey="dateOfBirth">
                      {' '}
                      Date of birth
                    </Text>
                  </InputLabel>
                </Skeleton>
                <Skeleton loading={loading}>
                  <Input
                    className={clsx(classes.input, classes.marginTop)}
                    type="date"
                    name="birthday"
                    data-cy="date-input"
                    {...commonInputProps}
                    defaultValue={timeConverter(
                      birthday || new Date().getMilliseconds().toString()
                    )}
                    required
                  />
                </Skeleton>
              </>
            </InfoBlock>
            <InfoBlock
              title={t('Marital status')}
              content={t(relationshipStatus)}
              blockName={BlockNames.marital}
              isEditable={editableBlock === BlockNames.marital}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              buttonLoading={loadingMutation}
            >
              <>
                <Skeleton loading={loading}>
                  <InputLabel
                    style={{
                      marginBottom: '12.04px',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 500,
                      fontStyle: 'normal',
                      fontSize: '1rem',
                      color: '#425364',
                    }}
                  >
                    <Text className={classes.label} i18nKey="maritalStatus">
                      Marital status
                    </Text>
                  </InputLabel>
                </Skeleton>
                <Skeleton loading={loading}>
                  <Input
                    type="select"
                    name="relationshipStatus"
                    data-cy="gender-input"
                    {...commonInputProps}
                    options={[
                      'single',
                      'in a relationship',
                      'married',
                      'domestic partnership',
                      'separated',
                      'divorced',
                      'widowed',
                      'engaged',
                      'open relationship',
                      'prefer not to say',
                      'other',
                    ]}
                    // defaultValue={relationshipStatus}
                  />
                </Skeleton>
              </>
            </InfoBlock>
            <InfoBlock
              title={t('Gender')}
              content={t(gender)}
              blockName={BlockNames.gender}
              isEditable={editableBlock === BlockNames.gender}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              buttonLoading={loadingMutation}
            >
              <>
                <Skeleton loading={loading}>
                  <InputLabel
                    style={{
                      marginBottom: '12.04px',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 500,
                      fontStyle: 'normal',
                      fontSize: '1rem',
                      color: '#425364',
                    }}
                  >
                    Gender
                  </InputLabel>
                </Skeleton>
                <Skeleton loading={loading}>
                  <Input
                    type="select"
                    name="gender"
                    data-cy="gender-input"
                    {...commonInputProps}
                    options={[
                      'male',
                      'female',
                      'non-binary',
                      'prefer not to say',
                      'other',
                    ]}
                    // defaultValue={gender}
                  />
                </Skeleton>
              </>
            </InfoBlock>
            <InfoBlock
              title={t('Pronouns')}
              content={t(pronouns)}
              blockName={BlockNames.pronouns}
              isEditable={editableBlock === BlockNames.pronouns}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              buttonLoading={loadingMutation}

              // isBorderBottom={false}
            >
              <>
                <Skeleton loading={loading}>
                  <InputLabel
                    style={{
                      marginBottom: '12.04px',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 500,
                      fontStyle: 'normal',
                      fontSize: '1rem',
                      color: '#425364',
                    }}
                  >
                    <Text className={classes.label} i18nKey="pronouns">
                      Pronouns
                    </Text>
                  </InputLabel>
                </Skeleton>
                <Skeleton loading={loading}>
                  <Input
                    type="select"
                    name="pronouns"
                    data-cy="gender-input"
                    {...commonInputProps}
                    options={['he/him', 'she/her', 'other']}
                    defaultValue={pronouns}
                  />
                </Skeleton>
              </>
            </InfoBlock>
            <InfoBlock
              title={t('Ethnicity and race')}
              content={t(raceAndEthnicity)}
              blockName={BlockNames.race}
              isEditable={editableBlock === BlockNames.race}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              buttonLoading={loadingMutation}

              // isBorderBottom={false}
            >
              <>
                <Skeleton loading={loading}>
                  <InputLabel
                    style={{
                      marginBottom: '12.04px',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 500,
                      fontStyle: 'normal',
                      fontSize: '1rem',
                      color: '#425364',
                    }}
                  >
                    <Text
                      className={classes.label}
                      i18nKey="Race and ethnicity"
                    >
                      Ethnicity and race
                    </Text>
                  </InputLabel>
                </Skeleton>
                <Skeleton loading={loading}>
                  <Input
                    type="select"
                    name="raceAndEthnicity"
                    data-cy="gender-input"
                    {...commonInputProps}
                    options={[
                      'white',
                      'black or african american',
                      'asian',
                      'native american or alaska native',
                      'native hawaiian or other pacific islander',
                      'middle eastern or north african',
                      'biracial multiracial',
                      'prefer not to say',
                      'other',
                    ]}
                    // defaultValue={raceAndEthnicity}
                  />
                </Skeleton>
              </>
            </InfoBlock>
            <InfoBlock
              title={t('Religion')}
              content={t(religion)}
              blockName={BlockNames.Religion}
              isEditable={editableBlock === BlockNames.Religion}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              buttonLoading={loadingMutation}

              // isBorderBottom={false}
            >
              <>
                <Skeleton loading={loading}>
                  <InputLabel
                    style={{
                      marginBottom: '12.04px',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 500,
                      fontStyle: 'normal',
                      fontSize: '1rem',
                      color: '#425364',
                    }}
                  >
                    <Text className={classes.label} i18nKey="religion">
                      Religion
                    </Text>
                  </InputLabel>
                </Skeleton>
                <Skeleton loading={loading}>
                  <Input
                    type="select"
                    name="religion"
                    data-cy="gender-input"
                    {...commonInputProps}
                    options={[
                      'christian',
                      'muslim',
                      'jewish',
                      'hindu',
                      'buddhist',
                      'sikh',
                      'atheist',
                      'prefer not to say',
                      'other',
                    ]}
                    // defaultValue={religion}
                  />
                </Skeleton>
              </>
            </InfoBlock>
            <InfoBlock
              title={t('Employment status')}
              content={t(employmentStatus)}
              blockName={BlockNames.employment}
              isEditable={editableBlock === BlockNames.employment}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loading}
              isBorderBottom={false}
              buttonLoading={loadingMutation}
            >
              <>
                <Skeleton loading={loading}>
                  <InputLabel
                    style={{
                      marginBottom: '12.04px',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 500,
                      fontStyle: 'normal',
                      fontSize: '1rem',
                      color: '#425364',
                    }}
                  >
                    <Text className={classes.label} i18nKey="employmentStatus">
                      Employment status
                    </Text>
                  </InputLabel>
                </Skeleton>
                <Skeleton loading={loading}>
                  <Input
                    type="select"
                    name="employmentStatus"
                    data-cy="gender-input"
                    {...commonInputProps}
                    options={[
                      'employed full time',
                      'employed part time',
                      'self employed',
                      'unemployed',
                      'retired',
                      'student',
                      'homemaker',
                      'prefer not to say',
                      'other',
                    ]}
                    // defaultValue={employmentStatus}
                  />
                </Skeleton>
              </>
            </InfoBlock>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
