import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
} from '@material-ui/core';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useEditorContext } from '../EditorContextProvider';
import useStyles from './styles';
import { IListOption } from '.';
import { changeFontFamily } from './Utils';

interface GenericProps {
  options: IListOption[];
  selectedIndex: number;
  toggleListVisibilityList: (listName: string) => void;
  onClick?: any;
}
const GenericList: React.FC<GenericProps> = ({
  options,
  selectedIndex,
  toggleListVisibilityList,
}) => {
  const listRef = useRef(null);
  const classes = useStyles({ isSession: false });
  const { addBlock, editorRef, startPosition, endPosition, blockId } =
    useEditorContext();
  const [selectedItem, setSelectedIndex] = useState(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent | null) => {
      if (listRef.current && !listRef.current.contains(event?.target as Node)) {
        toggleListVisibilityList(null);
      }
    },
    [toggleListVisibilityList]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  const handleListItemClick = (event, index, element) => {
    setSelectedIndex(index);
    addBlock(options[index].blockName, options[index].level);
    if (element.fontFamily)
      changeFontFamily(
        startPosition,
        endPosition,
        editorRef,
        element.fontFamily,
        blockId
      );
    toggleListVisibilityList(null);
  };

  return (
    <div ref={listRef} className={classes.genericListBox}>
      <List component="nav" aria-label="main mailbox folders">
        {options.map((element, index) => (
          <ListItem
            style={{
              fontFamily: element.fontFamily,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              paddingInline: '15px',
              gap: '8px',
              paddingBlock: 0,
              paddingBottom: '0.5rem',
            }}
            key={`${element.blockName}-${index}`}
            button
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index, element)}
          >
            {element.icon && (
              <Box className={classes.iconBox}>{element.icon}</Box>
            )}
            <ListItemText
              primaryTypographyProps={{
                style: {
                  fontSize: '16px',
                  fontFamily: element.fontFamily,
                  fontWeight: 400,
                  lineHeight: '20px',
                },
              }}
              primary={element.name}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );
};

export default GenericList;
