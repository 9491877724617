enum Period {
  day = 'DAY',
  week = 'WEEK',
  month = 'MONTH',
  year = 'YEAR',
}
import dayjs from 'dayjs';
import { Payment } from './DoctorFinance';

interface IAppointment {
  isPaid: string;
  sessionType: { rate: string };
  startDate: Date;
  isEnded: boolean;
}

export function filterByDate(
  item: { startDate: Date },
  range: string,
  _startDate?: any
) {
  const now = _startDate ? new Date(_startDate) : new Date();
  const itemDate = new Date(item?.startDate);

  switch (range) {
    case Period.day:
      return itemDate.toDateString() === now.toDateString();

    case Period.week:
      const startOfWeek = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - now.getDay()
      );
      const endOfWeek = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - now.getDay() + 6
      );
      return itemDate >= startOfWeek && itemDate <= endOfWeek;

    case Period.month:
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return itemDate >= startOfMonth && itemDate <= endOfMonth;

    case Period.year:
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const endOfYear = new Date(now.getFullYear(), 11, 31);
      return itemDate >= startOfYear && itemDate <= endOfYear;

    default:
      return itemDate.toDateString() === now.toDateString();
  }
}

export function calculateAmount(
  data: { allAppointments: IAppointment[] },
  status: Payment,
  Time: string,
  startDate?: any
) {
  return data?.allAppointments
    ?.filter(
      (obj) =>
        obj?.isPaid === status &&
        filterByDate(obj, Time, startDate) &&
        obj?.isEnded
    )
    .map((obj) => obj?.sessionType?.rate)
    .filter((rate) => typeof rate === 'string')
    .reduce((sum, rate) => sum + parseInt(rate), 0);
}
export function calculateAmountPaid(
  data: { allAppointments: IAppointment[] },
  Time: string,
  startDate?: any
) {
  return data?.allAppointments
    ?.filter(
      (obj) => obj?.isPaid === 'paid' && filterByDate(obj, Time, startDate)
    )
    .map((obj) => obj?.sessionType?.rate)
    .filter((rate) => typeof rate === 'string')
    .reduce((sum, rate) => sum + parseInt(rate), 0);
}

export function calculatePercentage(
  data: { allAppointments: IAppointment[] },
  status: Payment,
  timeUnit: string
) {
  const currentAmount = calculateAmount(data, status, timeUnit);
  const previousTime = calculatePreviousTime(timeUnit);
  const previousAmount = calculateAmount(data, status, timeUnit, previousTime);

  if (previousAmount === 0) {
    return 0;
  }

  const percentageChange =
    ((currentAmount - previousAmount) / previousAmount) * 100;
  return Math.round(percentageChange);
}

function calculatePreviousTime(timeUnit: string): string {
  const currentDate = new Date();

  switch (timeUnit) {
    case Period.day:
      currentDate.setDate(currentDate.getDate() - 1);
      break;
    case Period.week:
      currentDate.setDate(currentDate.getDate() - 7);
      break;
    case Period.month:
      currentDate.setMonth(currentDate.getMonth() - 1);
      break;
    case Period.year:
      currentDate.setFullYear(currentDate.getFullYear() - 1);
      break;
    default:
      break;
  }

  return currentDate.toISOString();
}
