import React, { ReactElement, useState } from 'react';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tabs from 'components/ui/Tabs';
import TeamSettings from './teamtab/TeamSettings';
import { ReactComponent as StaffSelected } from 'assets/staff/teamSelected.svg';
import { ReactComponent as StaffNotSelected } from 'assets/staff/teamNotSelected.svg';
import { ReactComponent as PermissionSelected } from 'assets/staff/permissionSelected.svg';
import { ReactComponent as PermissionNotSelected } from 'assets/staff/permissionNotSelected.svg';
import { useStyles } from './styles';
import TabText from './TabText';
import { gql } from '@apollo/client';
import PermissionSettings from './permissionstab/PermissionSettings';

const Staff = (): ReactElement => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  return (
    <Grid container>
      <Tabs
        tabsMode="form"
        tabClassHeader={classes.tabForm}
        tabsBar={[
          <TabText
            text="My team"
            isSelected={tab === 0}
            selectedIcon={<StaffSelected />}
            notSelectedIcon={<StaffNotSelected />}
          />,
          <TabText
            text="Roles and permission"
            isSelected={tab === 1}
            selectedIcon={<PermissionSelected />}
            notSelectedIcon={<PermissionNotSelected />}
          />,
        ]}
        tabsContent={[
          <Box mt={4}>
            <TeamSettings />
          </Box>,
          <Box mt={4}>
            <PermissionSettings />
          </Box>,
        ]}
        customValue={tab}
        setCustomValue={setTab}
      />
    </Grid>
  );
};

export default Staff;

export const INVITED_STAFF = gql`
  query invitedStaffByDoctor {
    invitedStaffByDoctor {
      _id
      email
      firstname
      middlename
      lastname
    }
  }
`;
