import React from 'react';
import { Box, Avatar, Typography } from '@material-ui/core';
import AutoCompleteAddUsers, { Option } from './AutoCompleteAddUsers';

interface IAvatarSymptomsProps {
  values: Option[];
  options: Option[];
  setValues: (value: Option[]) => void;
  hasErrors?: boolean;
  multiple?: boolean;
  placeholder?: string;
}

export default function SelectUser({
  values,
  options,
  setValues,
  hasErrors,
  multiple = false,
  placeholder,
}: IAvatarSymptomsProps) {
  return (
    <Box display="flex" width="100%" flexDirection="column">
      <AutoCompleteAddUsers
        renderPreviewWrapper={(content: React.ReactNode) => (
          <Box pt={2} display="flex" flexDirection="column">
            {content}
          </Box>
        )}
        options={options}
        values={values}
        setValues={setValues}
        hasErrors={hasErrors}
        multiple={multiple}
        placeholder={placeholder}
      />
    </Box>
  );
}
