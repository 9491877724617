import { Box } from '@material-ui/core';
import { CustomAccordion } from 'pages/DoctorFinancePage/invoice/CustomAccordion';
import React from 'react';
import InsuranceCard from './InsuranceAccoridionContent/InsuranceCard';
import PolicyHolder from './InsuranceAccoridionContent/PolicyHolder';
import InsuranceInfo from './InsuranceAccoridionContent/InsuranceInfo';
import { useFormContext } from 'react-hook-form';

const InsuranceModal = ({
  country,
  frontPicture,
  backPicture,
}: {
  country?: string;
  frontPicture?: string;
  backPicture?: string;
}) => {
  const { register, control, errors, watch, setValue } = useFormContext();
  const commonInputProps = {
    register,
    control,
    errors,
    watch,
    setValue,
  };

  return (
    <Box px={4}>
      <CustomAccordion
        title={'Insurance card'}
        AccordionSummary={<></>}
        AccordionContent={
          <InsuranceCard
            {...commonInputProps}
            frontPicture={frontPicture}
            backPicture={backPicture}
          />
        }
      />
      <CustomAccordion
        title={'Policy holder'}
        AccordionSummary={<></>}
        AccordionContent={
          <PolicyHolder {...commonInputProps} country={country} />
        }
      />
      <CustomAccordion
        title={'Insurance info'}
        AccordionSummary={<></>}
        AccordionContent={<InsuranceInfo {...commonInputProps} />}
      />
    </Box>
  );
};

export default InsuranceModal;
