import React from 'react';
import DatePicker from 'react-datepicker';
import { FormInput } from './FormInput';
import { InputAdornment, makeStyles, Box } from '@material-ui/core';
import { ReactComponent as DateIcon } from 'assets/patientActivities/inputs/date.svg';

interface IProps {
  range: {
    startDate: Date | null;
    endDate: Date | null;
  };
  onChange: (date: any) => void;
}
const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: '100%',
    '& div.react-datepicker-wrapper': {
      width: '100%',
      zIndex: 1000000000000000,
    },
    '& .react-datepicker-popper': {
      zIndex: 50000000000000,
    },
    '& .react-datepicker__day--selected': {
      backgroundColor: '#147AF3',
      color: 'white !important',
      borderRadius: '30px',
    },
    '& .react-datepicker__day-name': {
      color: '#425364',
      fontWeight: 500,
    },
    '& .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selected)':
      {
        backgroundColor: '#F0F9FF',
        color: '#425364 !important',
        // borderRadius: '30px',
      },
    '& .react-datepicker__day--in-range:not(.react-datepicker__day--selected)':
      {
        backgroundColor: '#F0F9FF',
        color: '#425364 !important',
        // borderRadius: '30px',
      },
    '& .react-datepicker__header': {
      backgroundColor: '#ffffff',
      borderBottom: '0px',
    },
    '& .react-datepicker__day': {
      color: '#425364',
      fontWeight: 500,
    },
    '& .react-datepicker__day--outside-month': {
      color: '#949494',
      fontWeight: 500,
    },
    '& .react-datepicker__day[aria-disabled="true"]': {
      color: '#949494',
      fontWeight: 500,
    },
    '& .react-datepicker__triangle': {
      borderBottom: '1px solid #ffffff',
    },
    '& .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before':
      {
        borderBottom: '1px solid #fbfbfb',
        display: 'none',
      },
    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: 'white !important',
    },
  },
}));

const DateRangeInput = ({ range, onChange }: IProps) => {
  const classes = useStyles();
  const formatDate = (date) => {
    return date ? date.toLocaleDateString() : '';
  };
  const [open, setOpen] = React.useState(false);
  return (
    <Box className={classes.datePicker}>
      <DatePicker
        selected={range.startDate}
        onChange={(date: any) => {
          onChange(date);
          const [start, end] = date;
          if (start && end) {
            setOpen(false);
          }
        }}
        monthsShown={2}
        popperPlacement="bottom-end"
        startDate={range.startDate}
        endDate={range.endDate}
        selectsRange
        open={open}
        onClickOutside={() => setOpen(false)}
        onInputClick={() => setOpen(true)}
        value={
          range?.startDate &&
          range?.endDate &&
          `${formatDate(range.startDate)} - ${formatDate(range.endDate)}`
        }
        customInput={
          <FormInput
            fullWidth
            type="text"
            data-cy="date-input"
            variant="filled"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <DateIcon onClick={() => setOpen(true)} />
                </InputAdornment>
              ),
            }}
            style={{ width: '280px' }}
          />
        }
      />
    </Box>
  );
};

export default DateRangeInput;
