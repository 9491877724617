import { Box, Typography } from '@material-ui/core';
import { FormAddress } from 'pages/PatientActivitiesPage/CreateEditForm/FormAddress';
import React from 'react';
import { useForm } from 'react-hook-form';

const Adress = ({ data, updateResponse }: any) => {
  const { question, description, options, responses } = data?.questions[0];
  const [newData, setNewData] = React.useState(data);
  React.useEffect(() => {
    if (data) {
      setNewData(data);
    }
  }, [data]);
  const handleAddress = (index, value, field) => {
    setNewData((prevData) => {
      const updatedQuestions = prevData.questions.map((q, i) => {
        if (i !== index) return q;
        const currentResponse = q.responses || {};
        return {
          ...q,
          responses: {
            ...currentResponse,
            [field]: value,
          },
        };
      });

      const updatedData = {
        ...prevData,
        questions: updatedQuestions,
      };

      updateResponse(updatedData);
      return updatedData;
    });
  };
  const { register, control, errors } = useForm();

  return (
    <Box mt={2}>
      <FormAddress
        name={'adress'}
        register={register}
        control={control}
        errors={errors}
        question={question}
        handleAddress={handleAddress}
        questionIndex={0}
        response={responses}
      />
    </Box>
  );
};

export default Adress;
