import React, { ReactNode } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { ReactComponent as AssignIcon } from 'assets/patientActivities/assignVector.svg';
import { useStyles } from '../styles';
import EditButtons from 'components/EditButtons';
import Text from 'Text';

interface IAssignModalProps {
  handleClose: () => void;
  handleSubmit: () => void;
  text: ReactNode;
}

function AssignModal({
  handleClose,
  handleSubmit,
  text,
}: IAssignModalProps): React.ReactElement {
  const classes = useStyles({ selected: false });
  return (
    <Box className={classes.AssignModal}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        style={{
          padding: '1rem',
          boxSizing: 'border-box',
          gap: '20px',
        }}
      >
        <AssignIcon />
        <Typography className={classes.assignText}>{text}</Typography>
      </Box>
      <Box
        height="76px"
        display="flex"
        alignItems="center"
        width="100%"
        borderTop="0.5px solid rgba(161, 176, 196, 1)"
        style={{
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        }}
      >
        <Box className={classes.buttonsContainer}>
          <Button className={classes.cancelButton} onClick={handleClose}>
            <Text i18nKey="cancel">Cancel</Text>
          </Button>
          <Button className={classes.assignButton} onClick={handleSubmit}>
            <Text i18nKey="assign">Assign</Text>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AssignModal;
