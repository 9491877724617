import { useQuery, gql } from '@apollo/client';

interface IUseAppointmentArgs {
  startDate?: string | Date | number | undefined;
  endDate?: string | Date | number | undefined;
  page?: string | number;
  limit?: string | number;
  filter?: string;
  patient?: string;
  searchValue?: string;
  start?: string | Date;
  end?: string | Date;
}

export default function useGetFinances({
  startDate,
  endDate,
  page,
  limit,
  filter,
  patient,
  searchValue,
  start,
  end,
}: IUseAppointmentArgs) {
  let queryObject = {};
  if (startDate) {
    queryObject = {
      startDate,
      endDate,
      filter,
      patient,
    };
  } else {
    queryObject = {
      limit,
      page,
      filter,
      patient,
      searchValue,
      start,
      end,
    };
  }
  const { data, error, loading, refetch } = useQuery(GET_FINANCES, {
    variables: queryObject,
    // fetchPolicy: 'network-only',
  });
  return { data, error, loading, refetch };
}

export const GET_FINANCES = gql`
  query finances(
    $limit: String
    $page: String
    $filter: String
    $patient: ID
    $searchValue: String
    $start: Date
    $end: Date
  ) {
    finances(
      page: $page
      limit: $limit
      filter: $filter
      patient: $patient
      searchValue: $searchValue
      start: $start
      end: $end
    ) {
      result {
        _id
        description
        title
        appointmentUri
        paymentIntentId
        invitation {
          invitation_id
        }
        doctor {
          _id
          firstname
          middlename
          email
          lastname
          picture
          timeZone
        }
        patient {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
          timeZone
        }
        startDate
        endDate
        location
        locationLink
        conditionType {
          tag
          color
        }
        members
        isPaid
        isPrepay
        sessionType {
          session
          rate
          duration
          currency
        }
        invoice {
          _id
          invoiceRef
          doctorInfo {
            firstname
            lastname
            phone
            address
            suite
            city
            zipcode
            country
            state
          }
          patientInfo {
            firstname
            lastname
            phone
            address
            suite
            city
            zipcode
            country
            state
          }
          amount
          amountPaid
          issueDate
          dueDate
          invoicePdf
        }
        superbill {
          _id
          ref
          superBillPdf
          insuranceRelated {
            firstname
            middlename
            lastname
            zipCode
            city
            address
            state
            relationShipType
          }
          patient {
            firstname
            middlename
            lastname
            city
            address
            state
            zipcode
          }
          editableBill {
            firstname
            middlename
            lastname
            city
            address
            state
            zipcode
          }
        }
      }
      count
    }
  }
`;
