import React from 'react';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as AddIcon } from 'assets/plus-square.svg';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import Input from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import { IRole } from './PermissionSettings';
import { ReactComponent as SearchIcon } from 'assets/searchIcon.svg';

interface IEditProps {
  open: boolean;
  onClose: () => void;
  role: IRole;
}

function AssignService({ open, onClose, role }: IEditProps) {
  const classes = useStyles();

  return (
    <BasicModal open={open} onClose={onClose} handleClose={onClose} isNew>
      <Box className={classes.PasswordModal}>
        <AddIcon />
        <Typography className={classes.modalTitle}>Assign a service</Typography>
        <Typography className={classes.modalText}>
          Select the service you want to make available for the [role name] role{' '}
          <span style={{ fontWeight: '700' }}>{role?.role}</span>
        </Typography>
        <Box width="100%" my="1.5rem">
          <Input
            type="text"
            placeholder={`Search services`}
            name="service"
            variant="filled"
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ gap: '1rem' }}
        >
          <Button onClick={onClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button type="submit" className={classes.saveButton}>
            Add
          </Button>
        </Box>
      </Box>
    </BasicModal>
  );
}

export default AssignService;
