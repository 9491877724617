import React from 'react';
import ReactDOM from 'react-dom';
import ShortResponse from '../../Responses/ShortResponse';
import { FormRadio } from 'pages/PatientActivitiesPage/CreateEditForm/FormRadio';

interface TimelineData {
  events?: Array<any>;
  questions: Array<any>;
  question?: any;
}

interface TimelineConfig {}

interface TimelineConstructorProps {
  data: any;
  config?: TimelineConfig;
  api: any;
  readOnly: boolean;
}

export default class ShortChoice {
  private api: any;
  private readOnly: boolean;
  private data: any;
  private CSS: { wrapper: string };
  private nodes: { holder: HTMLElement | null };

  static get toolbox() {
    return {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 12L10.5 15L16.5 9M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      title: 'Multiple choice',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }: TimelineConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = data;
    this.CSS = {
      wrapper: 'walkthrough-timeline',
    };

    this.nodes = {
      holder: null,
    };
    this.updateResponse = this.updateResponse.bind(this);
  }

  private updateDom() {
    if (this.nodes.holder) {
      ReactDOM.render(
        <ShortResponse data={this.data} updateResponse={this.updateResponse} />,
        this.nodes.holder
      );
    }
  }

  private deleteBlock = (index: number) => {
    this.api.blocks.delete(index);
  };
  private getBlockIndex = () => {
    return this.api.blocks.getCurrentBlockIndex();
  };
  private updateResponse(response) {
    this.data = {
      ...response,
    };
    this.updateDom();
  }
  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    ReactDOM.render(
      <ShortResponse data={this.data} updateResponse={this.updateResponse} />,
      rootNode
    );

    return this.nodes.holder;
  }

  save(): TimelineData {
    return this.data;
  }
}
