import React, { ReactElement, useEffect } from 'react';
import { gql } from '@apollo/client';
import { Grid, Box, makeStyles } from '@material-ui/core';
import Password from 'pages/AdminPage/TabsContent/Password';
import Payment from 'pages/AdminPage/TabsContent/BillingInformation';
import EmailNotifications from 'pages/AdminPage/TabsContent/EmailNotifications';
import { ContactInfo } from './ContactInfo';
import { Demographics } from './Demographics';
import { RegionalTat } from './RegionalTat';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';
import PracticeInformation from 'pages/AdminPage/TabsContent/PracticeInformation';
import Sidebar from 'pages/AdminPage/SideBar';
import Insurance from './Insurance';

enum REDIRECT {
  notification = 'Notification',
}
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    borderRadius: '16px',
    minHeight: 'calc(100vh - 220px)',
  },
}));

const PatientSettings = (): ReactElement => {
  const { t } = useTranslation();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: t('Settings') });
  }, [headerTitleDispatcher]);
  const classes = useStyles();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const redirectStatus = queryParams.get('query');
  const tabSelected = queryParams.get('tab');
  const [customValue, setCustomValue] = React.useState(
    tabSelected ? Number(tabSelected) : 0
  );
  React.useEffect(() => {
    if (tabSelected && Number(tabSelected) !== customValue) {
      setCustomValue(Number(tabSelected));
    }
  }, [tabSelected]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (customValue !== null && customValue !== undefined) {
        history.push(`/patient/settings?tab=${customValue}`);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [customValue]);
  React.useEffect(() => {
    if (redirectStatus === REDIRECT.notification) {
      setCustomValue(3);
    }
  }, [redirectStatus]);
  const menuData = [
    {
      title: 'PERSONAL INFO',
      items: [
        {
          title: 'Contact information',
          content: <ContactInfo />,
          disabled: false,
          index: 0,
        },
        {
          title: 'Demographics',
          content: <Demographics />,
          disabled: false,
          index: 7,
        },
        {
          title: 'Signature',
          content: <PracticeInformation isPatient isPracticeSetting={false} />,
          disabled: false,
          index: 6,
        },
        {
          title: 'Insurance',
          content: <Insurance />,
          disabled: false,
          index: 8,
        },
      ],
    },
    {
      title: 'YOUR ACCOUNT',
      items: [
        {
          title: 'Login and security',
          content: <Password isPatient />,
          disabled: false,
          index: 2,
        },
        {
          title: 'Regional settings',
          content: <RegionalTat />,
          disabled: false,
          index: 1,
        },
        {
          title: 'Notifications',
          content: <EmailNotifications isPatient />,
          disabled: false,
          index: 3,
        },
      ],
    },
    {
      title: 'Payment',
      items: [
        {
          title: 'Payment settings',
          content: <Payment isPatient />,
          disabled: false,
          index: 4,
        },
      ],
    },
  ];
  return (
    <Box
      style={{
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Sidebar
        menuData={menuData}
        setCustomValue={setCustomValue}
        customValue={customValue}
        loadingSubscription={false}
      />
    </Box>
  );
};
export default PatientSettings;

export const GET_CURRENT_USER = gql`
  query user($userId: ID!) {
    user(userId: $userId) {
      language
    }
  }
`;
