import React from 'react';
import ReactDOM from 'react-dom';
import MultiChoice from '../components/MultiChoice';
import FormGenerator from 'pages/FormsPage/FormGenerator';

interface TimelineData {
  events?: Array<any>;
  questions: Array<any>;
  question?: any;
}

interface TimelineConfig {
  edit?: boolean;
}

interface TimelineConstructorProps {
  data: TimelineData;
  config?: TimelineConfig;
  api: any;
  readOnly: boolean;
  edit: boolean;
}

export default class Phone {
  private api: any;
  private readOnly: boolean;
  private data: TimelineData;
  private CSS: { wrapper: string };
  private nodes: { holder: HTMLElement | null };
  private edit: boolean;

  static get toolbox() {
    return {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M7.38028 7.85335C8.07627 9.30297 9.02506 10.6616 10.2266 11.8632C11.4282 13.0648 12.7869 14.0136 14.2365 14.7096C14.3612 14.7694 14.4235 14.7994 14.5024 14.8224C14.7828 14.9041 15.127 14.8454 15.3644 14.6754C15.4313 14.6275 15.4884 14.5704 15.6027 14.4561C15.9523 14.1064 16.1271 13.9316 16.3029 13.8174C16.9658 13.3864 17.8204 13.3864 18.4833 13.8174C18.6591 13.9316 18.8339 14.1064 19.1835 14.4561L19.3783 14.6509C19.9098 15.1824 20.1755 15.4481 20.3198 15.7335C20.6069 16.301 20.6069 16.9713 20.3198 17.5389C20.1755 17.8242 19.9098 18.09 19.3783 18.6214L19.2207 18.779C18.6911 19.3087 18.4263 19.5735 18.0662 19.7757C17.6667 20.0001 17.0462 20.1615 16.588 20.1601C16.1751 20.1589 15.8928 20.0788 15.3284 19.9186C12.295 19.0576 9.43264 17.4332 7.04466 15.0452C4.65668 12.6572 3.03221 9.79483 2.17124 6.76144C2.01103 6.19699 1.93092 5.91477 1.9297 5.50182C1.92833 5.0436 2.08969 4.42311 2.31411 4.0236C2.51636 3.66357 2.78117 3.39876 3.3108 2.86913L3.46843 2.7115C3.99987 2.18006 4.2656 1.91433 4.55098 1.76999C5.11854 1.48292 5.7888 1.48292 6.35636 1.76999C6.64174 1.91433 6.90747 2.18006 7.43891 2.7115L7.63378 2.90637C7.98338 3.25597 8.15819 3.43078 8.27247 3.60655C8.70347 4.26945 8.70347 5.12403 8.27247 5.78692C8.15819 5.96269 7.98338 6.1375 7.63378 6.4871C7.51947 6.60142 7.46231 6.65857 7.41447 6.72538C7.24446 6.96281 7.18576 7.30707 7.26748 7.58743C7.29048 7.66632 7.32041 7.72866 7.38028 7.85335Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      title: 'Phone',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }: TimelineConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    this.edit = config.edit;

    const defaultQuestion = {
      question: 'Phone number',
      type: 'Phone',
      options: ['Option 1', 'Option 2'],
      description: '',
      required: false,
      isDescription: false,
      range: [1, 3],
    };

    this.data = {
      questions: data.questions || [defaultQuestion],
    };

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    };

    this.nodes = {
      holder: null,
    };
  }
  // private setQuestions = (newQuestions: Array<any>) => {
  //   console.log(newQuestions, 'newQuestions');
  //   this.data = {
  //     ...this.data,
  //     questions: newQuestions,
  //   };
  // };

  private setQuestions = (
    newQuestionsOrUpdater: Array<any> | ((questions: Array<any>) => Array<any>)
  ) => {
    if (typeof newQuestionsOrUpdater === 'function') {
      // Handle functional update
      const updatedQuestions = (
        newQuestionsOrUpdater as (questions: Array<any>) => Array<any>
      )(this.data.questions);
      this.data = {
        ...this.data,
        questions: updatedQuestions,
      };
    } else {
      // Handle direct array update
      this.data = {
        ...this.data,
        questions: newQuestionsOrUpdater,
      };
    }
    this.updateDom();
  };
  private deleteBlock = (index: number) => {
    this.api.blocks.delete(index);
  };
  private getBlockIndex = () => {
    return this.api.blocks.getCurrentBlockIndex();
  };
  private updateDom() {
    if (this.nodes.holder) {
      ReactDOM.render(
        <FormGenerator
          questions={this.data.questions}
          setQuestions={this.setQuestions}
          question={this.data.question}
          isNotes={false}
          api={this.api} // Pass the Editor.js API
          deleteBlock={this.deleteBlock}
          getBlockIndex={this.getBlockIndex}
          edit={this.edit}
        />,
        this.nodes.holder
      );
    }
  }
  // private setQuestionssss = (
  //   updateFn: (questions: Array<any>) => Array<any>
  // ) => {
  //   // Check if the input is a function
  //   if (typeof updateFn === 'function') {
  //     // Call the function with the current questions to get the updated questions
  //     const updatedQuestions = updateFn(this.data.questions);
  //     console.log(updatedQuestions, 'updatedQuestions');
  //     // Update the data with the new questions array
  //     this.data = {
  //       ...this.data,
  //       questions: updatedQuestions,
  //     };

  //     console.log(updatedQuestions, 'updated questions');
  //   } else {
  //     console.error('Expected a function that returns an array of questions');
  //   }
  // };

  private changeQuestion = (addedQuestion: string) => {
    this.data = {
      ...this.data,
      question: { ...this.data.question, question: addedQuestion },
    };
  };

  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    // const onDataChange = (newData: TimelineData) => {
    //   this.data = {
    //     ...newData,
    //   };
    // };

    ReactDOM.render(
      // <MultiChoice
      //   onDataChange={onDataChange}
      //   readOnly={this.readOnly}
      //   data={this.data}
      // />,
      <FormGenerator
        questions={this.data.questions}
        api={this.api} // Pass the Editor.js API
        setQuestions={this.setQuestions}
        changeQuestion={this.changeQuestion}
        question={this.data.question}
        isNotes={false} // You can modify this based on your needs
        deleteBlock={this.deleteBlock}
        getBlockIndex={this.getBlockIndex}
        edit={this.edit}
      />,
      rootNode
    );

    return this.nodes.holder;
  }

  save(): TimelineData {
    return this.data;
  }
}
