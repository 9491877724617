import React, { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const TabText = ({
  text,
  isSelected,
  selectedIcon,
  notSelectedIcon,
}: {
  text: string;
  isSelected: boolean;
  selectedIcon: ReactElement;
  notSelectedIcon: ReactElement;
}): ReactElement => {
  const classes = useStyles();

  return (
    <Box className={classes.tab}>
      {isSelected ? selectedIcon : notSelectedIcon}
      <Typography
        className={classes.tabText}
        style={{
          fontSize: isSelected ? '18px' : '16px',
          color: isSelected ? '#0265DC' : '#98A2B3',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default TabText;
