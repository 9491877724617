import { log } from "config";


export const addstyle = async (startPosition, endPosition, editorRef, tag, blockId) => {
  let open = '';
  open = `<${tag}>`;
  const close = `</${tag}>`;
  changeStyle(startPosition,endPosition, editorRef,tag, open, close, blockId);
};
export const changeFont = (startPosition,  endPosition, editorRef, fontSize,blockId) => {
  const word="font"
  const open=`<font style="font-size: ${fontSize};">`
  const close='</font>'
  changeStyle( startPosition,  endPosition, editorRef, word, open, close, blockId);
};
export const changeFontFamily = ( startPosition,  endPosition, editorRef, fontFamily, blockId) => {
  const word="font"
  const open=`<font style="font-family: ${fontFamily};">`
  const close='</font>'
  changeStyle( startPosition,  endPosition, editorRef, word, open, close, blockId);
}
export enum pluginType {
  texColor='texColor',
  highlightColor='highlightColor',
  textColorPicker = 'textColorPicker',
  highlightColorPicker =  'highlightColorPicker',
  insertOptions = 'insertOptions',
  fontFamilies='fontFamilies'
}

export  const fontFamilies = [
    { name: 'Roboto', fontFamily: 'Roboto, sans-serif' },
    { name: 'Open Sans', fontFamily: 'Open Sans, sans-serif' },
    { name: 'Lato', fontFamily: 'Lato, sans-serif' },
    { name: 'Montserrat', fontFamily: 'Montserrat, sans-serif' },
    { name: 'Noto Sans', fontFamily: 'Noto Sans, sans-serif' },
    { name: 'Raleway', fontFamily: 'Raleway, sans-serif' },
    { name: 'Source Sans', fontFamily: 'Source Sans Pro, sans-serif' },
    { name: 'PT Sans', fontFamily: 'PT Sans, sans-serif' },
    { name: 'Merriweather', fontFamily: 'Merriweather, serif' },
    { name: 'Oswald', fontFamily: 'Oswald, sans-serif' },
    { name: 'Poppins', fontFamily: 'Poppins, sans-serif' },
    { name: 'Nunito', fontFamily: 'Nunito, sans-serif' },
    { name: 'Quicksand', fontFamily: 'Quicksand, sans-serif' },
  ];

export const highlightColors = [
        '#F2F4F7',
        '#D0D5DD',
        '#98A2B3',
        '#CAE8FF',
        '#96CEFD',
        '#3892F3',
        '#FECDCA',
        '#F97066',
        '#FDB022',
        '#FDE272',
        '#A6EF67',
        '#CEEAB0',
        '#C3B5FD',
        '#EEAAFD',
        '#F670C7',
        '#FFFFFF',
      ]
export const texColors = [
        '#182230',
        '#475467',
        '#98A2B3',
        '#0265DC',
        '#3892F3',
        '#78BBFA',
        '#B42318',
        '#F04438',
        '#FDB022',
        '#FDE272',
        '#66C61C',
        '#4F7A21',
        '#875BF7',
        '#D444F1',
        '#F670C7',
        '#FFFFFF',
      ]


export const handleChangeColor = ( startPosition, endPosition, editorRef, color, blockId, type) => {
  const word="font"
  const open = type === 'texColor' ?`<font style="color: ${color};">` : `<font style="background-color: ${color};">` 
  const close='</font>'
  changeStyle(  startPosition,  endPosition, editorRef, word, open, close, blockId);
};

const cleanHTMLTags = (text) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  const emptyTags = doc.querySelectorAll(':empty:not(br)');
  emptyTags.forEach((tag) => tag.parentNode.removeChild(tag));
  const cleanedText = doc.body.innerHTML;
  return cleanedText;
};


const changeStyle = async ( startPosition,  endPosition, editorRef, word, open, close, blockIndex ) => {
  let left = '';
  let midle = '';
  let right = '';
  let leftResult;
  let midleResult;
  let rightResult;
  // Start and end positions for text modification
  let a = startPosition;
  let b = endPosition;
  
  // Ensure a <= b because when i select a text from right to left they switch 
  if (a > b) {
    a = startPosition;
    b = startPosition;
  }
  try {
    const content = await editorRef.current.save();
    const blockId = editorRef.current.blocks.getCurrentBlockIndex();
    const currentBlock =  blockId != -1 && content.blocks[blockId];
    const foundBlock = content.blocks.find(block => block.id === blockIndex) || currentBlock;
    if (foundBlock) {
      let currentText = foundBlock.data.text;
      const textArray = currentText.split('');
      let skipMode = false;

      for (let i = 0; i < textArray.length && i < b; i++) {
          if (i === b) {
              break;
          }
          
          if (textArray[i] === '<') {
              skipMode = true;
          }
          
          if (skipMode && i <= a) {
              a++;
              b++;
          }
          
          if (skipMode && i > a) {
              b++;
          }
          
          if (textArray[i] === '>') {
              skipMode = false;
          }
      }

      // Extract left, middle, and right portions of the text
      left = currentText.substring(0, a)
      midle = currentText.substring(a, b)
      right = currentText.substring(b)
       if (word === 'bold') {
        midle = midle.replace(/(\s|&nbsp;)+$/, ''); // Removes both spaces and &nbsp;
      }
      // Perform checks and modifications based on the left, middle, and right portions
      leftResult = checkLeft(left, word)
      midleResult = countAndSubtractTags(midle, word)

      rightResult = checkright(right, word)
      // Construct modifiedText based on the checks and results
      if (leftResult.check && rightResult.check && word !== "font") {

        const modifiedText = [
          leftResult.text,
          midleResult.text,
          rightResult.text
        ].join('');
        foundBlock.data.text = cleanHTMLTags(modifiedText);
      } else if (leftResult.check && !rightResult.check && word !== "font") {
        const modifiedText = [
          leftResult.text,
          midleResult.text,
          rightResult.storedOpenTags,
          rightResult.text
        ].join('');
        foundBlock.data.text = cleanHTMLTags(modifiedText);
      } else if (!leftResult.check && rightResult.check && word !== "font") {

        const modifiedText = [
          leftResult.text,
          rightResult.CloseTag,
          midleResult.text,
          rightResult.text
        ].join('');
        foundBlock.data.text = cleanHTMLTags(modifiedText);
      } else if (!leftResult.check && !rightResult.check && leftResult.CloseTag && rightResult.storedOpenTags && word !== "font") {
        if (word === "font") {
          midleResult.text = open + midleResult.text + close;
        }
        const modifiedText = [
          leftResult.text,
          leftResult.CloseTag,
          midleResult.text,
          rightResult.storedOpenTags,
          rightResult.text
        ].join('');
        foundBlock.data.text = cleanHTMLTags(modifiedText);
      } else {
        const modifiedText = [
          currentText.substring(0, a),
          midleResult.storedCloseTags,
          open,
          midleResult.text,
          close,
          midleResult.storedOpenTags,
          currentText.substring(b)
        ].join('');
        foundBlock.data.text = cleanHTMLTags(modifiedText);
      }
      editorRef.current.blocks.update(blockIndex, {
        text: foundBlock.data.text, 
      });
    }
  } catch (error) {
    console.error("Error updating block:", error);
  }
  
}




function checkLeft(text, word) {
  let storedOpenTags = '';
  let CloseTag = '';
  let check = false;

  // Check if the input text is not empty
  if (text !== '') {
    let startIndex = text.length - 1;
    let endIndex = text.length;

    // Iterate backwards through the text to find the last occurrence of the opening tag of the word
    while ((startIndex = text.lastIndexOf('<' + word[0], startIndex)) !== -1) {
      // Find the corresponding closing tag for the word
      endIndex = text.indexOf(word[word.length - 1] + '>', startIndex);

      // If no closing tag is found, break the loop
      if (endIndex === -1) {
        break;
      }

      // Extract the tag from startIndex to endIndex
      const tag = text.substring(startIndex, endIndex + 1);

      // Check if the tag is a closing tag for the word
      if (tag.startsWith('</' + word)) {
        // If it's a closing tag, break the loop
        break;
      } else if (tag.startsWith('<' + word)) {
        // If it's an opening tag, store it as the storedOpenTags
        storedOpenTags = text.substring(startIndex, endIndex + 1);

        // Check if the opening tag is at the end of the text
        if (endIndex + 1 === text.length) {
          // If yes, remove the tag from the text and set check to true
          text = text.substring(0, startIndex);
          check = true;
          break;
        } else {
          // If not, set the CloseTag and break the loop
          CloseTag = '</' + word + '>';
          break;
        }
      }
      // Move to the previous character in the text
      startIndex--;
    }
  }

  // Return an object containing the storedOpenTags, CloseTag, modified text, and check flag
  return { storedOpenTags, CloseTag, text, check };
}


function checkright(text, word) {
  // Initialize variables to store open tags, closing tags, and a check flag
  let storedOpenTags = '';
  let CloseTag = '';
  let startIndex = 0;
  let endIndex = 0;
  let check = false;

  // Iterate through the text to find opening and closing tags related to the specified word
  while ((startIndex = text.indexOf('<', endIndex)) !== -1 && endIndex != text.length) {
    // Find the index of the closing '>' character
    endIndex = text.indexOf('>', startIndex);

    // If no closing tag is found, break the loop
    if (endIndex === -1) {
      break;
    }

    // Extract the tag from startIndex to endIndex
    const tag = text.substring(startIndex, endIndex + 1);

    // Check if the tag is a closing tag for the specified word
    if (tag.startsWith('</' + word)) {
      // If it's a closing tag, check if it's at the begining of the text
      if (tag.length === endIndex + 1) {
        // If yes, remove the tag from the text and set CloseTag and check flags
        text = text.substring(endIndex + 1, text.length);
        CloseTag = tag;
        check = true;
      } else {
        // If it's not the entire tag, set CloseTag and storedOpenTags accordingly
        CloseTag = tag;
        storedOpenTags = '<' + word + '>';
      }
      // Break the loop after processing the closing tag
      break;
    } else if (tag.startsWith('<' + word)) {
      // If it's an opening tag, break the loop
      break;
    }
    endIndex++; // Move to the next '>' character
  }

  // Return an object containing the stored open tags, CloseTag, modified text, and check flag
  return { storedOpenTags, CloseTag, text, check };
}


  
function countAndSubtractTags(text, word) {
  // Initialize variables to store open tags, closing tags, and flags
  let storedOpenTags = '';
  let storedCloseTags = '';
  let closedtag = false;
  let startIndex = 0;
  let endIndex = 0;
  let startopen = false;
  let countWord1 = (text.match(new RegExp('<' + word, 'g')) || []).length;
  let countWord2 = (text.match(new RegExp('</' + word + '>', 'g')) || []).length;
  text = text.replace(/<br\s*\/?>/gi, '<<BR>>').trim();
  // Iterate through the text to find and process tags related to the specified word
  while ((startIndex = text.indexOf('<', endIndex)) !== -1) {
    endIndex = text.indexOf('>', startIndex);
    if (endIndex === -1) {
      break; // Break the loop if no closing '>' character is found
    }

    const tag = text.substring(startIndex, endIndex + 1);

    if (tag.startsWith('</' + word)) {
      // Handle closing tags
      if (!startopen) {
        // If it's the first closing tag encountered, store it in storedCloseTags
        closedtag = true;
        storedCloseTags += text.substring(startIndex, endIndex + 1);
      }
      // Remove the tag from the text and update counters
      text = text.slice(0, startIndex) + text.slice(endIndex + 1);
      startIndex = 0;
      endIndex = 0;
      countWord2--;
    } else if (tag.startsWith('<' + word)) {
      // Handle opening tags
      startopen = true;
      if (countWord2 > 0) {
        // If closing tags are still present, decrement the opening tag counter
        countWord1--;
      } else if (countWord1 > 0 && countWord2 <= 0) {
        // If no closing tags are left but opening tags are present, store in storedOpenTags
        storedOpenTags += text.substring(startIndex, endIndex + 1);
        closedtag = false;
      }
      // Remove the tag from the text and reset indices
      text = text.slice(0, startIndex) + text.slice(endIndex + 1);
      startIndex = 0;
      endIndex = 0;
    }
  }
text = text.replace(/<<BR>>/g, '<br>');
  // Return an object containing the stored open tags, stored close tags, and modified text
  return { storedOpenTags, storedCloseTags, text };
}

export const selectionEvent = async (
  event,
  setSelectedElement,
  setStartPosition,
  setEndPosition,
  setActiveStyle,
  setBlockId
) => {
  try {
    const closestBlock = event.target.closest('.ce-block');
    if (!closestBlock) {
      console.warn('No block selected');
      return;
    }
    const blockId = closestBlock.getAttribute('data-id');
    if (!blockId) {
      console.warn('Block ID not found');
      return;
    }
   if (blockId) setBlockId(blockId);
   log('blockId :>> ', blockId);
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) {
      console.warn('No text selection found');
      return;
    }

    const selectedText = selection.toString().trim();
    if (!selectedText) {
      console.warn('No text selected');
      return;
    }

    const range = selection.getRangeAt(0);
    const selectedNode = range.commonAncestorContainer;

    // Determine the selectedElement
    const selectedElement =
        selectedNode.nodeType === Node.TEXT_NODE
          ? selectedNode.parentElement
          : selectedNode;

    if (!(selectedElement instanceof Element)) {
      console.warn('Selected element is not an Element');
      return;
    }

    // Calculate start and end positions
    const preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(closestBlock);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);

    const start = preSelectionRange.toString().length;
    const end = start + selectedText.length;

      setSelectedElement(selectedElement);
      setStartPosition(start);
      setEndPosition(end);

    const computedStyle = window.getComputedStyle(selectedElement);
    const isBold = computedStyle.fontWeight === 'bold';
    const isItalic = computedStyle.fontStyle === 'italic';
    const isUnderline = computedStyle.textDecoration.includes('underline');

    const fontSize = parseInt(computedStyle.fontSize, 10);
    setActiveStyle({
      bold: isBold,
      italic: isItalic,
      underline: isUnderline,
      fontSize,
    });
  } catch (error) {
    console.error('Error handling text selection event:', error);
  }
};



  export  const changeAlignment = async (editorRef, alignment, blockIndex) => {
    try {
      const content = await editorRef.current.save();
      const blockId = editorRef.current.blocks.getCurrentBlockIndex();
      const currentBlock = blockId != -1 && content.blocks[blockId] || content.blocks.find(block => block.id === blockIndex);;
      if (currentBlock) currentBlock.data.alignment = alignment;
      editorRef.current.blocks.update(blockIndex, {
        alignment: currentBlock.data.alignment 
      });
    } catch (error) {
      console.error('Error applying alignment:', error);
    }
  };

