import { useQuery, gql } from '@apollo/client';

export default function useGetSearchTemplates() {
  const { data, loading } = useQuery(SEARCH_TEMPLATES);
  return { data, loading };
}

export const SEARCH_TEMPLATES = gql`
  query doctorSearchedTemplates {
    doctorSearchedTemplates {
      _id
      title
      description
      lastOpenedAt
      isDefault
      doctor {
        _id
      }
      formType
      blocks
      isAutoSent
    }
  }
`;
