import React from 'react';
import { SaveTemplateAs } from './SaveTemplateAs';
import { BlocksPicker } from './BlocksPicker';

export const CustomSidebar = () => {
  return (
    <div
      style={{
        borderRadius: '16px',
        height: 'calc(100vh - 64px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '1rem',
        position: 'relative',
        overflow: 'hidden',
        boxSizing: 'border-box',
        flexDirection: 'column',
      }}
    >
      <SaveTemplateAs />
      <BlocksPicker />
    </div>
  );
};
