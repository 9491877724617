import React from 'react';

function OtherInsurance({ color = '#344054' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.514 2.915a3.832 3.832 0 01-2.18.904 3.832 3.832 0 00-3.515 3.515 3.832 3.832 0 01-.904 2.18 3.832 3.832 0 000 4.972c.523.613.84 1.376.904 2.18a3.832 3.832 0 003.515 3.515c.804.064 1.567.38 2.18.904a3.832 3.832 0 004.972 0 3.832 3.832 0 012.18-.904 3.832 3.832 0 003.515-3.515c.064-.804.38-1.567.904-2.18a3.832 3.832 0 000-4.972 3.832 3.832 0 01-.904-2.18 3.832 3.832 0 00-3.515-3.515 3.832 3.832 0 01-2.18-.904 3.832 3.832 0 00-4.972 0z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.585 13.604c-.117 0-.175-.063-.175-.189v-2.226c0-.126.059-.189.175-.189h2.24c.117 0 .175.063.175.189v2.226c0 .126-.058.189-.175.189h-2.24zM10.88 13.604c-.117 0-.175-.063-.175-.189v-2.226c0-.126.058-.189.175-.189h2.24c.117 0 .175.063.175.189v2.226c0 .126-.058.189-.175.189h-2.24zM7.175 13.604c-.117 0-.175-.063-.175-.189v-2.226c0-.126.058-.189.175-.189h2.24c.117 0 .175.063.175.189v2.226c0 .126-.058.189-.175.189h-2.24z"
        fill={color}
      />
    </svg>
  );
}

export default OtherInsurance;
