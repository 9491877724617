import React from 'react';

function SelfRelationShip({ color = '#344054' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={18}
      viewBox="0 0 16 18"
      fill="none"
    >
      <path
        d="M14.666 16.5c0-1.163 0-1.745-.143-2.218A3.333 3.333 0 0012.3 12.06c-.473-.143-1.055-.143-2.218-.143H5.916c-1.163 0-1.744 0-2.217.143a3.333 3.333 0 00-2.222 2.222c-.144.473-.144 1.055-.144 2.218M11.75 5.25a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SelfRelationShip;
