import i18next from 'i18next';

export function splitText(text: string) {
  const { language } = i18next;
  let newText = text.replace(
    /(sleep|mood|reflection|message|task|session)/g,
    (match, p1) => {
      const translations = {
        sleep: language === 'fr' ? 'évaluation du sommeil' : 'sleep check-in',
        mood: language === 'fr' ? "évaluation d'humeur" : 'mood check-in',
        reflection: language === 'fr' ? 'réflexion' : 'reflection',
        message: 'message',
        task: language === 'fr' ? 'Tâche' : 'task',
        session: language === 'fr' ? 'Séance' : 'session',
      };

      return `<span style="color: #0265DC; font-weight: bold; text-decoration: underline; cursor: pointer;" data-type="${p1}">${translations[p1]}</span>`;
    }
  );

    if (newText.includes('reflection')) {
    newText = newText.replace(/completed/g, 'submitted');
  }

  newText = newText.replace(/Checkin/g, '');

  return newText;
}



export function getTypeFromText(text: string) {
  return (
    text?.split('completed a')[1]?.trim() ||
    text?.split('a enregistré une')[1]?.trim()
  );
}
