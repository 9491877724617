import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  Divider,
  Theme,
  makeStyles,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import InputBlock from './InputBlock';
import { IRates } from './type';
import { IsDefaultComponent } from './IsDefaultComponent';
import ColorInput from './ColorInput';
import { colors } from '../ManageTags/Constant';
import { convertTagFormat } from 'pages/SchedulePage/AddEventPage/utils';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import CptComponent from './cptComponent';
import clsx from 'clsx';
import Text from 'Text';
import { ReactComponent as OnlineIcon } from 'assets/Schedule/new/online.svg';
import { ReactComponent as InPersonIcon } from 'assets/Schedule/new/in-person.svg';

interface ICpt {
  category: string;
  status: string;
  code: string;
  description: string;
}
interface IRateModal {
  currentRow: IRates;
  editMode: boolean;
  tags: any;
  cpts: [ICpt];
}

const useStyles = makeStyles<Theme>(() => ({
  eventType: {
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50%',
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    maxHeight: '44px',
  },
  oneEvent: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: '#344054',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '11px 0',
    maxHeight: '44px',
    cursor: 'pointer',
    '& svg': {
      marginRight: '0.5rem',
    },
  },
  onlineEvent: {
    borderRight: '1px solid #D0D5DD',
  },
  activeEvent: {
    backgroundColor: '#147AF3',
    color: '#fff',
    '& svg path': {
      stroke: '#fff',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  equal: {
    flex: 1,
  },
  textTitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    color: '#344054',
  },
}));

export const convertObjectsFormat = (array) => {
  if (array) {
    return array?.map((element) => ({
      ...element,
      name: `${element?.code} - ${element?.description}`,
      id: element?.code,
    }));
  }
  return [];
};

const RatesModal = ({ currentRow, editMode, tags, cpts }: IRateModal) => {
  const { register, control, errors } = useFormContext();
  const commonProps = { register, control, errors };
  const classes = useStyles();

  return (
    <Box
      px="2rem"
      py={1}
      sx={{
        width: {
          xs: '50vw',
          lg: '43vw',
          xl: '35vw',
        },
      }}
      height="100%"
    >
      <InputBlock
        label={'Service name'}
        {...commonProps}
        name="session"
        currentRow={currentRow}
        editMode={editMode}
      />
      <CptComponent
        control={control}
        name="cptCode"
        options={convertObjectsFormat(cpts)}
        label="CPT code (optional)"
      />
      <IsDefaultComponent
        title={'Set as default service'}
        description={
          'This service will be preselected when your clients book with you.'
        }
        name="isDefault"
        {...commonProps}
        type={'switch'}
        isDefault={currentRow?.isDefault}
      />
      <ColorInput control={control} values={colors} />
      <Divider style={{ width: '100%', height: '2px', marginBlock: '2rem' }} />
      <Box className={classes.container}>
        <InputBlock
          label={'Duration'}
          type="number"
          {...commonProps}
          name="duration"
          currentRow={currentRow}
          editMode={editMode}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">minutes</InputAdornment>
            ),
          }}
        />
        <InputBlock
          label={'Currency'}
          {...commonProps}
          name="currency"
          type="select"
          options={['USD', 'EUR']}
          currentRow={currentRow}
          editMode={editMode}
          className={classes.equal}
        />
        <InputBlock
          label={'Pricing'}
          {...commonProps}
          name="rate"
          currentRow={currentRow}
          editMode={editMode}
          type="number"
          className={classes.equal}
        />
      </Box>

      <IsDefaultComponent
        title={'Prepayment Required'}
        description={'Client must pay service fees in order to join'}
        name="isPrepayment"
        {...commonProps}
        type="switch"
      />
      <Divider style={{ width: '100%', height: '2px', marginBlock: '2rem' }} />
      <Typography className={classes.textTitle}>Location</Typography>
      <Controller
        name="location"
        control={control}
        render={({ onChange, value }) => (
          <Box className={classes.eventType} mt={1}>
            <Box
              className={clsx(
                classes.oneEvent,
                classes.onlineEvent,
                value === 'Online' && classes.activeEvent // `value` is true for online
              )}
              onClick={() => onChange('Online')} // Set activeMeeting to true
            >
              <OnlineIcon />
              <Text i18nKey="online">Online</Text>
            </Box>
            <Box
              className={clsx(
                classes.oneEvent,
                value === 'In-person' && classes.activeEvent // `value` is false for in-person
              )}
              onClick={() => onChange('In-person')} // Set activeMeeting to false
            >
              <InPersonIcon />
              <Text i18nKey="inPerson">In-person</Text>
            </Box>
          </Box>
        )}
      />

      <Divider style={{ width: '100%', height: '2px', marginBlock: '2rem' }} />
      <Typography className={classes.textTitle}>Associated tags</Typography>
      <Box width="100%" mt={1}>
        <Controller
          name="tags"
          render={({ onChange, value }) => {
            return (
              <Symptoms
                type=""
                disableCustomFilter={true}
                options={convertTagFormat(tags?.doctorTags)}
                values={value}
                setValues={onChange}
              />
            );
          }}
          {...commonProps}
        />
      </Box>
    </Box>
  );
};

export default RatesModal;
