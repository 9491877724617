import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import Table from 'components/ui/Table';
import { IRole } from './PermissionSettings';
import { ReactComponent as DropDownComponent } from 'assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron-up.svg';
import { useStyles } from '../styles';
import Text from 'Text';
import DropDownSelect from '../DropdownSelect';
import { ReactComponent as ClientIcon } from 'assets/roles/clients.svg';
import { ReactComponent as ScheduleIcon } from 'assets/roles/calendar.svg';
import { ReactComponent as TasksIcon } from 'assets/roles/tasks.svg';
import { ReactComponent as FinancesIcon } from 'assets/roles/finances.svg';
import { ReactComponent as FilesIcon } from 'assets/roles/files.svg';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import { ReactComponent as Eye } from 'assets/templateSettings/eye.svg';
import TrashIcon from 'assets/TrashIcon';

interface ITableInterface {
  role: IRole;
  isSelected: boolean;
  handleSelect: () => void;
}

function TopSection({ role, isSelected, handleSelect }: ITableInterface) {
  const classes = useStyles();
  const [tableKey, setTableKey] = useState(0);

  // Initialize state to store current permissions
  const [currentPermissions, setCurrentPermissions] = useState(
    role.permissions
  );

  useEffect(() => {
    setTableKey((prevKey) => prevKey + 1);
    setCurrentPermissions(role.permissions); // Update currentPermissions on role change
  }, [role]);

  // Update permission values in state
  const updatePermission = (
    category: string,
    type: 'view' | 'edit' | 'delete',
    value: string
  ) => {
    setCurrentPermissions((prevPermissions) =>
      prevPermissions.map((permission) =>
        permission.category === category
          ? { ...permission, [type]: value }
          : permission
      )
    );
  };

  const getPermissionIcon = (permission: string) => {
    switch (permission) {
      case 'clients':
        return <ClientIcon />;
      case 'schedule':
        return <ScheduleIcon />;
      case 'tasks':
        return <TasksIcon />;
      case 'finances':
        return <FinancesIcon />;
      case 'files':
        return <FilesIcon />;
      default:
        return <ClientIcon />;
    }
  };

  const getPermissionDesc = (permission: string) => {
    switch (permission) {
      case 'clients':
        return 'Client list and records';
      case 'schedule':
        return 'Client sessions and events';
      case 'tasks':
        return 'Client-focused or general tasks';
      case 'finances':
        return 'Invoices, payments, and reports';
      case 'files':
        return 'Documents, images and videos';
      default:
        return 'Client list and records';
    }
  };

  const columns = [
    {
      id: 'category',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Permissions" style={{ whiteSpace: 'noWrap' }}>
            Permissions
          </Text>
        </Box>
      ),
      align: 'left',
      minWidth: 10,
      format: (value) => {
        return (
          <Box display="flex" alignItems="flex-start">
            {getPermissionIcon(value)}
            <Box display="flex" flexDirection="column" ml="1rem">
              <Typography
                style={{
                  fontWeight: 700,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: '15px',
                  fontFamily: 'Inter',
                }}
              >
                {value}
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: '13px',
                  fontFamily: 'Inter',
                  marginTop: '5px',
                  color: '#667085',
                }}
              >
                {getPermissionDesc(value)}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      id: 'view',
      minWidth: 10,
      label: (
        <Box fontSize="1rem" display="flex" alignItems="center">
          <Eye />
          <Text
            i18nKey="View"
            style={{ whiteSpace: 'noWrap', marginLeft: '10px' }}
          >
            View
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value, { category }) => {
        return (
          <Box width="158px">
            <DropDownSelect
              key={`view-${tableKey}`}
              defaultMenuName={
                currentPermissions.find((p) => p.category === category)?.view
              }
              disabled={role.role === 'Admin'}
              setValue={(newValue) =>
                updatePermission(category, 'view', newValue)
              }
              options={['None', 'Owned only', 'Assigned only']}
              onClickAddOption={() => {}}
            />
          </Box>
        );
      },
    },
    {
      id: 'edit',
      minWidth: 10,
      label: (
        <Box fontSize="1rem" display="flex" alignItems="center">
          <EditIcon />
          <Text
            i18nKey="Edit"
            style={{ whiteSpace: 'noWrap', marginLeft: '10px' }}
          >
            Edit
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value, { category }) => {
        return (
          <Box width="158px">
            <DropDownSelect
              key={`edit-${tableKey}`}
              defaultMenuName={
                currentPermissions.find((p) => p.category === category)?.edit
              }
              disabled={
                role.role === 'Admin' ||
                currentPermissions.find((p) => p.category === category)
                  ?.view === 'None'
              }
              setValue={(newValue) =>
                updatePermission(category, 'edit', newValue)
              }
              options={['None', 'Owned only', 'Assigned only']}
              onClickAddOption={() => {}}
            />
          </Box>
        );
      },
    },
    {
      id: 'delete',
      minWidth: 10,
      label: (
        <Box fontSize="1rem" display="flex" alignItems="center">
          <TrashIcon width="18px" height="18px" fill="#475467" />
          <Text
            i18nKey="Delete"
            style={{ whiteSpace: 'noWrap', marginLeft: '10px' }}
          >
            Delete
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value, { category }) => {
        return (
          <Box width="158px">
            <DropDownSelect
              key={`delete-${tableKey}`}
              defaultMenuName={
                currentPermissions.find((p) => p.category === category)?.delete
              }
              disabled={
                role.role === 'Admin' ||
                currentPermissions.find((p) => p.category === category)
                  ?.view === 'None'
              }
              setValue={(newValue) =>
                updatePermission(category, 'delete', newValue)
              }
              options={['None', 'Owned only', 'Assigned only']}
              onClickAddOption={() => {}}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box width="100%" mt="2rem">
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        onClick={handleSelect}
        style={{
          cursor: 'pointer',
        }}
      >
        <Box>{isSelected ? <ChevronUp /> : <DropDownComponent />}</Box>
        <Typography className={classes.sectionTitle}>
          Provider permissions for the{' '}
          <span style={{ color: '#0054B6' }}>{role?.role}</span> role
        </Typography>
      </Box>
      {isSelected && (
        <Box width="100%" mt="1rem">
          <Table
            loading={false}
            page={1}
            columns={columns as any}
            limit={10}
            fullData={false}
            handlePagination={() => {}}
            data={currentPermissions}
            pagination
            sorting={false}
          />
        </Box>
      )}
    </Box>
  );
}

export default TopSection;
