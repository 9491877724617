import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { TypeEnum } from 'pages/PatientActivities/commun/Activity';
import { useStyles } from '../styles';
import { ReactComponent as ReflectionIcon } from 'assets/patient-profile/response/reflection.svg';
import { ReactComponent as MoodIcon } from 'assets/patient-profile/response/mood.svg';
import { ReactComponent as SleepIcon } from 'assets/patient-profile/response/sleep.svg';
import { IPatientActivities } from 'pages/PatientActivities';
import dayjs from 'dayjs';
import { TranslationKeys } from 'shared/interfaces/i18';
import {
  emojis,
  handleEmoji,
} from 'pages/PatientActivities/RecentActivityCard';
import { modalTitle } from 'pages/PatientActivities/commun/ResultModal';
import { useTranslation } from 'react-i18next';

interface IViewModalProps {
  activities: IPatientActivities[];
  activityType: TypeEnum;
  currentId?: string;
}

export const ViewModal = ({
  activities,
  activityType,
  currentId,
}: IViewModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectedResponse = activities.filter((el) => el._id === currentId)[0];

  const title = modalTitle(activityType, 'form');

  const modalIcon = {
    [TypeEnum.MOOD]: () => <MoodIcon />,
    [TypeEnum.SLEEP]: () => <SleepIcon />,
    [TypeEnum.REFLECTION]: () => <ReflectionIcon />,
  };

  return (
    <Box>
      <Box borderBottom="1px solid #D0D5DD">
        <Box
          display="flex"
          alignItems="center"
          gridGap="0.8rem"
          px="2rem"
          py="1rem"
        >
          <>{modalIcon[activityType]()}</>
          <Box>
            <Typography className={classes.viewTitle}>
              {title ? t(title) : ''}
            </Typography>
            <Typography className={classes.viewDate}>
              {t('Logged')}{' '}
              {dayjs(selectedResponse?.assignedAt).format('MMM D, YYYY')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box px="2rem" py="1.5rem">
        {activityType !== TypeEnum.REFLECTION && (
          <>
            <Box display="flex" alignItems="center" gridGap="0.8rem" mb={4}>
              {handleEmoji(
                selectedResponse?.patientResponse[0]?.response,
                emojis
              )}
              <Box>
                <Typography className={classes.viewQuestion}>
                  {selectedResponse?.question
                    ? t(selectedResponse?.question as TranslationKeys)
                    : '-'}
                </Typography>
                <Typography className={classes.viewResponse}>
                  {selectedResponse?.patientResponse[0]?.response
                    ? t(selectedResponse?.patientResponse[0]?.response)
                    : '-'}
                </Typography>
              </Box>
            </Box>
            <Box mb={4}>
              <Typography className={classes.viewQuestion2}>
                {t('How do you describe this feeling?')}
              </Typography>
              <Typography className={classes.viewResponse}>
                {selectedResponse?.patientResponse[0]?.tags?.length > 0
                  ? selectedResponse?.patientResponse[0]?.tags
                      ?.map((tag) => t(tag))
                      .join(', ')
                  : '-'}
              </Typography>
            </Box>
          </>
        )}
        <Box mb={3}>
          {activityType !== TypeEnum.REFLECTION ? (
            selectedResponse?.patientResponse[0]?.notes && (
              <Typography className={classes.viewQuestion2}>Note</Typography>
            )
          ) : (
            <Typography className={classes.viewQuestion2}>
              {selectedResponse?.patientResponse[0]?.question
                ? selectedResponse?.patientResponse[0]?.question
                : '-'}
            </Typography>
          )}
          <Typography className={classes.viewResponse}>
            {activityType !== TypeEnum.REFLECTION
              ? selectedResponse?.patientResponse[0]?.notes
              : selectedResponse?.patientResponse[0]?.response ?? '-'}
          </Typography>
        </Box>
        {selectedResponse?.patientResponse[0]?.image && (
          <Box>
            <img
              src={selectedResponse?.patientResponse[0]?.image}
              alt="image"
              className={classes.viewImg}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
