import { Box, TextField, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { useStyles } from '../styles';

interface IFormQuestion {
  register: any;
  control: any;
  errors: any;
  question: string;
  name: string;
  isShort?: boolean;
  handleInputChange: (index: number, value: string) => void;
  questionIndex: number;
  responses?: string;
}

export const FormQuestion = ({
  register,
  control,
  errors,
  question,
  name,
  isShort = false,
  handleInputChange,
  questionIndex,
  responses,
}: IFormQuestion) => {
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      event.stopPropagation();
      inputRef?.current?.focus();
    }
  };

  return (
    <Box>
      <Typography className={classes.formQuestion}>{question}</Typography>
      <TextField
        inputRef={inputRef}
        className={classes.textField}
        variant="outlined"
        multiline={!isShort}
        rows={!isShort ? 6 : 1}
        onChange={(e) => handleInputChange(questionIndex, e.target.value)}
        value={responses}
        onKeyDown={handleKeyDown}
      />
    </Box>
  );
};
