import { Box, Typography } from '@material-ui/core';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import React from 'react';
import { ReactComponent as UploadInsurance } from 'assets/UploadInsurance.svg';
import { ReactComponent as UploadIconInsurance } from 'assets/UploadIconInsurance.svg';

import { useStyles } from '../Insurance';
import { DropzoneDialogBase } from 'material-ui-dropzone';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

const InsuranceCard = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState({ front: false, back: false });
  const [fileObjectsFront, setFront] = React.useState([]);
  const [fileObjectsBack, setBack] = React.useState([]);
  function onAddFront(newFileObjs) {
    if (fileObjectsFront.length > 0) return null;
    setFront([].concat(fileObjectsFront, newFileObjs));
    return null;
  }
  function onAddBack(newFileObjs) {
    if (fileObjectsBack.length > 0) return null;
    setBack([].concat(fileObjectsBack, newFileObjs));
    return null;
  }

  function onDeleteFront(deleteFileObj) {
    const filltredArray = fileObjectsFront.filter(
      (value, index: number) => value !== deleteFileObj
    );
    setFront(filltredArray);
  }
  function onDeleteBack(deleteFileObj) {
    const filltredArray = fileObjectsBack.filter(
      (value, index: number) => value !== deleteFileObj
    );
    setBack(filltredArray);
  }

  function onSaveFront() {
    props?.setValue(
      'frontPicture',
      fileObjectsFront.map((fileObject) => fileObject.file)
    );
    setOpen({ ...open, front: false });
  }
  function onSaveBack() {
    props?.setValue(
      'backPicture',
      fileObjectsBack.map((fileObject) => fileObject.file)
    );
    setOpen({ ...open, back: false });
  }

  const uploadCards = [
    {
      label: 'Front-side',
      onClick: () => {
        setOpen({ ...open, front: true });
      },
      name: 'frontPicture',
    },
    {
      label: 'Back-side',
      onClick: () => {
        setOpen({ ...open, back: true });
      },
      name: 'backPicture',
    },
  ];
  return (
    <Box my={2}>
      <Box display="flex" alignItems="center">
        <UploadInsurance style={{ marginRight: '5px' }} />
        <Typography className={classes.text}>
          Upload high-quality front and back photos of your insurance card
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        mt={4}
        style={{ gap: '20px' }}
      >
        {uploadCards?.map((card, index) => (
          <Box style={{ width: '50%' }}>
            <Typography>{card.label}</Typography>
            <Controller
              name={card?.name}
              control={props.control}
              defaultValue={[]}
              render={({ value, onChange }) => (
                <>
                  {value?.length > 0 ? (
                    props?.frontPicture && index === 0 ? (
                      <img
                        src={props?.frontPicture}
                        alt={card.label}
                        style={{ width: '100%' }}
                      />
                    ) : props?.backPicture && index === 1 ? (
                      <img
                        src={props?.backPicture}
                        alt={card.label}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      <img
                        src={
                          index === 0 && fileObjectsFront.length > 0
                            ? URL.createObjectURL(fileObjectsFront[0]?.file)
                            : index === 1 && fileObjectsBack.length > 0
                            ? URL.createObjectURL(fileObjectsBack[0]?.file)
                            : ''
                        }
                        alt={card.label}
                        style={{ width: '100%' }}
                      />
                    )
                  ) : (
                    <Box
                      mt={1}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      style={{
                        border: '1px solid #EAECF0',
                        borderRadius: '12px',
                        cursor: 'pointer',
                      }}
                      onClick={card.onClick}
                      py={4}
                    >
                      <UploadIconInsurance />
                      <Typography className={classes.uploadText}>
                        Click to upload
                      </Typography>
                      <Typography className={classes.uploadSizeText}>
                        (Max.20MB)
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            />
          </Box>
        ))}
      </Box>
      <DropzoneDialogBase
        dialogTitle={'Upload your front card'}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        fileObjects={fileObjectsFront}
        filesLimit={1}
        cancelButtonText={t('cancel')}
        submitButtonText={t('submit')}
        maxFileSize={5000000}
        open={open.front}
        onAdd={onAddFront}
        onDelete={onDeleteFront}
        onClose={() => {
          setOpen({ ...open, front: false });
          setFront([]);
        }}
        onSave={onSaveFront}
        showPreviews
        showFileNamesInPreview={false}
        showAlerts={['error', 'info']}
      />
      <DropzoneDialogBase
        dialogTitle={'Upload your back card'}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        fileObjects={fileObjectsBack}
        filesLimit={1}
        cancelButtonText={t('cancel')}
        submitButtonText={t('submit')}
        maxFileSize={5000000}
        open={open.back}
        onAdd={onAddBack}
        onDelete={onDeleteBack}
        onClose={() => {
          setOpen({ ...open, back: false });
          setBack([]);
        }}
        onSave={onSaveBack}
        showPreviews
        showFileNamesInPreview={false}
        showAlerts={['error', 'info']}
      />
    </Box>
  );
};

export default InsuranceCard;
