import { Grid, Typography, Box, InputLabel } from '@material-ui/core';
import Skeleton from 'components/ui/Skeleton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/ui/Button';
import { FormProvider, useForm } from 'react-hook-form';
import BasicModal from 'components/ui/Modal';
import InputBlock from './InputBlock';
import useCreateStripeAccount from '@eggmed/graphql-client/operations/doctorOperations/useCreateStripeAccount';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

export const schema = yup.object().shape({
  country: yup.string().required('Country is a required field'),
});
const UnsupportedCountry = ({ classes, loading, data }) => {
  const { doctor } = useAuth();
  const { country, address, suite, city, state, zipcode } = data || {};
  const [open, setOpen] = React.useState(false);
  const { createStripeAccountDoctor, loading: loadingMutation } =
    useCreateStripeAccount(doctor?._id);
  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      country: '',
      address,
      suite,
      city,
      state,
      zipcode,
    },
    resolver: yupResolver(schema),
  });
  React.useEffect(() => {
    if (data) {
      methods.reset({
        country: '',
        address,
        suite,
        city,
        state,
        zipcode,
      });
    }
  }, [methods.reset, data]);
  const { control, register, errors } = methods;
  const commonInputProps = { control, register, errors };
  async function onSubmit(data) {
    await createStripeAccountDoctor({ variables: { adressInput: data } });
    handleClose();
  }
  return (
    <Grid item xl={12} lg={12} md={12}>
      <Typography className={classes.title}>{t('Payout settings')}</Typography>
      <Skeleton loading={loading}>
        <Box className={classes.canceledBox} mt={1}>
          <Skeleton loading={loading}>
            <Typography className={classes.trialLabel}>
              Selected country is not supported for payouts{' '}
            </Typography>
          </Skeleton>
          <Typography className={classes.trialDescription}>
            The country you have selected is currently not supported for
            payouts. Please choose a supported country to ensure you can receive
            payments from your clients.{' '}
          </Typography>
          <CustomButton
            className={classes.addBtn}
            buttonType="reset"
            onClick={handleOpen}
          >
            <Typography style={{ fontWeight: 500 }}>Update address</Typography>
          </CustomButton>
        </Box>
      </Skeleton>
      <FormProvider {...methods}>
        <BasicModal
          open={open}
          onClose={() => {
            handleClose();
          }}
          handleClose={() => {
            handleClose();
          }}
          isFromModal
          isSlide
          divider
          title={'Update address'}
          titlePadding="2rem"
          onSubmit={onSubmit}
          loading={loadingMutation}
        >
          <Grid container style={{ marginBottom: '5vh' }}>
            <Box
              px="2rem"
              py={1}
              sx={{
                width: {
                  xs: '50vw',
                  lg: '43vw',
                  xl: '35vw',
                },
              }}
              height="100%"
            >
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12}>
                  <InputBlock
                    type="country"
                    label={'Supported countries'}
                    name="country"
                    isAllSupported
                    {...commonInputProps}
                  />
                </Grid>
                <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                  <InputBlock
                    type="text"
                    label={'Street address 1'}
                    name="address"
                    {...commonInputProps}
                  />
                </Grid>
                <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                  <InputBlock
                    label="Street address 2"
                    type="text"
                    name="suite"
                    {...commonInputProps}
                  />
                </Grid>

                <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                  <InputBlock
                    label="City"
                    type="text"
                    name="city"
                    {...commonInputProps}
                  />
                </Grid>
                <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                  <InputBlock
                    label={t('State/Province')}
                    type="text"
                    name="state"
                    {...commonInputProps}
                  />
                </Grid>
                <Grid item xs={12} lg={6} xl={6} md={6} sm={12}>
                  <InputBlock
                    label={t('Zip code')}
                    type="number"
                    name="zipcode"
                    {...commonInputProps}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </BasicModal>
      </FormProvider>
    </Grid>
  );
};

export default UnsupportedCountry;
