import React from 'react';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as PasswordIcon } from 'assets/passcode-lock.svg';
import { Box, Typography } from '@material-ui/core';
import { ITeam } from './TeamSettingsTable';
import { useStyles } from '../styles';
import Button from 'components/ui/Button';
import TrashIcon from 'assets/TrashIcon';

interface IEditProps {
  open: boolean;
  onClose: () => void;
  user: ITeam;
}

function RemoveModal({ open, onClose, user }: IEditProps) {
  const classes = useStyles();

  return (
    <BasicModal open={open} onClose={onClose} handleClose={onClose} isNew>
      <Box className={classes.PasswordModal}>
        <TrashIcon width="49px" height="49px" fill="#D92D20" />
        <Typography className={classes.modalTitle}>
          {`Remove ${user?.firstname} from your team?`}
        </Typography>
        <Typography className={classes.modalText}>
          This action will permanently delete their Eggmed account, and they
          will lose access to all assigned tasks and data.
        </Typography>
        <Box width="100%" my="1.5rem">
          <Typography className={classes.modalText}>
            This action <span style={{ fontWeight: 700 }}>cannot</span> be
            undone.
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ gap: '1rem' }}
        >
          <Button onClick={onClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button type="submit" className={classes.saveButtonRed}>
            Remove team member
          </Button>
        </Box>
      </Box>
    </BasicModal>
  );
}

export default RemoveModal;
