import React from 'react';

function ClientMinor({ color = '#182230' }: { color?: string }) {
  return (
    <svg width="20" height="20" viewBox="0 0 17.96 19.1">
      <defs></defs>
      <g id="Layer_1-2" data-name="Layer_1">
        <g>
          <path
            fill={color}
            d="M13.02,15.52c-.4,0-.72.32-.72.72v2.86h1.44v-2.86c0-.4-.32-.72-.72-.72Z"
          />
          <path
            fill={color}
            d="M5.11,15.52c-.4,0-.72.32-.72.72v2.86h1.44v-2.86c0-.4-.32-.72-.72-.72Z"
          />
          <path
            fill={color}
            d="M17.47,7.49h0c-.5-.86-1.5-1.48-2.6-1.47.09-.82-.01-1.63-.31-2.38h0c-.43-1.07-1.18-1.97-2.15-2.63h0c-.99-.67-2.16-1.02-3.37-1.02-2.57,0-4.83,1.58-5.64,3.95h0c-.23.66-.29,1.38-.22,2.1-.35,0-.69.04-1.03.17h0s0,0,0,0c-.66.26-1.26.76-1.61,1.36h0C.18,8.19-.02,9.02,0,9.78H0c.03,1.01.46,1.85,1.25,2.31h0s0,0,0,0h0c.39.23.8.33,1.2.33.68,0,1.32-.29,1.84-.66.45-.32.81-.72,1.06-1.09.34.49.77.9,1.27,1.23v.48h-.28s-1.95.18-1.95.18h-.04s-.03.01-.03.01c-1.42.49-2.44,1.83-2.44,3.42v3.12h1.44v-3.12c0-1.19.97-2.16,2.16-2.16h1.18c.21,1.14,1.2,1.99,2.39,1.99s2.18-.86,2.39-1.99h1.18c1.19,0,2.17.97,2.17,2.16v3.12h1.44v-3.12c0-1.58-1.02-2.93-2.44-3.42h-.03s-.04-.01-.04-.01l-1.95-.18h-.01s-.01,0-.01,0h-.25v-.48c.48-.31.9-.71,1.23-1.18.59.79,1.62,1.5,2.71,1.5.41,0,.83-.1,1.23-.34h0c.78-.46,1.22-1.27,1.27-2.25h0c.04-.74-.14-1.55-.49-2.14ZM4.44,6.68h0s0,0,0,0h0ZM4.23,9.77h0c-.15.25-.42.56-.75.8-.34.24-.7.4-1.02.4-.16,0-.32-.04-.47-.12h0c-.4-.24-.52-.7-.54-1.11h0c-.02-.56.16-1.12.33-1.41h0c.19-.32.53-.61.89-.75.14-.05.31-.1.51-.1s.43.05.68.19c.43.25.6.63.63,1.01.03.4-.1.81-.26,1.09ZM4.54,6.39s0,0,0,0c0,0,0,0,0,0ZM10.06,13.33c0,.56-.45,1.03-.99,1.03h0c-.56,0-1.01-.44-1.01-1v-.87c.64.15,1.32.15,2,0v.83ZM12.13,8.12c0,1.7-1.38,3.06-3.06,3.06s-3.06-1.37-3.06-3.06v-1.99h1.38c.81,0,1.54-.29,2.11-.6.11.5.58.86,1.11.78h0c.6-.09,1.11-.36,1.53-.64v2.46ZM12.05,3.87h0s0,.01,0,.01c-.32.29-.71.63-1.15.83v-.15c0-.76-.88-1.19-1.48-.72h0c-.41.33-1.2.83-2.03.83h-1.71c-.56,0-1,.41-1.08.95-.01-.42.04-.83.17-1.22h0c.6-1.77,2.31-2.97,4.27-2.97,1.76,0,3.18.87,3.94,2.25-.3-.11-.65-.08-.92.17ZM15.95,10.64c-.38.22-.81.16-1.23-.07-.42-.23-.78-.61-.94-.89h0c-.18-.31-.27-.72-.22-1.07.05-.4.26-.72.64-.94.25-.15.49-.2.71-.2.58,0,1.09.39,1.31.76.17.28.31.79.29,1.28-.02.49-.19.91-.56,1.13Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default ClientMinor;
