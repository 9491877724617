import { IRole } from './permissionstab/PermissionSettings';

export const team = [
  {
    _id: '101',
    picture:
      'https://plus.unsplash.com/premium_photo-1689551670902-19b441a6afde?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cmFuZG9tJTIwcGVvcGxlfGVufDB8fDB8fHww',
    firstname: 'Amanda',
    lastname: 'Smith',
    email: 'asmith@mail.com',
    inviteStatus: 'Accepted',
    role: 'Admin',
    lastActive: new Date(Date.now() - 16 * 60 * 1000), // 16 minutes ago
    Supervisor: null,
  },
  {
    _id: '102',
    picture:
      'https://www.beaconjournal.com/gcdn/presto/2021/05/12/NABJ/6f48888a-98f3-46cb-ae5b-ad8d06c6b8c5-IMG_Mark_J_Price_Photo_1_1_.JPG?width=660&height=908&fit=crop&format=pjpg&auto=webp',
    firstname: 'Alfonso',
    lastname: 'Bator',
    email: 'albator@mail.com',
    inviteStatus: 'Pending',
    role: 'Provider',
    lastActive: null,
    Supervisor: null,
  },
  {
    _id: '103',
    picture:
      'https://mrwallpaper.com/images/hd/portrait-of-woman-with-random-people-5wu04gyr7p6p0i5c.jpg',
    firstname: 'Haylie',
    lastname: 'Franci',
    email: 'hfrancie@mail.com',
    inviteStatus: 'Accepted',
    role: 'Provider',
    lastActive: new Date(Date.now() - 5 * 24 * 60 * 60 * 1000), // 5 days ago
    Supervisor: {
      _id: '102',
      picture:
        'https://www.beaconjournal.com/gcdn/presto/2021/05/12/NABJ/6f48888a-98f3-46cb-ae5b-ad8d06c6b8c5-IMG_Mark_J_Price_Photo_1_1_.JPG?width=660&height=908&fit=crop&format=pjpg&auto=webp',
      firstname: 'Alfonso',
      lastname: 'Bator',
    },
  },
  {
    _id: '104',
    picture:
      'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/35af6a41332353.57a1ce913e889.jpg',
    firstname: 'Gretchen',
    lastname: 'Philips',
    email: 'gretchenph@mail.com',
    inviteStatus: 'Expired',
    role: 'Assistant',
    lastActive: null,
    Supervisor: null,
  },
  {
    _id: '105',
    picture:
      'https://i.pinimg.com/236x/b7/31/e8/b731e83e46cfdff9c4f73bde73d1a281.jpg',
    firstname: 'Stephan',
    lastname: 'Bohn',
    email: 'sbohn@mail.com',
    inviteStatus: 'Accepted',
    role: 'Assistant',
    lastActive: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), // 1 month ago
    Supervisor: {
      _id: '101',
      picture:
        'https://preview.redd.it/created-random-people-using-chatgpt-midjourney-do-you-know-v0-xc8pg40i5dqb1.png?width=640&crop=smart&auto=webp&s=4029ddd24fbe7e577359e7e6d1f0c49bec2b4856',
      firstname: 'Amanda',
      lastname: 'Smith',
    },
  },
];

export const supervisors = [
  {
    _id: '101',
    firstname: 'Amanda',
    lastname: 'Smith',
    picture:
      'https://plus.unsplash.com/premium_photo-1689551670902-19b441a6afde?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cmFuZG9tJTIwcGVvcGxlfGVufDB8fDB8fHww',
  },
  {
    _id: '102',
    firstname: 'Alfonso',
    lastname: 'Bator',
    picture:
      'https://www.beaconjournal.com/gcdn/presto/2021/05/12/NABJ/6f48888a-98f3-46cb-ae5b-ad8d06c6b8c5-IMG_Mark_J_Price_Photo_1_1_.JPG?width=660&height=908&fit=crop&format=pjpg&auto=webp',
  },
  {
    _id: '103',
    firstname: 'Haylie',
    lastname: 'Franci',
    picture:
      'https://mrwallpaper.com/images/hd/portrait-of-woman-with-random-people-5wu04gyr7p6p0i5c.jpg',
  },
  {
    _id: '104',
    firstname: 'Gretchen',
    lastname: 'Philips',
    picture:
      'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/35af6a41332353.57a1ce913e889.jpg',
  },
  {
    _id: '105',
    firstname: 'Stephan',
    lastname: 'Bohn',
    picture:
      'https://i.pinimg.com/236x/b7/31/e8/b731e83e46cfdff9c4f73bde73d1a281.jpg',
  },
];
export const roles: IRole[] = [
  {
    role: 'Admin',
    permissions: [
      {
        category: 'clients',
        view: 'Everything',
        edit: 'Everything',
        delete: 'Everything',
      },
      {
        category: 'schedule',
        view: 'Everything',
        edit: 'Everything',
        delete: 'Everything',
      },
      {
        category: 'tasks',
        view: 'Everything',
        edit: 'Everything',
        delete: 'Everything',
      },
      {
        category: 'finances',
        view: 'Everything',
        edit: 'Everything',
        delete: 'Everything',
      },
      {
        category: 'files',
        view: 'Everything',
        edit: 'Everything',
        delete: 'Everything',
      },
    ],
    services: [
      {
        _id: 'service1',
        name: 'Complete Management',
        duration: 120,
        rate: 200,
        currency: 'USD',
        prepayment: 'required',
        location: 'online',
      },
    ],
  },
  {
    role: 'Provider',
    permissions: [
      {
        category: 'clients',
        view: 'Assigned only',
        edit: 'Assigned only',
        delete: 'Assigned only',
      },
      { category: 'schedule', view: 'None', edit: 'None', delete: 'None' },
      {
        category: 'tasks',
        view: 'Assigned only',
        edit: 'Assigned only',
        delete: 'None',
      },
      {
        category: 'finances',
        view: 'Everything',
        edit: 'Everything',
        delete: 'Assigned only',
      },
      {
        category: 'files',
        view: 'Owned only',
        edit: 'Owned only',
        delete: 'Owned only',
      },
    ],
    services: [
      {
        _id: 'service2',
        name: 'Therapy Session',
        duration: 60,
        rate: 100,
        currency: 'USD',
        prepayment: 'required',
        location: 'in person',
      },
      {
        _id: 'service3',
        name: 'Consultation',
        duration: 30,
        rate: 50,
        currency: 'USD',
        prepayment: 'none',
        location: 'online',
      },
    ],
  },
  {
    role: 'Assistant',
    permissions: [
      {
        category: 'clients',
        view: 'Assigned only',
        edit: 'None',
        delete: 'None',
      },
      {
        category: 'schedule',
        view: 'Assigned only',
        edit: 'None',
        delete: 'None',
      },
      {
        category: 'tasks',
        view: 'Assigned only',
        edit: 'Assigned only',
        delete: 'None',
      },
      { category: 'finances', view: 'None', edit: 'None', delete: 'None' },
      { category: 'files', view: 'Owned only', edit: 'None', delete: 'None' },
    ],
    services: [
      {
        _id: 'service4',
        name: 'Administrative Support',
        duration: 45,
        rate: 30,
        currency: 'USD',
        prepayment: 'none',
        location: 'online',
      },
    ],
  },
];
