import React, {
  ReactElement,
  ReactNode,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import Dayjs from 'dayjs';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, LinearProgress, Grid, Typography } from '@material-ui/core';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import BasicModal from 'components/ui/Modal';
import { log } from 'config/log';
import AddEventPage from 'pages/SchedulePage/AddEventPage';
import useGetAppointmentsByDate from '@eggmed/graphql-client/operations/appointmentsOperations/useGetAppointmentsByDate';
import useAddAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useAddAppointment';
import { Redirect, useLocation } from 'react-router-dom';
import { HeaderPageNameProvider } from './HeaderPageNameConext';
import SideBar from './sidebar';
import Header from './header';
import { getAppointmentsByDate_appointmentsByDate_result } from '@eggmed/graphql-client/operations/appointmentsOperations/__generated__/getAppointmentsByDate';
import NotificationProvider from 'pages/PatientNotificationPage/Provider';
import { ReactComponent as NoDoctorIllustration } from 'assets/illustrations/notLinked.svg';
import SubscriptionPage from 'pages/SubscriptionPage/SubscriptionPage';
import SchedulePageProvider from 'pages/SchedulePage/state/SchedulePageProvider';
import { ReactComponent as Eggm } from 'assets/LogoEggm.svg';
import { ReactComponent as DemoIcon } from 'assets/DemoIcon.svg';
import Button from 'components/ui/Button';
import useToggleDemo from '@eggmed/graphql-client/operations/doctorOperations/useToggleDemo';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '30%',
    height: '10px',
    backgroundColor: 'white',
    marginTop: '3%',
    borderRadius: '50px',
  },
  barColorPrimary: {
    backgroundImage: 'linear-gradient(to right, #39A0FF, #8FFF85)',
    borderRadius: '50px',
  },
  boxDemo: {
    backgroundColor: '#FAFEF5',
    borderLeft: '5px solid #66C61C',
  },
  demoBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  demoText: {
    fontWeight: 600,
    fontSize: '18px',
    color: '#182230',
  },
  main: {
    position: 'relative',
    overflowY: 'auto',
    height: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    width: '100%',
    paddingInline: ({ isMargin }: { isMargin: boolean }) =>
      isMargin ? theme.spacing(4) : 0,
    paddingTop: ({ isMargin }: { isMargin: boolean }) =>
      isMargin ? theme.spacing(2) : 0,
    backgroundColor: ({ isBackgroundGrey }: { isBackgroundGrey: boolean }) =>
      isBackgroundGrey ? '#F4F2EE' : 'white',
    transition: 'margin-left 0.3s ease',
    [theme.breakpoints.only('xl')]: {
      marginLeft: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
        !isSidebarExpanded ? '162px' : '71px',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
        !isSidebarExpanded ? '162px' : '71px',
    },
  },
  content: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    position: 'sticky',
    top: '0',
  },
  sidebar: {
    zIndex: 100000,
    position: 'sticky',
    [theme.breakpoints.only('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    boxSizing: 'border-box',
    position: 'sticky',
    top: '0',
  },
  illustration: {
    minHeight: 'calc(100vh - 110px)',
  },
  fixedHeader: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 100,
  },
  fixedSidebar: {
    position: 'fixed',
    top: '70px',
    left: 0,
    height: '100%',
    zIndex: 2,
    borderRight: '1px solid #EAECF0',
  },
  scrollableContent: {
    marginLeft: theme.spacing(30), // Adjust based on your sidebar width
    paddingTop: theme.spacing(10), // Adjust based on your header height
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflowY: 'auto',
    height: '100vh',
    boxSizing: 'border-box',
  },
}));

interface Props {
  children: ReactNode;
  isMargin?: boolean;
  isBackgroundGrey?: boolean;
}
const DoctorLayout = ({
  children,
  isMargin = true,
  isBackgroundGrey = false,
}: Props): ReactElement => {
  const { toggleDemo } = useToggleDemo();
  const history = useHistory();

  const location = useLocation();
  const isPatientProfile = location.pathname.includes('doctor/patient');

  async function toggleFunction() {
    await toggleDemo();
    if (isPatientProfile) {
      history.push(`/doctor/dashboard`);
    }
    return;
  }
  const hidden = [
    '/doctor/inbox',
    '/doctor/admin',
    '/doctor/help',
    '/doctor/terms',
  ];
  const isHiddenRoute = !hidden.some((route) =>
    location.pathname.startsWith(route)
  );
  const startDate = Dayjs(Dayjs().startOf('day').valueOf());
  const endDate = Dayjs(
    Dayjs()
      .endOf('day')
      .set('second', Dayjs().endOf('day').get('second') + 1)
      .set('minute', 1)
      .valueOf()
  );
  const { data } = useGetAppointmentsByDate({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });
  const [isSidebarExpanded, setIsSidebarExpanded] = React.useState(false); // State for sidebar expansion

  const [modalAddOpen, setAddModal] = useState<boolean>(false);
  const { addAppointment } = useAddAppointment();
  const classes = useStyles({
    marginLeft: location.pathname === '/doctor/schedule',
    isBackgroundGrey,
    isSidebarExpanded,
    isMargin,
  });
  const { user: authData, doctor, loading, error, role } = useAuth();
  const staffHasNoDoctor = !!!authData?._id && !!authData.user;

  const upcomingAppointments = useMemo(() => {
    return data?.appointmentsByDate?.result?.sort(
      (
        a: getAppointmentsByDate_appointmentsByDate_result,
        b: getAppointmentsByDate_appointmentsByDate_result
      ) => {
        if (a?.startDate < b?.startDate) {
          return -1;
        }
        if (a?.startDate > b?.startDate) {
          return 1;
        }
        return 0;
      }
    );
  }, [data?.appointmentsByDate?.result]);

  const handleOpenAddAppointment = useCallback(() => setAddModal(true), []);
  const handleCloseAddModal = useCallback(() => setAddModal(false), []);
  if (
    !localStorage.getItem('token') ||
    localStorage.getItem('token') === 'undefined'
  )
    return <Redirect to="/login" />;
  if (staffHasNoDoctor && window.location.pathname !== '/doctor/dashboard') {
    return <Redirect to="/doctor/dashboard" />;
  }
  if (loading) {
    return (
      <Box
        minHeight="100vh"
        display="flex"
        margin="auto"
        width="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: '#F4F2EE' }}
      >
        <Eggm />
        <LinearProgress
          classes={{
            root: classes.root,
            barColorPrimary: classes.barColorPrimary,
          }}
        />
      </Box>
    );
  }

  if (role === 'patient') {
    return <Redirect to="/patient" />;
  }

  if (error || !doctor || !authData || !role) {
    window.location.reload();
    return <div>Error</div>;
  }

  return (
    <SchedulePageProvider>
      <HeaderPageNameProvider>
        <Suspense
          fallback={
            <Box
              minHeight="100vh"
              display="flex"
              margin="auto"
              width="100%"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              style={{ backgroundColor: '#F4F2EE' }}
            >
              <Eggm />
              <LinearProgress
                classes={{
                  root: classes.root,
                  barColorPrimary: classes.barColorPrimary,
                }}
              />
            </Box>
          }
        >
          <Grid container style={{ flexWrap: 'nowrap' }}>
            <Box className={`${classes.fixedHeader} ${classes.content}`}>
              <Box className={classes.header}>
                <NotificationProvider>
                  <Header
                    openAddAppointment={handleOpenAddAppointment}
                    authData={authData}
                    doctor={doctor}
                    appointments={upcomingAppointments}
                    staffHasNoDoctor={staffHasNoDoctor}
                  />
                </NotificationProvider>
              </Box>
              {staffHasNoDoctor ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.illustration}
                  width="100%"
                >
                  <NoDoctorIllustration style={{ height: '450px' }} />
                </Box>
              ) : (
                <Box display="flex" flexWrap="no-wrap">
                  {!staffHasNoDoctor && (
                    <Box
                      className={`${classes.fixedSidebar} ${classes.sidebar}`}
                    >
                      <SideBar
                        sideRespons="normal"
                        isSidebarExpanded={isSidebarExpanded}
                        setIsSidebarExpanded={setIsSidebarExpanded}
                      />
                    </Box>
                  )}
                  <div className={classes.main}>
                    {isHiddenRoute && doctor.isDemo && (
                      <Box
                        mb={2}
                        p={2}
                        className={classes.boxDemo}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box display="flex" alignItems="center">
                          <DemoIcon style={{ marginRight: '10px' }} />
                          <Typography className={classes.demoText}>
                            Demo client enabled: Virginia Worrymore is set up
                            for your practice.
                          </Typography>
                        </Box>
                        <Button
                          className={classes.demoBtn}
                          onClick={() => toggleFunction()}
                        >
                          Disable demo client
                        </Button>
                      </Box>
                    )}
                    {children}
                  </div>
                </Box>
              )}
            </Box>
          </Grid>

          <BasicModal open={modalAddOpen} onClose={handleCloseAddModal}>
            <>
              {modalAddOpen && (
                <AddEventPage
                  closeModal={handleCloseAddModal}
                  editMode={false}
                  doctorId={authData.doctorId}
                  addEvent={async (data: any) => {
                    await addAppointment(data, (e: any) => log('error', e));
                  }}
                />
              )}
            </>
          </BasicModal>
          <SubscriptionPage />
        </Suspense>
      </HeaderPageNameProvider>
    </SchedulePageProvider>
  );
};

export default DoctorLayout;
