import { makeStyles } from '@material-ui/core';
import { BorderBottom } from '@material-ui/icons';

export const useStyles = makeStyles((theme) => ({
  tabForm: {
    width: '100%',
    '& .MuiTabs-scrollable': {
      marginLeft: '2rem',
    },
    '& .MuiTabs-root': {
      width: '100%',
      transform: 'translateX(-2rem)',
    },
    '& .MuiBox-root-922': {
      width: '100%',
    },
    '& .MuiTab-root': {
      maxWidth: '100% !important',
    },
    '&.MuiTab-wrapper': {
      padding: '10px 0',
      height: '100%',
    },
  },
  permissionsTab: {
    width: '100%',
    '& .MuiTabs-scrollable': {
      marginLeft: '2rem',
    },
    '& .MuiTabs-root': {
      width: '100%',
    },
    '& .MuiBox-root-922': {
      width: '100%',
    },
    '& .MuiTab-root': {
      maxWidth: '100% !important',
      [theme.breakpoints.down('xl')]: {
        minWidth: '1px',
      },
    },
    '&.MuiTab-wrapper': {
      padding: '10px 0',
      height: '100%',
    },
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    paddingInline: '3rem',
    paddingBlock: '0.5rem',
  },
  permission: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    paddingBlock: '0.5rem',
  },
  tabText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
  },
  clearButton: {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  addBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  addBtnText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#FFFFFF',
    whiteSpace: 'nowrap',
  },
  actions: {
    opacity: 0,
    transition: 'opacity 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionsVisible: {
    opacity: 1,
  },
  labelText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: '#182230',
    marginBottom: '0.5rem',
  },
  popup: {
    padding: '8px 12px',
    backgroundColor: '#101828',
    borderRadius: '8px',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '14.4px',
    textAlign: 'left',
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#D0D5DD',
    marginBlock: '1rem',
  },
  modalTitle: {
    marginTop: '1rem',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24.4px',
    textAlign: 'left',
    color: '#182230',
  },
  modalText: {
    marginTop: '5px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    color: '#667085',
  },
  PasswordModal: {
    width: theme.typography.pxToRem(457),
    boxSizing: 'border-box',
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '8px',
  },

  cancelButton: {
    backgroundColor: 'transparent',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    height: '44px',
    maxHeight: '44px',
    width: '100%',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#182230',
    textTransform: 'none',
  },
  saveButton: {
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    borderRadius: '8px',
    height: '44px',
    maxHeight: '44px',
    width: '100%',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0265DC',
    },
  },
  saveButtonRed: {
    backgroundColor: '#D92D20',
    border: '1px solid #D92D20',
    borderRadius: '8px',
    height: '44px',
    maxHeight: '44px',
    width: '100%',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#D92D20',
    },
  },
  CustomTab: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
  },
  sectionTitle: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'none',
    lineHeight: '20px',
    color: '#344054',
    marginLeft: '1rem',
  },
  type: {
    backgroundColor: '#EFF8FF',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
    border: '1px solid #B2DDFF',
    padding: '2px 8px',
    marginRight: '4px',
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Inter',
    color: '#175CD3',
  },
}));
