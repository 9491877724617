import React, { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import ThreeDotsIcon from 'assets/ThreeDotsIcon';
import { IRole } from './PermissionSettings';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import { ReactComponent as PasswordIcon } from 'assets/passcode.svg';
import TrashIcon from 'assets/TrashIcon';
import Menu from 'components/ui/Menu';

const CustomTab = ({
  isSelected,
  role,
  handleEdit,
  handleRemove,
}: {
  isSelected: boolean;
  role: IRole;
  handleEdit: () => void;
  handleRemove: () => void;
}): ReactElement => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const hasMenu = role.role !== 'Admin';
  const menuElements = [
    {
      avatar: <EditIcon />,
      name: 'Rename',
      onClick: handleEdit,
    },
    {
      avatar: <TrashIcon width={18} height={18} fill="#B42318" />,
      name: 'Remove',
      onClick: handleRemove,
      isRed: true,
    },
  ];
  return (
    <Box className={classes.permission}>
      <Typography
        className={classes.CustomTab}
        style={{
          color: isSelected ? '#0265DC' : '#848F9A',
        }}
      >
        {role.role}
      </Typography>
      {hasMenu && (
        <Box
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
        >
          <ThreeDotsIcon
            fill={isSelected ? '#0265DC' : '#848F9A'}
            width="20px"
            height="20px"
          />
        </Box>
      )}
      <Menu<any>
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        handleMenuClose={() => setAnchorEl(null)}
        MenuElements={
          role?.role === 'Provider' ? menuElements.slice(0, 1) : menuElements
        }
      />
    </Box>
  );
};

export default CustomTab;
