import React, { ReactNode } from 'react';
import { Box, Grid, Button, Typography } from '@material-ui/core';
import useStyles from './EventForm/styles';
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';
import ProgressLoader from 'components/ui/ProgressLoader';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTranslation } from 'react-i18next';

enum EventState {
  event = 'event',
  following = 'following',
}
enum typeAction {
  edit = 'edit',
  delete = 'delete',
}
interface IRecurringProps {
  handleClose: () => void;
  handleSubmitEvent?: any;
  infos?: any;
  closeModal: () => void;
  title: string;
  icon: ReactNode;
  type: string;
  loading?: boolean;
}
const RecurringModal = ({
  handleClose,
  handleSubmitEvent,
  infos,
  closeModal,
  title,
  icon,
  type,
  loading,
}: IRecurringProps) => {
  const { triggerSnack } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(EventState.event);
  const state = checked === EventState.event ? true : false;
  function handleChange() {
    if (checked === EventState.event) {
      setChecked(EventState.following);
    } else {
      setChecked(EventState.event);
    }
  }
  async function handleAction() {
    if (type == typeAction.delete && checked === EventState.event) {
      triggerSnack('Session cancelled successfully');
    } else if (type == typeAction.delete && checked === EventState.following) {
      triggerSnack('Sessions cancelled successfully');
    } else {
      triggerSnack('Changes updated successfully');
    }
    if (type == typeAction.edit) {
      await handleSubmitEvent(infos, undefined, state);
    } else {
      await handleSubmitEvent(infos, state);
    }
    handleClose();
    closeModal();
  }
  return (
    <Box>
      {icon}
      <Box my={2}>
        <Typography className={classes.textRecurring}>{title}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" ml={-1}>
        <Box display="flex" alignItems="center">
          <Radio
            className={classes.rootRadio}
            color="default"
            disableRipple
            checkedIcon={
              <span
                className={clsx(classes.iconRadio, classes.checkedIconRadio)}
              />
            }
            icon={<span className={classes.iconRadio} />}
            checked={checked === EventState.event}
            onChange={handleChange}
            value="event"
            data-cy="male-input"
            inputProps={{ 'aria-label': 'A' }}
          />
          {t('This event')}
        </Box>
        <Box display="flex" alignItems="center" mb={4}>
          <Radio
            className={classes.rootRadio}
            color="default"
            disableRipple
            checkedIcon={
              <span
                className={clsx(classes.iconRadio, classes.checkedIconRadio)}
              />
            }
            icon={<span className={classes.iconRadio} />}
            checked={checked === EventState.following}
            onChange={handleChange}
            value="following"
            data-cy="male-input"
            inputProps={{ 'aria-label': 'A' }}
          />
          {t('All the following events')}
        </Box>
        <Box display="flex" alignItems="center">
          <Button className={classes.btnC} onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button
            className={classes.btnSC}
            onClick={() => {
              handleAction();
            }}
          >
            {loading ? <ProgressLoader width={20} height={20} /> : t('Save')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RecurringModal;
