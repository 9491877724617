import { gql, useMutation } from '@apollo/client';
import { GET_INSURANCES } from './useGetInsurances';

export const DELETE_INSURANCE = gql`
  mutation deleteInsurance($id: ID) {
    deleteInsurance(id: $id) {
      patient {
        firstname
        lastname
      }
      frontPicture
      backPicture
      relationShipType
      firstname
      middlename
      lastname
      sex
      birthday
      phone
      address
      city
      state
      zipCode
      country
      insuranceType
      payer
      memberID
      groupID
      planID
      copay
      deductible
      startDate
      endDate
    }
  }
`;

export default function useDeleteInsurance(patient) {
  const [deleteInsurance, { data, error, loading }] = useMutation(
    DELETE_INSURANCE,
    {
      refetchQueries: [{ query: GET_INSURANCES, variables: { patient } }],
      awaitRefetchQueries: true,
    }
  );

  return { deleteInsurance, data, error, loading };
}
