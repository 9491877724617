export const templates = [
  {
    id: '1',
    _id: '1',
    name: 'SOAP',
    type: 'Note-taking',
    isDefault: true,
    isAutoSent: false, // Not allowed for Note-taking
    isScored: false, // SOAP is typically not scored
    tags: [
      { title: 'Retention', color: 'blue' },
      { title: 'Urgent', color: 'red' },
    ],
    lastUsed: '2 days ago',
  },
  {
    id: '2',
    _id: '2',
    name: 'DAP',
    type: 'Note-taking',
    isDefault: false,
    isAutoSent: false, // Not allowed for Note-taking
    isScored: true, // DAP might be scored
    tags: [
      { title: 'Diagnosis01', color: 'purple' },
      { title: 'Retention', color: 'blue' },
      { title: 'Special cases', color: 'orange' },
      { title: 'First session', color: 'green' },
      { title: 'TagB', color: 'red' },
      { title: 'TagC', color: 'green' },
      { title: 'TagD', color: 'orange' },
      { title: 'TagE', color: 'purple' },
      { title: 'TagF', color: 'yellow' },
    ],
    lastUsed: '2 weeks ago',
  },
  {
    id: '3',
    _id: '3',

    name: 'GAD-7',
    type: 'Assessment',
    isDefault: false,
    isAutoSent: false, // Not allowed for Assessment
    isScored: true, // GAD-7 is typically scored
    tags: [{ title: 'First session', color: 'green' }],
    lastUsed: '5 months ago',
  },
  {
    id: '4',
    _id: '4',

    name: 'PHQ-9',
    type: 'Assessment',
    isDefault: false,
    isAutoSent: false, // Not allowed for Assessment
    isScored: true, // PHQ-9 is typically scored
    tags: [],
    lastUsed: '1 month ago',
  },
  {
    id: '5',
    _id: '5',

    name: 'Release of Information',
    type: 'Intake',
    isDefault: false,
    isAutoSent: false,
    isScored: false, // Not typically scored
    tags: [
      { title: 'Special cases', color: 'orange' },
      { title: 'Urgent', color: 'red' },
    ],
    lastUsed: '2 days ago',
  },
  {
    id: '6',
    _id: '6',

    name: 'Third Party Financial Responsibility Form',
    type: 'Intake',
    isDefault: false,
    isAutoSent: false,
    isScored: false, // Not typically scored
    tags: [{ title: 'Urgent', color: 'red' }],
    lastUsed: '2 weeks ago',
  },
  {
    id: '7',
    _id: '7',

    name: 'Consent for Minor Usage of Software Services',
    type: 'Intake',
    isDefault: false,
    isAutoSent: false,
    isScored: false, // Not typically scored
    tags: [
      { title: 'Special cases', color: 'orange' },
      { title: 'First session', color: 'green' },
    ],
    lastUsed: '5 months ago',
  },
  {
    id: '8',
    _id: '8',

    name: 'Consent for Telehealth Consultation',
    type: 'Intake',
    isDefault: false,
    isAutoSent: true, // Allowed for Intake
    isScored: false, // Not typically scored
    tags: [{ title: 'Special cases', color: 'orange' }],
    lastUsed: '1 month ago',
  },
  {
    id: '9',
    _id: '9',

    name: 'Notice of Privacy Practices',
    type: 'Intake',
    isDefault: false,
    isAutoSent: true, // Allowed for Intake
    isScored: false, // Not typically scored
    tags: [{ title: 'Urgent', color: 'red' }],
    lastUsed: '2 years ago',
  },
  {
    id: '10',
    _id: '10',

    name: 'Informed Consent for Psychotherapy',
    type: 'Intake',
    isDefault: false,
    isAutoSent: false,
    isScored: false, // Not typically scored
    tags: [{ title: 'Urgent', color: 'red' }],
    lastUsed: '1 day ago',
  },
  {
    id: '11',
    _id: '11',

    name: 'Practice Policies',
    type: 'Intake',
    isDefault: false,
    isAutoSent: false,
    isScored: false, // Not typically scored
    tags: [{ title: 'Clients-centered', color: 'blue' }],
    lastUsed: '2 hours ago',
  },
  {
    id: '12',
    _id: '12',

    name: 'PIE',
    type: 'Note-taking',
    isDefault: false,
    isAutoSent: false, // Not allowed for Note-taking
    isScored: false, // PIE is typically not scored
    tags: [
      { title: 'Retention', color: 'blue' },
      { title: 'Urgent', color: 'red' },
    ],
    lastUsed: '2 years ago',
  },
  {
    id: '13',
    _id: '13',

    name: 'CHART',
    type: 'Note-taking',
    isDefault: false,
    isAutoSent: false, // Not allowed for Note-taking
    isScored: false, // CHART is typically not scored
    tags: [{ title: 'Clients-centered', color: 'blue' }],
    lastUsed: '1 day ago',
  },
  {
    id: '14',
    _id: '14',

    name: 'BIRP',
    type: 'Note-taking',
    isDefault: false,
    isAutoSent: false, // Not allowed for Note-taking
    isScored: true, // BIRP might be scored
    tags: [{ title: 'Retention', color: 'blue' }],
    lastUsed: '1 month ago',
  },
  {
    id: '15',
    _id: '15',

    name: 'SOAPIE',
    type: 'Note-taking',
    isDefault: false,
    isAutoSent: false, // Not allowed for Note-taking
    isScored: false, // SOAPIE is typically not scored
    tags: [{ title: 'Special cases', color: 'orange' }],
    lastUsed: '18 hours ago',
  },
  {
    id: '16',
    _id: '16',

    name: 'Custom Form 1',
    type: 'Other',
    isDefault: false,
    isAutoSent: false,
    isScored: false, // Default value; can be changed based on the form's nature
    tags: [
      { title: 'Tag1', color: 'blue' },
      { title: 'Tag2', color: 'red' },
      { title: 'Tag3', color: 'green' },
      { title: 'Tag4', color: 'orange' },
      { title: 'Tag5', color: 'purple' },
      { title: 'Tag6', color: 'yellow' },
      { title: 'Tag7', color: 'cyan' },
    ],
    lastUsed: '3 days ago',
  },
  {
    id: '17',
    _id: '17',

    name: 'Custom Form 2',
    type: 'Other',
    isDefault: false,
    isAutoSent: false,
    isScored: false, // Default value; can be changed based on the form's nature
    tags: [
      { title: 'TagA', color: 'blue' },
      { title: 'TagB', color: 'red' },
      { title: 'TagC', color: 'green' },
      { title: 'TagD', color: 'orange' },
      { title: 'TagE', color: 'purple' },
      { title: 'TagF', color: 'yellow' },
      { title: 'TagG', color: 'cyan' },
    ],
    lastUsed: '1 week ago',
  },
  {
    id: '18',
    _id: '18',

    name: 'Custom Form 3',
    type: 'Other',
    isDefault: false,
    isAutoSent: false,
    isScored: false, // Default value; can be changed based on the form's nature
    tags: [
      { title: 'TagX', color: 'blue' },
      { title: 'TagY', color: 'red' },
      { title: 'TagZ', color: 'green' },
      { title: 'TagM', color: 'orange' },
      { title: 'TagN', color: 'purple' },
      { title: 'TagO', color: 'yellow' },
      { title: 'TagP', color: 'cyan' },
    ],
    lastUsed: '1 month ago',
  },
];
