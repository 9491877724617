import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  timePicker: {
    '& div.react-datepicker-wrapper': {
      display: 'block',
    },
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: '100px',
  },
  managePayout: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 10px',
    width: '33%',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    marginTop: theme.spacing(2),

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  alertMsg: {
    fontWeight: 600,
    fontSize: '18px',
    color: '#182230',
    fontFamily: 'Inter, sans-serif',
  },
  payoutAlert: {
    backgroundColor: '#FFFBFA',
    borderLeft: '5px solid #B42318',
  },
  btnC: {
    height: '36px',
    border: '0.75px solid #A4A4A4',
    paddingInline: '20px',
    color: '#425364',
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  btnSC: {
    height: '36px',
    border: '0.75px solid #A4A4A4',
    paddingInline: '30px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'capitalize',
    backgroundColor: '#1F61DC',
    '&:hover': {
      backgroundColor: '#1F61DC',
    },
    minWidth: '102px',
  },
  rootRadio: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconRadio: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'white',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIconRadio: {
    backgroundColor: 'white',
    border: '1px solid #39B54A',
    color: 'green',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage: 'radial-gradient(#39B54A,#39B54A 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#f5f8fa',
    },
  },
  textRecurring: {
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#273142',
    textAlign: 'center',
  },
  toggleText: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#425364',
  },
  addPatientText: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#2DC76D',
  },
  prepayRequire: {
    border: '1px dashed #BCC6D3',
    backgroundColor: '#F8FAFB',
  },
  textPrepay: {
    fontSize: '13px',
    fontWeight: 500,
    color: '#425364',
  },
  summary: {
    border: '1px solid #FE443F',
    borderRadius: '6px',
    backgroundColor: ({ expanded }: { expanded: boolean }) =>
      expanded ? '#F8FAFB' : 'rgba(254, 68, 63, 0.06)',
  },
  frequency: {
    border: '0.5px solid #425364',
    backgroundColor: '#F8FAFB',
    borderRadius: '6px',
  },
  locationTitle: {
    textTransform: 'capitalize',
    color: ' #1F61DC',
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  freeTimes: {
    border: '1px solid #D0D5DD',
    borderRadius: '10px',
    padding: '12px 20px',
    boxSizing: 'border-box',
    flexGrow: 1,
    width: '18%',
    maxWidth: '18.5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    color: '#344054',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '14px',
  },
  illus: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  textTimes: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#425364',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  textTimesPatient: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '19px',
    color: '#425364',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: theme.spacing(2),
  },
  empty: {
    width: '91%',
    backgroundColor: '#F8FAFB',
    border: '1px dashed #BCC6D3',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  emptyIllustration: {
    width: '100%',
    border: '1px dashed #BCC6D3',
    borderRadius: '12px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
  },
  scroll: {
    '& ::-webkit-scrollbar': {
      webkitAppearance: 'none',
    },
    maxHeight: '50vh',
    overflowY: 'scroll',
  },
  spacingStatus: {
    marginLeft: theme.spacing(-3),
  },
  formControl: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  formPatientDate: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(0),
  },
  leftSpacing: {
    paddingLeft: theme.spacing(5),
  },
  marginStatus: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  descriptionIcon: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    marginRight: theme.spacing(3),
  },
  patientDescriptionIcon: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
  },
  label: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '0.875rem',
    lineHeight: '23px',
    fontWeight: 500,
    color: '#344054',
    marginBottom: '0.4rem',
  },
  addBtn: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 600,
    borderRadius: '8px',
    textTransform: 'none',
    border: '1px solid #D0D5DD',
    color: '#344054',
    backgroundColor: 'transparent',
    padding: '8px 16px',
    minWidth: '144px',
    width: 'unset',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    '&:hover': {
      backgroundColor: '#ececec',
      border: '1px solid #D0D5DD',
    },
    '& svg': {
      marginRight: '0.5rem',
    },
  },
  labelPatient: {
    color: '#707090',
    fontSize: '18px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  labelDoctor: {
    color: '#1F61DC',
    fontSize: '16px',
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  available: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#1F61DC',
  },
  eventDetails: {
    marginTop: theme.spacing(2),
    height: '3.5rem',
    maxHeight: '3.5rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sideAppointment: {
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  sideAppointmentPatient: {
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  bottomSpacingPatient: {
    marginBottom: theme.spacing(2),
  },
  bottomLabelSpacing: {
    marginBottom: theme.spacing(1),
  },
  topSpacing: {
    marginTop: '23px',
  },
  topSpacingDesc: {
    marginTop: '-5px',
  },
  topSpacingPatient: {
    marginTop: theme.spacing(1),
  },
  icon: {
    color: 'white',
  },
  betweenSpacing: {
    paddingRight: theme.spacing(2),
  },
  betweenSpacingSwitch: {
    paddingRight: theme.spacing(1),
  },
  betweenLeftSpacing: {
    marginLeft: theme.spacing(2),
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: '.15em 4px',
    fontWeight: 600,
    lineHeight: '15px',
    borderRadius: 2,
  },
  text: {
    flexGrow: 1,
    fontSize: '12px',
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
    marginLeft: '3px',
  },
  divider: {
    width: '67%',
    height: theme.typography.pxToRem(3),
    marginLeft: theme.spacing(1),
    backgroundColor: '#425364',
    [theme.breakpoints.only('lg')]: {
      width: '53%',
    },
    [theme.breakpoints.only('md')]: {
      width: '38%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '61%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '50%',
    },
  },
  secondaryText: {
    color: '#969aa0',
    fontSize: '0.9rem',
    lineHeight: '1.25rem',
    marginLeft: theme.spacing(1),
  },
  space: {
    paddingLeft: theme.spacing(4.5),
  },
  spaceIcon: {
    marginLeft: theme.spacing(0.5),
  },
  plusIcon: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  avatar: {
    width: '2.25rem',
    height: '2.25rem',
  },
  iconTopSpacing: {
    marginTop: theme.spacing(2),
  },
  validButton: {
    backgroundColor: '#2DC76D',
    color: 'white',
    border: '1px solid white',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2DC76D',
    },
  },
  cancelButton: {
    backgroundColor: 'white',
    color: '#425364',
    border: '1px solid #425364',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  patientFormRoot: {
    '& .MuiGrid-item': {
      paddingBottom: '20px',
    },
    // border: `1px solid ${theme.palette.gray.light}`,
    backgroundColor: theme.palette.gray.contrastText,
    borderRadius: '10px',
    padding: '10px',
    [theme.breakpoints.only('xs')]: {
      marginLeft: theme.spacing(-3),
    },
  },
  flexElement: {
    minHeight: theme.typography.pxToRem(170),
    maxHeight: theme.typography.pxToRem(170),
    [theme.breakpoints.down('md')]: {
      minHeight: theme.typography.pxToRem(270),
      maxHeight: theme.typography.pxToRem(270),
    },
    [theme.breakpoints.only('lg')]: {
      minHeight: theme.typography.pxToRem(230),
      maxHeight: theme.typography.pxToRem(230),
    },
  },
  switchRoot: {
    width: 31,
    height: 20,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 2,
    marginRight: theme.spacing(1),
  },
  switchThumb: {
    width: 16,
    height: 16,
  },
  switchBase: {
    '&.Mui-checked': {
      transform: 'translateX(12px)',
    },
  },
  widthBox: {
    width: '84%',
    [theme.breakpoints.only('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.only('md')]: {
      width: '74%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '82%',
    },
  },
  marginElement: {
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  selectDoctor: {
    width: '100%',
  },
  selectPatient: {
    width: '100%',
  },
  draft: {
    marginLeft: theme.spacing(3),
  },
  memberPreview: {
    padding: '10px',
    width: '97%',
    '&:hover': {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14),',
      borderRadius: '10px',
    },
  },
  removeMemberIcon: {
    borderRadius: '20px',
    padding: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(196, 196, 196, 0.55),',
    },
  },
  sideBox: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  sideBoxPatient: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      paddingLeft: '2rem',
    },
  },
  sideBoxDoctor: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  conflict: {
    backgroundColor: 'white',
    paddingBottom: '3rem',
    width: '60vw',
    [theme.breakpoints.only('sm')]: {
      width: '90vw',
      maxWidth: '90vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100vw',
    },
  },
  defaultSessionBox: {
    border: '1px solid var(--Orange-2, #FFAA15)',
    backgroundColor: 'rgba(255, 170, 21, 0.10)',
    borderRadius: '6px',
  },
  defaultSessionTitle: {
    padding: '5px',
    boxSizing: 'border-box',
    height: '23px',
    maxHeight: '23px',
  },
  title: {
    color: ' var(--Orange-2, #FFAA15)',
    fontFamily: 'Roboto',
    fontSize: '11px',
    lineHeight: '11px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineFeight: 'normal',
    letterSpacing: ' 0.234px',
  },
  linkTitle: {
    color: 'var(--Orange-2, #153AFF)',
    fontFamily: 'Roboto',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.234px',
    textDecorationLine: 'underline',
    padding: 0,
  },
  sessionLabel: {
    color: 'var(--2sd-Blue, #425364)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineGeight: 'normal',
    letterSpacing: '0.234px',
    textTransform: 'none',
  },
  cancelBtn: {
    backgroundColor: 'transparent',
    border: '1px solid var(--2sd-Blue, #425364)',
    color: 'var(--2sd-Blue, #425364)',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontweight: 400,
    lineHeight: 'normal',
    textTransform: 'capitalize',
  },
  saveBtn: {
    backgroundColor: 'var(--button-colors-green, #2DC76D)',
    border: '1px solid var(--button-colors-green, #2DC76D)',
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'var(--button-colors-green, #1CB65C)',
    },
  },
  selectInput: {
    height: '40px',
    '& .MuiFilledInput-root': {
      height: '40px',
      overflow: 'hidden',
    },
  },
  requirePrepayContainer: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    padding: '12px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    opacity: ({ isActivePayout }: { isActivePayout: boolean }) =>
      !isActivePayout ? 0.5 : 1,
  },
  requirePrepayTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 600,
    color: '#344054',
  },
  requirePrepayDesc: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
  },
  dateTimeContainer: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    padding: '12px 15px',
  },
  eventType: {
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50%',
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    maxHeight: '44px',
  },
  oneEvent: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: '#344054',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '11px 0',
    maxHeight: '44px',
    cursor: 'pointer',
    '& svg': {
      marginRight: '0.5rem',
    },
  },
  onlineEvent: {
    borderRight: '1px solid #D0D5DD',
  },
  activeEvent: {
    backgroundColor: '#147AF3',
    color: '#fff',
    '& svg path': {
      stroke: '#fff',
    },
  },
  frequencyContainer: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    padding: '12px 15px',
  },
  freqTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
  },
  freqDesc: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 400,
    color: '#475467',
  },
  textField: {
    width: '100%',
    '& fieldset': {
      border: '1px solid #EAECF0 !important',
      borderRadius: '8px',
    },
  },
  eventTitle: {
    marginBottom: '16px',
    marginTop: 0,
  },
  dateEventContainer: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    padding: '12px 15px 15px',
  },
  notesContainer: {
    '& > div > div': {
      marginTop: 0,
    },
  },
}));

export default useStyles;
