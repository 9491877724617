import React from 'react';
import { Controller } from 'react-hook-form';
import AutoCompleteAddNew from 'components/ui/Inputs/AutoCompleteAddnew';
import { useStyles } from './Payouts';
import { Box, Typography, makeStyles, Paper } from '@material-ui/core';
import clsx from 'clsx';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';

const CptComponent = ({
  control,
  options,
  name,
  label,
  isLimited = false,
  setValue,
  errors,
}: {
  control: any;
  options: any;
  name: string;
  label: string;
  isLimited?: boolean;
  setValue?: any;
  errors?: any;
}) => {
  const { t } = useTranslation();
  const limitedOptions = isLimited ? options.slice(0, 10) : options;
  const filterOptions =
    isLimited &&
    createFilterOptions({
      matchFrom: 'any',
      limit: 10, // This allows filtering to use the entire options array
    });
  const classes = useStyles();

  const CustomPaper = (props) => (
    <Paper {...props} style={{ width: '500px' }} />
  );
  return (
    <Box mb={2}>
      <Typography className={clsx(classes.textTitle, classes.margin)}>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({ onChange, value }) => (
          <AutoCompleteAddNew
            value={
              isLimited
                ? {
                    ...value,
                    name: value?.code,
                    id: value?.code,
                    description: value?.description,
                  }
                : value
            }
            onChange={(event, newValue) => {
              onChange(newValue);
              if (isLimited) {
                setValue('description', newValue?.description);
              }
            }}
            options={limitedOptions}
            className={classes.select}
            getOptionLabel={(option) => {
              if (option) {
                return option?.name;
              }
              return '';
            }}
            {...(isLimited && {
              filterOptions: ([], params) => filterOptions(options, params),
            })}
            {...(isLimited && {
              PaperComponent: CustomPaper,
            })}
          />
        )}
      />
      {errors && errors[name] && (
        <Box
          mt={0.5}
          data-cy="error-message"
          className={classes.error}
          display="flex"
          alignItems="center"
        >
          <ErrorIcon style={{ fontSize: '13px' }} />
          <Typography
            style={{ fontSize: '12px', marginLeft: '3px', fontWeight: 400 }}
          >
            {' '}
            {t(errors[name]?.message)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CptComponent;
