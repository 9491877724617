import React, { useEffect } from 'react';
import { useStyles } from '../styles';
import { Box, Typography, Button } from '@material-ui/core';
import { ReactComponent as DefaultIcon } from 'assets/default.svg';
import { IDoctorTemplates } from './FormModal';
import Menu from 'components/ui/Menu';
import { ReactComponent as CheckIcon } from 'assets/patientActivities/check.svg';
import useMakeDefaultTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useMakeDefaultTemplate';
import { DOCTOR_TEMPLATES } from './Questionaires';
import FormPage from 'pages/FormsPage';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as FormIcon } from 'assets/newForm.svg';
import { useTranslation } from 'react-i18next';
import TrashIcon from 'assets/TrashIcon';
import { ReactComponent as PreviewIcon } from 'assets/patientActivities/view.svg';
import { ReactComponent as EditIcon } from 'assets/patientActivities/edit.svg';
import { ReactComponent as CopyIcon } from 'assets/patientActivities/copyTemp.svg';
import useCreateDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreateDoctorTemplate';
import {
  templateEnum,
  useEditorContext,
} from 'components/Editorjs/EditorContextProvider';
import Tabs from 'components/ui/Tabs';
import { ReactComponent as IntakeIcon } from 'assets/form/tabs/intake.svg';
import { ReactComponent as AssessmentIcon } from 'assets/form/tabs/assessment.svg';
import { CardType } from './FormCard';
import { FormTemplates } from './FormTemplates';
import {
  FormTypeEnum,
  useProvider as useFormProvider,
} from './ActivityProvider';
import Pagination from 'components/ui/Pagination';
import { calculatePaginationTabs } from 'components/ui/Table/tableutils';

interface IQuestionaireModalProps {
  handleNext: () => void;
  title: string;
  patientId?: string;
  assignText?: string;
  doctorTemplates?: IDoctorTemplates[];
  openAssignModal: (form: IDoctorTemplates) => void;
  openDeleteModal: (form: IDoctorTemplates) => void;
  isNotes?: boolean;
  setNotes?: (notes: boolean) => void;
  appointmentId?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  loading?: boolean;
}

function QestionaireModal({
  handleNext,
  patientId,
  assignText,
  doctorTemplates,
  openAssignModal,
  openDeleteModal,
  isNotes = false,
  appointmentId,
  open,
  setOpen,
  loading,
}: IQuestionaireModalProps) {
  const { t } = useTranslation();
  const classes = useStyles({ selected: false });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [formToAssign, setFormToAssign] =
    React.useState<IDoctorTemplates>(null);
  const [menuType, setMenuType] = React.useState<CardType>(null);
  const [editMode, setEditMode] = React.useState(false);
  const [handleMakeDefault] = useMakeDefaultTemplate();
  const [handleCreateDoctorTemplate] = useCreateDoctorTemplate();
  const [customValue, setCustomValue] = React.useState<number>(0);
  const {
    setFormType,
    loading: templatesLoading,
    setPage,
    page,
    count,
  } = useFormProvider();

  const handlePagination = (e: unknown, pageNumber: number) => {
    setPage(pageNumber);
  };
  const handleDefault = () => {
    handleMakeDefault({
      variables: { id: formToAssign._id },
      refetchQueries: [DOCTOR_TEMPLATES],
    });
    setAnchorEl(null);
  };
  const handleCopy = async (form: IDoctorTemplates) => {
    await handleCreateDoctorTemplate({
      variables: {
        doctorTemplateInput: {
          title: form.title,
          description: form.description,
          questions: form.questions,
          lastOpenedAt: new Date(),
          type: isNotes ? 'note' : 'form',
        },
      },
      refetchQueries: [DOCTOR_TEMPLATES],
    });
    setAnchorEl(null);
  };

  const notesMenuElements = [
    {
      name: 'Edit',
      onClick: () => {
        handleEditForm(formToAssign, false);
        setAnchorEl(null);
        setEditMode(true);
      },
      avatar: <EditIcon />,
    },
    {
      name: 'Preview',
      onClick: () => {
        handleEditForm(formToAssign, true);
        setAnchorEl(null);
      },
      avatar: <PreviewIcon />,
    },
    {
      name: 'Make default',
      onClick: handleDefault,
      avatar: <DefaultIcon />,
      accessoryRight: formToAssign?.isDefault && <CheckIcon />,
    },
    {
      name: 'Delete',
      onClick: () => {
        handleDeleteForm(formToAssign);
        setAnchorEl(null);
      },
      avatar: <TrashIcon fill="#B42318" width="18px" height="20px" />,
      isRed: true,
    },
  ];
  const { setBlocks, handleChangeText, handleSelectedTemplate } =
    useEditorContext();
  const menuElements = [
    {
      name: 'Edit',
      onClick: () => {
        setBlocks(formToAssign?.blocks, true, formToAssign?._id, true);
        handleNext();
        handleChangeText(formToAssign.title);
        handleSelectedTemplate(formToAssign.formType);
        // handleEditForm(formToAssign, false);
        setAnchorEl(null);
        setEditMode(true);
      },
      avatar: <EditIcon />,
    },
    {
      name: 'View',
      onClick: () => {
        handleEditForm(formToAssign, true);
        setAnchorEl(null);
        setEditMode(false);
      },
      avatar: <PreviewIcon />,
    },
    {
      name: 'Delete',
      onClick: () => {
        handleDeleteForm(formToAssign);
        setAnchorEl(null);
      },
      avatar: <TrashIcon fill="#B42318" width="18px" height="20px" />,
      isRed: true,
    },
  ];
  const defaultMenuElements = [
    {
      name: 'View',
      onClick: () => {
        handleEditForm(formToAssign, true);
        setAnchorEl(null);
        setEditMode(false);
      },
      avatar: <PreviewIcon />,
    },
    {
      name: 'Copy',
      onClick: () => {
        handleCopy(formToAssign);
        setAnchorEl(null);
      },
      avatar: <CopyIcon />,
    },
  ];
  const menuTypes = {
    [CardType.default]: () => defaultMenuElements,
    [CardType.personal]: () => menuElements,
    [CardType.notes]: () => notesMenuElements,
  };
  const handleOpenAssignModal = (
    target: HTMLElement,
    form: IDoctorTemplates
  ) => {
    setFormToAssign(form);
    setAnchorEl(target);
  };
  const handleAssignForm = async (form: IDoctorTemplates) => {
    openAssignModal(form);
  };
  const [previewMode, setPreviewMode] = React.useState<Boolean>(false);
  const handleEditForm = async (form: IDoctorTemplates, preview: Boolean) => {
    await setPreviewMode(preview);
    await setFormToAssign(form);
    setOpen(true);
  };
  const handleDeleteForm = async (form: IDoctorTemplates) => {
    openDeleteModal(form);
  };

  useEffect(() => {
    setFormType(
      customValue === 0 ? FormTypeEnum.intakeForm : FormTypeEnum.assessmentForm
    );
    setPage(1);
  }, [customValue]);

  return (
    <>
      <Box className={classes.formModal}>
        <Box
          boxSizing="border-box"
          width="100%"
          px="2rem"
          py="1rem"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            className={classes.button}
            onClick={() => {
              setBlocks(null, false, null);
              handleSelectedTemplate(templateEnum.assessmentForm);
              handleNext();
            }}
          >
            <FormIcon style={{ marginRight: '5px' }} />
            {isNotes ? t('Create template') : t('Create a new form')}
          </Button>
        </Box>
        <Tabs
          classNameTab={classes.tabForm}
          tabsMode="form"
          tabsBar={[
            <Box display="flex" alignItems="center" gridGap="0.5rem">
              <IntakeIcon className={customValue === 0 && classes.activeIcon} />
              Intake forms
            </Box>,
            <Box display="flex" alignItems="center" gridGap="0.5rem">
              <AssessmentIcon
                className={customValue === 1 && classes.activeIcon}
              />
              Assessment forms
            </Box>,
          ]}
          tabsContent={[
            <FormTemplates
              templates={doctorTemplates}
              loading={loading || templatesLoading}
              handleNext={handleNext}
              isNotes={isNotes}
              setMenuType={setMenuType}
              handleAssignForm={handleAssignForm}
              handleOpenAssignModal={handleOpenAssignModal}
            />,
            <FormTemplates
              templates={doctorTemplates}
              loading={loading || templatesLoading}
              handleNext={handleNext}
              isNotes={isNotes}
              setMenuType={setMenuType}
              handleAssignForm={handleAssignForm}
              handleOpenAssignModal={handleOpenAssignModal}
            />,
          ]}
          customValue={customValue}
          setCustomValue={setCustomValue}
        />
        <Box display="flex" justifyContent="center" mt={8}>
          <Pagination
            count={calculatePaginationTabs(5, count)}
            onChange={handlePagination}
            page={page}
            variant="outlined"
            shape="rounded"
          />
        </Box>
      </Box>

      <Menu<any>
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        handleMenuClose={() => setAnchorEl(null)}
        MenuElements={menuTypes[menuType || CardType.default]()}
        className={classes.menu}
      />
      <BasicModal
        open={open}
        onClose={() => setOpen(false)}
        handleClose={() => setOpen(false)}
        isRegularModal
        isSlide
        divider
        title={formToAssign?.title}
      >
        <FormPage
          isNotes={isNotes}
          forms={{
            formName: formToAssign?.title,
            formDescription: formToAssign?.description,
            formQuestions: formToAssign?.questions,
            formId: formToAssign?._id && formToAssign?._id,
            doctorId: formToAssign?.doctor?._id,
            patientId,
            assignText,
            appointmentId,
            previewMode: previewMode,
            source: formToAssign?.source,
            blocks: formToAssign?.blocks,
          }}
          editMode={editMode}
        />
      </BasicModal>
    </>
  );
}

export default QestionaireModal;
