import { useMutation, gql, useQuery } from '@apollo/client';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import ProgressLoader from 'components/ui/ProgressLoader';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import React, { useState } from 'react';
import Skeleton from 'components/ui/Skeleton';
import Text from 'Text';
import SignatureCanvas from 'react-signature-canvas';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import { ReactComponent as Trash } from 'assets/ClearTrash.svg';
import dayjs from 'dayjs';
import { TranslationKeys } from 'shared/interfaces/i18';
import { useSnackbar } from 'hooks/useSnackbar';
import { InfoBlock } from './InfoBlock';
import { BlockNames } from './PersonalInformation';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import Tabs from 'components/ui/Tabs';
import { ReactComponent as ProviderInfo } from 'assets/ProviderInfo.svg';
import { ReactComponent as OrganizationInfo } from 'assets/OrganizationInfo.svg';
import Input from 'components/ui/Inputs';
import { EDIT_DOCTOR, GET_DOCTOR } from 'pages/DoctorProfilePage/graphql';

import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';
import SignatureComponent from './SignatureComponent';

export const useStyles = makeStyles((theme) => ({
  gridContainerBilling: {
    padding: '0px 0px',
    position: 'relative',
  },
  activeIcon: {
    '& path': {
      stroke: '#0265DC',
    },
    transform:"scale(1.25)",
  },
  activeBox: {
    '& path': {
      stroke: '#0265DC',
    },
    '& .MuiTab-root': {
      maxWidth: '100% !important',
    },
    height: "5vh",
  },
  activeText:{
    fontSize:"18px"
  },
  normalText:{
    fontSize:"16px"
  },
  defaultBox: {
    '& path': {
      stroke: '#98A2B3',
    },
    height: "5vh"
  },
  tabForm: {
    width: '100%',
    '& .MuiTabs-scrollable': {
      marginLeft: '2rem',
    },
    '& .MuiTab-root': {
      maxWidth: '100% !important',
    },
    '&.MuiTab-wrapper':{
      padding:"10px 20px 10px 20px",
      height:"100%"
    },

  },
  divider: {
    marginTop: '-15px',
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
  },
  topsection: {
    backgroundColor: '#F5FBFF',
    paddingBlock: '16px',
    borderBottom: '1px solid #D0D5DD',
  },
  rootRadio: {
    maxWidth: '22px',
    marginLeft: '-8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconPatient: {
    cursor: 'pointer',
  },
  iconRadio: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'white',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIconRadio: {
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    color: 'green',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage: 'radial-gradient(white,white 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#0265DC',
    },
  },
  editBtn: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  editTxt: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    textDecoration: 'underline',
    color: '#101828',
    textTransform: 'capitalize',
    textUnderlineOffset: '2px',
  },
  radioLabel: {
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#475467',
  },
  label: {
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#344054',
  },
  clearTxt: {
    fontWeight: 600,
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#B42318',
  },
  paragraph: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#344054',
    fontFamily: 'Inter',
  },
  addBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  date: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
    // marginTop: theme.spacing(-3),
    marginLeft: theme.spacing(1),
  },
  content: {
    color: '#D92D20',
  },
}));

const PracticeInformation = ({
  isPatient = false,
  isEditable = false,
  handleCloseSignaturemodal,
  isPracticeSetting = true,
}: {
  isPatient?: boolean;
  isEditable?: boolean;
  handleCloseSignaturemodal?: () => void;
  isPracticeSetting?: boolean;
}) => {
  const { toggleSignature } = useEditorContext();
  const [editableBlock, setEditableBlock] = useState<BlockNames | null>(null);
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language || 'en');
  const { doctor, patient } = useAuth();
  const [editMode, setEditMode] = React.useState(false);
  const { triggerSnack } = useSnackbar();

  const [signature, { loading }] = useMutation(SIGNATURE);
  const refetchQueries = isPatient
    ? [{ query: GET_PATIENT, variables: { patientId: patient?._id } }]
    : [{ query: GET_DOCTOR, variables: { DoctorId: doctor?._id } }];
  const [setSignature, { loading: loadingSignature }] = useMutation(
    SET_SIGNATURE,
    {
      refetchQueries,
    }
  );
  const variables = isPatient
    ? { patientId: patient?._id }
    : { DoctorId: doctor?._id };
  const { data, loading: loadingQuery } = useQuery(
    isPatient ? GET_PATIENT : GET_DOCTOR,
    {
      variables,
    }
  );
  const dataUser = isPatient ? data?.patient : data?.doctor;
  const methods = useForm({
    defaultValues: {
      name: editMode
        ? dataUser?.signature?.name
        : isPatient
          ? patient?.firstname + ' ' + patient?.lastname
          : doctor?.firstname + ' ' + doctor?.lastname,
      ...dataUser?.providerSettings,
      ...dataUser?.organizationSettings,
    },
  });
  const [editDoctor, { loading: loadingMutation }] = useMutation(EDIT_DOCTOR, {
    refetchQueries: [
      { query: GET_DOCTOR, variables: { DoctorId: doctor?._id } },
    ],
    awaitRefetchQueries: false,
  });
  const { register, control, errors, watch, reset, getValues } = methods;
  const commonProps = { register, control, errors };
  React.useEffect(() => {
    if (dataUser?.signature?.name) {
      reset({
        name: editMode
          ? dataUser?.signature?.name
          : isPatient
            ? patient?.firstname + ' ' + patient?.lastname
            : doctor?.firstname + ' ' + doctor?.lastname,
      });
    }
  }, [dataUser?.signature?.name, reset, editMode]);
  React.useEffect(() => {
    if (dataUser && !isPatient) {
      reset({
        ...dataUser?.providerSettings,
        ...dataUser?.organizationSettings,
      });
    }
  }, [reset, dataUser, isPatient]);
  const [signatures, setSignatures] = React.useState([]);
  const [selectedSignature, setSelectedSignature] = React.useState(0);
  async function handleGenerate() {
    if (watch()?.name === '') {
      setSignatures([]);
      setSelectedSignature(0);
      return;
    }
    const sign = await signature({
      variables: {
        name: watch()?.name,
      },
    });
    setSignatures(sign.data.generateSignature);
  }
  const handleSignatureSelect = (index) => {
    setSelectedSignature(index);
  };
  const handleUpload = async (data) => {
    if (selectedSignature === null) {
      return;
    }
    const selectedSVG = signatures[selectedSignature];
    const blob = new Blob([selectedSVG], { type: 'image/svg+xml' });
    const file = new File([blob], 'signature.svg', { type: 'image/svg+xml' });
    try {
      if (selectedSVG) {
        await setSignature({
          variables: {
            signatureInput: {
              picture: file,
              isGenerated: true,
              name: data?.name,
              index: selectedSignature,
            },
          },
        });
        triggerSnack();
        handleClose();
        toggleSignature(true);
        handleCloseSignaturemodal && handleCloseSignaturemodal();
      }
    } catch (error) {
      console.error('Error uploading signature:', error);
    }
  };

  const sigCanvasRef = React.useRef(null);
  const saveSignature = async () => {
    sigCanvasRef.current.getCanvas().toBlob(async (blob) => {
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      try {
        await setSignature({
          variables: {
            signatureInput: {
              picture: file,
              isGenerated: false,
              name: `${dataUser?.firstname} ${dataUser?.lastname}`,
              index: undefined,
            },
          },
        });
        triggerSnack();
        handleClose();
        toggleSignature(true);
        handleCloseSignaturemodal && handleCloseSignaturemodal();
      } catch (error) {
        console.error('Error uploading signature:', error);
      }
    });
  };

  const classes = useStyles();
  const [customValue, setCustomValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    handleGenerate();
  }, [watch()?.name, open]);
  React.useEffect(() => {
    if (dataUser?.signature?.index) {
      setSelectedSignature(dataUser?.signature?.index);
    }
  }, [dataUser?.signature?.index, open]);
  function handleClose() {
    setOpen(false);
    setEditMode(false);
    setSelectedSignature(0);
    setEditableBlock(null);
  }
  const [checked, setChecked] = React.useState(0);
  React.useEffect(() => {
    if (dataUser?.signature?.isGenerated) {
      setChecked(0);
    } else {
      setChecked(1);
    }
  }, [dataUser?.signature?.isGenerated]);
  function toDataURL(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (open && sigCanvasRef.current && dataUser?.signature?.picture) {
        toDataURL(dataUser?.signature?.picture).then((dataUrl) => {
          sigCanvasRef.current.clear();
          sigCanvasRef.current.fromDataURL(dataUrl);
        });
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [open, dataUser?.signature?.picture]);
  React.useEffect(() => {
    if (
      checked === 1 &&
      sigCanvasRef.current &&
      dataUser?.signature?.picture &&
      !dataUser?.signature?.isGenerated
    ) {
      sigCanvasRef.current.clear();
      sigCanvasRef.current.fromDataURL(dataUser.signature.picture);
    }
  }, [
    checked,
    data?.doctor?.signature?.picture,
    data?.patient?.signature?.picture,
  ]);
  function handleChange(event) {
    setChecked(Number(event.target.value));
  }
  const options = [
    {
      name: 'Generate',
      value: 0,
    },
    {
      name: 'Draw',
      value: 1,
    },
  ];

  async function submit(data) {
    if (editableBlock === BlockNames.signature) {
      if (checked === 0) {
        handleUpload(data);
      } else {
        saveSignature();
      }
    } else {
      const object =
        customValue === 0 ? 'providerSettings' : 'organizationSettings';
      const { __typename, ...rest } = dataUser[object] || {};

      const editedData = {
        firstname: dataUser?.firstname,
        lastname: dataUser?.lastname,
        username: dataUser?.username,
        email: dataUser?.email,
      };
      await editDoctor({
        variables: {
          doctorID: doctor._id,
          DoctorInput: {
            ...editedData,
            [object]: { ...rest, ...data },
          },
        },
      });
      triggerSnack();
      setEditableBlock(null);
    }
  }

  const updatedAt = `${t('Updated')} ${dayjs(
    dataUser?.signature?.updatedAt
  ).format(i18n.language === 'fr' ? 'D MMMM YYYY' : 'MMMM D, YYYY')}`;
  const showContent = dataUser?.signature?.picture && !loadingQuery;

  return (
    <Grid container spacing={2} className={classes.gridContainerBilling}>
      <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
        {!isPatient && isPracticeSetting && (
          <>
            <Skeleton loading={loadingQuery}>
              <Text
                i18nKey="Practice information"
                style={{
                  fontFamily: 'Inter, sans-serif',
                  fontStyle: 'normal',
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  color: '#344054',
                }}
              >
                Practice information
              </Text>
            </Skeleton>
            <Skeleton loading={loadingQuery}>
              <Text
                i18nKey="Practice information"
                style={{
                  fontFamily: 'Inter, sans-serif',
                  fontStyle: 'normal',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: '#667085',
                  marginTop: '10px',
                }}
              >
                Configure your provider and organization details{' '}
              </Text>
            </Skeleton>
          </>
        )}
      </Grid>
      {!isPatient && isPracticeSetting ? (
        <Box width="100%" ml={'-2rem'} mt={4}>
          <Tabs
            tabsMode="form"
            classNameTab={classes.tabForm}
            tabsBar={[
              <Box  className={customValue===0?classes.activeBox:classes.defaultBox} width="100%" display="flex" alignItems="center" gridGap={'0.5rem'}>
                <ProviderInfo
                  className={customValue === 0 && classes.activeIcon}
                />
                <p className={customValue===0&&classes.activeText} >Provider information</p>
              </Box>,
              <Box className={customValue===1?classes.activeBox:classes.defaultBox} display="flex" alignItems="center" gridGap={'0.5rem'}>
                <OrganizationInfo
                  className={customValue === 1 && classes.activeIcon}
                />
                <p className={customValue===1&&classes.activeText}> Organization information</p>
              </Box>,
            ]}
            tabsContent={[
              <Grid
                item
                xs={12}
                xl={isEditable ? 12 : 5}
                lg={isEditable ? 12 : 7}
                md={12}
                sm={12}
                style={{ marginLeft: '2rem', marginTop: '1rem' ,paddingLeft:"32px"}}
              >
                <FormProvider {...methods}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      methods.handleSubmit(submit)();
                    }}
                  >
                    <SignatureComponent
                      showContent={showContent}
                      updatedAt={updatedAt}
                      BlockNames={BlockNames}
                      editableBlock={editableBlock}
                      setEditableBlock={setEditableBlock}
                      loadingQuery={loadingQuery}
                      setEditMode={setEditMode}
                      setOpen={setOpen}
                      loadingSignature={loadingSignature}
                      options={options}
                      handleSignatureSelect={handleSignatureSelect}
                      checked={checked}
                      setSelectedSignature={setSelectedSignature}
                      signatures={signatures}
                      commonProps={commonProps}
                      loading={loading}
                      sigCanvasRef={sigCanvasRef}
                      handleChange={handleChange}
                      selectedSignature={selectedSignature}
                    />
                    <InfoBlock
                      title={t('Specialty')}
                      description={''}
                      content={`${dataUser?.providerSettings?.specialty ?? '-'
                        }`}
                      blockName={BlockNames.specialty}
                      isEditable={editableBlock === BlockNames.specialty}
                      editableBlock={editableBlock}
                      setEditableBlock={setEditableBlock}
                      loading={loadingQuery}
                      disabledButton={loadingMutation}
                      buttonLoading={loadingMutation}
                    >
                      <Skeleton loading={loadingQuery}>
                        <Input
                          type="select"
                          name="specialty"
                          data-cy="gender-input"
                          variant="filled"
                          inputRef={commonProps.register}
                          {...commonProps}
                          options={[
                            'Psychology',
                            'Psychiatry',
                            'Psychiatric - Mental Health Nursing',
                            'Behavioral health therapy',
                            'Physical therapy',
                            'Occupational therapy',
                            'Massage therapy',
                            'Social work',
                            'Mariage and family therapy',
                            'Acupuncture',
                            'Lactation consulting',
                            'Counseling',
                            'Dietetics or nutrition counseling',
                            'Chiropractic',
                            'Applied behavior analysis',
                            'Speech-language pathology',
                            'Substance use counseling',
                            'Other',
                          ]}
                        />
                      </Skeleton>
                    </InfoBlock>
                    <InfoBlock
                      title={'NPI number'}
                      description={''}
                      content={`${dataUser?.providerSettings?.npi ?? '-'}`}
                      blockName={BlockNames.npi}
                      isEditable={editableBlock === BlockNames.npi}
                      editableBlock={editableBlock}
                      setEditableBlock={setEditableBlock}
                      loading={loadingQuery}
                      disabledButton={loadingMutation}
                      buttonLoading={loadingMutation}
                    >
                      <Skeleton loading={loadingQuery}>
                        <Input
                          type="text"
                          name="npi"
                          data-cy="gender-input"
                          variant="filled"
                          inputRef={commonProps.register}
                        // {...commonProps}
                        />
                      </Skeleton>
                    </InfoBlock>
                    <InfoBlock
                      title={'License number'}
                      description={''}
                      content={`${dataUser?.providerSettings?.licenseNumber ?? '-'
                        }`}
                      blockName={BlockNames.license}
                      isEditable={editableBlock === BlockNames.license}
                      editableBlock={editableBlock}
                      setEditableBlock={setEditableBlock}
                      loading={loadingQuery}
                      disabledButton={loadingMutation}
                      buttonLoading={loadingMutation}
                    >
                      <Skeleton loading={loadingQuery}>
                        <Input
                          type="text"
                          name="licenseNumber"
                          data-cy="gender-input"
                          variant="filled"
                          inputRef={commonProps.register}
                          {...commonProps}
                        />
                      </Skeleton>
                    </InfoBlock>
                    <InfoBlock
                      title={'Taxonomy code'}
                      description={''}
                      content={`${dataUser?.providerSettings?.taxonomy ?? '-'}`}
                      blockName={BlockNames.taxonomy}
                      isEditable={editableBlock === BlockNames.taxonomy}
                      editableBlock={editableBlock}
                      setEditableBlock={setEditableBlock}
                      loading={loadingQuery}
                      disabledButton={loadingMutation}
                      buttonLoading={loadingMutation}
                    >
                      <Skeleton loading={loadingQuery}>
                        <Input
                          type="text"
                          name="taxonomy"
                          data-cy="gender-input"
                          variant="filled"
                          inputRef={commonProps.register}
                          {...commonProps}
                        />
                      </Skeleton>
                    </InfoBlock>
                  </form>
                </FormProvider>
              </Grid>,
              <>
                {' '}
                <Grid
                  item
                  xs={12}
                  xl={isEditable ? 12 : 5}
                  lg={isEditable ? 12 : 7}
                  md={12}
                  sm={12}
                  style={{ marginLeft: '2rem', marginTop: '1rem' ,paddingLeft:"32px"}}
                  >
                  <FormProvider {...methods}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        methods.handleSubmit(submit)();
                      }}
                    >
                      <InfoBlock
                        title={'Practice name'}
                        description={''}
                        content={`${
                          dataUser?.organizationSettings?.practiceName ?? '-'
                        }`}
                        blockName={BlockNames.practiceName}
                        isEditable={editableBlock === BlockNames.practiceName}
                        editableBlock={editableBlock}
                        setEditableBlock={setEditableBlock}
                        loading={loadingQuery}
                        disabledButton={loadingMutation}
                        buttonLoading={loadingMutation}
                      >
                        <Skeleton loading={loadingQuery}>
                          <Input
                            type="text"
                            name="practiceName"
                            data-cy="gender-input"
                            variant="filled"
                            inputRef={commonProps.register}
                            {...commonProps}
                          />
                        </Skeleton>
                      </InfoBlock>

                      <InfoBlock
                        title={'NPI number'}
                        description={''}
                        content={`${dataUser?.organizationSettings?.npiOrg ?? '-'
                          }`}
                        blockName={BlockNames.npiOrg}
                        isEditable={editableBlock === BlockNames.npiOrg}
                        editableBlock={editableBlock}
                        setEditableBlock={setEditableBlock}
                        loading={loadingQuery}
                        disabledButton={loadingMutation}
                        buttonLoading={loadingMutation}
                      >
                        <Skeleton loading={loadingQuery}>
                          <Input
                            type="text"
                            name="npiOrg"
                            data-cy="gender-input"
                            variant="filled"
                            inputRef={commonProps.register}
                            {...commonProps}
                          />
                        </Skeleton>
                      </InfoBlock>
                      <InfoBlock
                        title={'Taxonomy code'}
                        description={''}
                        content={`${dataUser?.organizationSettings?.taxonomyOrg ?? '-'
                          }`}
                        blockName={BlockNames.taxonomyOrg}
                        isEditable={editableBlock === BlockNames.taxonomyOrg}
                        editableBlock={editableBlock}
                        setEditableBlock={setEditableBlock}
                        loading={loadingQuery}
                        disabledButton={loadingMutation}
                        buttonLoading={loadingMutation}
                      >
                        <Skeleton loading={loadingQuery}>
                          <Input
                            type="text"
                            name="taxonomyOrg"
                            data-cy="gender-input"
                            variant="filled"
                            inputRef={commonProps.register}
                            {...commonProps}
                          />
                        </Skeleton>
                      </InfoBlock>
                      <InfoBlock
                        title={'License number'}
                        description={''}
                        content={`${dataUser?.organizationSettings?.licenseNumberOrg ??
                          '-'
                          }`}
                        blockName={BlockNames.licenseOrg}
                        isEditable={editableBlock === BlockNames.licenseOrg}
                        editableBlock={editableBlock}
                        setEditableBlock={setEditableBlock}
                        loading={loadingQuery}
                        disabledButton={loadingMutation}
                        buttonLoading={loadingMutation}
                      >
                        <Skeleton loading={loadingQuery}>
                          <Input
                            type="text"
                            name="licenseNumberOrg"
                            data-cy="gender-input"
                            variant="filled"
                            inputRef={commonProps.register}
                            {...commonProps}
                          />
                        </Skeleton>
                      </InfoBlock>
                      <InfoBlock
                        title={'Default place of service'}
                        description={''}
                        content={`${dataUser?.organizationSettings?.DPS ?? '-'
                          }`}
                        blockName={BlockNames.DPS}
                        isEditable={editableBlock === BlockNames.DPS}
                        editableBlock={editableBlock}
                        setEditableBlock={setEditableBlock}
                        loading={loadingQuery}
                        disabledButton={loadingMutation}
                        buttonLoading={loadingMutation}
                      >
                        <Skeleton loading={loadingQuery}>
                          <Input
                            type="select"
                            name="DPS"
                            data-cy="gender-input"
                            variant="filled"
                            inputRef={commonProps.register}
                            {...commonProps}
                            options={['', '', '', '']}
                          />
                        </Skeleton>
                      </InfoBlock>
                    </form>
                  </FormProvider>
                </Grid>
                ,
              </>,
            ]}
            customValue={customValue}
            setCustomValue={setCustomValue}
          />
        </Box>
      ) : (
        !isPracticeSetting && (
          <Grid
            item
            xs={12}
            xl={isEditable ? 12 : 5}
            lg={isEditable ? 12 : 7}
            md={12}
            sm={12}
          >
            <FormProvider {...methods}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  methods.handleSubmit(submit)();
                }}
              >
                <SignatureComponent
                  showContent={showContent}
                  updatedAt={updatedAt}
                  BlockNames={BlockNames}
                  editableBlock={editableBlock}
                  setEditableBlock={setEditableBlock}
                  loadingQuery={loadingQuery}
                  setEditMode={setEditMode}
                  setOpen={setOpen}
                  loadingSignature={loadingSignature}
                  options={options}
                  handleSignatureSelect={handleSignatureSelect}
                  checked={checked}
                  setSelectedSignature={setSelectedSignature}
                  signatures={signatures}
                  commonProps={commonProps}
                  loading={loading}
                  sigCanvasRef={sigCanvasRef}
                  handleChange={handleChange}
                  selectedSignature={selectedSignature}
                />
              </form>
            </FormProvider>
          </Grid>
        )
      )}
    </Grid>
  );
};

const SIGNATURE = gql`
  mutation generateSignature($name: String) {
    generateSignature(name: $name)
  }
`;

const SET_SIGNATURE = gql`
  mutation setSignature($signatureInput: SignatureInput) {
    setSignature(signatureInput: $signatureInput)
  }
`;

export default PracticeInformation;
