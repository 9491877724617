import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Chip, Tooltip, TextField } from '@material-ui/core';
import { getTagColor } from 'utils/objectUtils';
import { useStyles } from './styles';
import TagsEditBar, { Itag } from './TagEdit';
import { useTag } from '../../ManageTags/useTag';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import { DOCTOR_TEMPLATES_BY_FORM } from '../ManageTemplates';
import { gql, useMutation } from '@apollo/client';

interface Iprops {
  tags: Itag[];
  editMode: boolean;
  width?: number;
  setEditMode: (value: boolean) => void;
  isSelected: boolean;
  templateId: string;
}

const TagsCell = ({
  tags,
  editMode,
  width = 300,
  setEditMode,
  isSelected,
  templateId,
}: Iprops) => {
  const [tagState, setTagState] = useState([]);

  const [addTagToTemplate] = useMutation(ADD_TAG_MUTATION);

  const { addTag, createLoading, data } = useTag();

  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [visibleTagsCount, setVisibleTagsCount] = useState(0);
  const tagWidthsRef = useRef<number[]>([]);
  const tooltipChipWidth = 40;
  const [init, setInit] = useState(true);

  const calculateVisibleTags = useCallback(() => {
    if (!containerRef?.current || tagWidthsRef?.current?.length === 0) return;

    const containerWidth = containerRef.current.offsetWidth;
    const availableWidth = containerWidth - tooltipChipWidth;
    let accumulatedWidth = 0;
    let count = 0;
    const chipGap = 3;

    for (let tagWidth of tagWidthsRef.current) {
      const widthWithGap = tagWidth + chipGap;

      if (accumulatedWidth + widthWithGap > availableWidth) break;
      accumulatedWidth += widthWithGap;
      count += 1;
    }

    if (
      count < tagWidthsRef.current.length &&
      accumulatedWidth + tooltipChipWidth > containerWidth
    ) {
      count = Math.max(count - 1, 0);
    }

    setVisibleTagsCount(count);
  }, [width, tooltipChipWidth]);

  const onAddTag = async (newTag, inNew) => {
    const isTagExist =
      tagState && tagState?.some((tag) => tag._id === newTag._id);
    if (!isTagExist) {
      if (inNew) {
        const addedTag = await addTag({
          variables: {
            tagInput: {
              tag: newTag.tag,
              color: newTag.color,
            },
          },
        });
        setTagState((prev) => [...prev, addedTag.data.addTag]);
      } else {
        setTagState((prev) => [...prev, newTag]);
      }
    }
  };

  useEffect(() => {
    const measureDiv = document.createElement('div');
    measureDiv.className = classes.hiddenTagMeasure;
    document.body.appendChild(measureDiv);

    if (tagState?.length > 0) {
      tagWidthsRef.current = tagState.map((tag) => {
        const chip = document.createElement('div');
        chip.className = classes.chip;
        chip.style.backgroundColor = getTagColor(tag.color).backgroundColor;
        chip.style.border = getTagColor(tag.color).border;
        chip.style.color = getTagColor(tag.color).text;
        chip.textContent = tag.tag;
        measureDiv.appendChild(chip);
        const width = chip.offsetWidth + 8;
        measureDiv.removeChild(chip);
        return width;
      });

      document.body.removeChild(measureDiv);
      calculateVisibleTags();
    }
  }, [tagState, classes, calculateVisibleTags]);

  useEffect(() => {
    if ((!!tags && tags.length > 0, !!init)) {
      setTagState(tags);
      setInit(false);
    }
  }, [tags, init]);

  useEffect(() => {
    const tagIds = tagState.map((tag) => tag._id);
    if (tagIds.length > 0) {
      addTagToTemplate({
        variables: {
          templateId,
          tags: tagIds,
        },
        refetchQueries: [DOCTOR_TEMPLATES, DOCTOR_TEMPLATES_BY_FORM],
      });
    }
  }, [tagState]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        dropdownRef.current &&
        !containerRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target) &&
        isSelected &&
        editMode
      ) {
        setEditMode(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editMode, isSelected, setEditMode]);

  const handleDeleteTag = async (tagId) => {
    await setTagState((prev) => {
      const newTags = prev.filter((tag) => tag._id !== tagId);
      const tagIds = newTags.map((tag) => tag._id);
      addTagToTemplate({
        variables: {
          templateId,
          tags: tagIds,
        },
        refetchQueries: [DOCTOR_TEMPLATES, DOCTOR_TEMPLATES_BY_FORM],
      });
      return newTags;
    });
  };

  return (
    <div
      className={classes.tagsContainer}
      ref={containerRef}
      style={{
        overflowY: editMode ? 'auto' : 'hidden',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {!editMode && (
        <Box display="flex" flexWrap="nowrap" style={{ overflow: 'hidden' }}>
          {tagState?.slice(0, visibleTagsCount).map((tag, index) => (
            <Chip
              key={index}
              label={tag.tag}
              className={classes.chip}
              style={{
                backgroundColor: getTagColor(tag.color).backgroundColor,
                border: getTagColor(tag.color).border,
                color: getTagColor(tag.color).text,
              }}
              size="small"
            />
          ))}
          {visibleTagsCount < tagState?.length && (
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              title={
                <div className={classes.tooltipContent}>
                  {tagState?.slice(visibleTagsCount)?.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag.tag}
                      className={`${classes.tooltipChip} ${classes.tooltipChipSpacing}`}
                      style={{
                        backgroundColor: getTagColor(tag.color).backgroundColor,
                        border: getTagColor(tag.color).border,
                        color: getTagColor(tag.color).text,
                        width: 'fit-content',
                      }}
                      size="small"
                    />
                  ))}
                </div>
              }
              arrow
            >
              <Chip label="..." size="small" className={classes.seeMoreChip} />
            </Tooltip>
          )}
        </Box>
      )}

      {editMode && (
        <TagsEditBar
          tags={tagState}
          onAddTag={onAddTag}
          onDeleteTag={handleDeleteTag}
          allTags={data?.doctorTags.filter(
            (tag) => !tagState.some((t) => t._id === tag._id)
          )}
          dropdownAnchor={containerRef.current}
          dropdownRef={dropdownRef}
          createLoading={createLoading}
        />
      )}
    </div>
  );
};

export default TagsCell;

const ADD_TAG_MUTATION = gql`
  mutation addTagToTemplate($templateId: ID, $tags: [ID]) {
    addTagToTemplate(templateId: $templateId, tags: $tags) {
      _id
    }
  }
`;
