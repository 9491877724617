import React from 'react';
import { Box, Grid } from '@material-ui/core';
import useStyles from '../style';
import Tabs from 'components/ui/Tabs';
import Text from 'Text';
import Input from 'components/ui/Inputs';
import AllTemplates from './templatesTabs/AllTemplates';
import { TemplateType } from './templatesTabs/Columns';
import { templates } from './templatesTabs/constants';
import SearchBar from './templatesTabs/SearchBar';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import Button from 'components/ui/Button';
import NoteTakingModal from 'components/ui/Modal/NoteTakingModal';
import NewForm from 'pages/PatientActivities/Questionaires/NewForm';
import {
  templateEnum,
  useEditorContext,
} from 'components/Editorjs/EditorContextProvider';
import { gql, useQuery } from '@apollo/client';

function ManageTemplates() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [filteredTemplates, setFilteredTemplates] = React.useState(templates);
  const {
    setBlocks,
    handleChangeText,
    handleSelectedTemplate,
    selectedTemplate,
  } = useEditorContext();
  const handleSearch = (query: string) => {
    const filtered = templates.filter((template) => {
      const nameMatches = template.name
        .toLowerCase()
        .includes(query.toLowerCase());
      const tagMatches = template.tags.some((tag) =>
        tag.title.toLowerCase().includes(query.toLowerCase())
      );
      return nameMatches || tagMatches;
    });

    setFilteredTemplates(filtered);
    setTab(0);
  };
  const [formType, setFormType] = React.useState('');
  const [type, setType] = React.useState('');
  const [isAll, setIsAll] = React.useState(true);

  React.useEffect(() => {
    if (tab === 0) {
      setIsAll(true);
      setPage(1);
      handleSelectedTemplate(templateEnum.assessmentForm);
    }
    if (tab === 2) {
      setFormType(templateEnum.intakeForm);
      setType('form');
      setIsAll(false);
      setPage(1);
      handleSelectedTemplate(templateEnum.intakeForm);
    }
    if (tab === 3) {
      setFormType(templateEnum.assessmentForm);
      setType('form');
      setIsAll(false);
      setPage(1);
      handleSelectedTemplate(templateEnum.assessmentForm);
    }
    if (tab === 1) {
      setFormType(templateEnum.noteTaking);
      setType('note');
      setIsAll(false);
      setPage(1);
      handleSelectedTemplate(templateEnum.noteTaking);
    }
    if (tab === 4) {
      setFormType(templateEnum.other);
      setType('other');
      setIsAll(false);
      setPage(1);
      handleSelectedTemplate(templateEnum.other);
    }
  }, [tab]);
  const [page, setPage] = React.useState(1);
  const limit = 10;
  const { data, loading, refetch } = useQuery(DOCTOR_TEMPLATES_BY_FORM, {
    variables: {
      formType,
      type,
      isAll,
      page,
      limit,
    },
  });
  React.useEffect(() => {
    refetch();
  }, [data]);
  const concatenatreTemplate = [
    ...(data?.doctorTemplateByTypeForm?.result?.map((el) => {
      return {
        ...el,
        name: el?.title,
        type: el?.formType,
      };
    }) || []),
  ];
  function handleOpenModal() {
    setOpen(true);
  }
  function handleCloseModal() {
    setOpen(false);
  }

  return (
    <Grid container spacing={2} className={classes.gridContainerBilling}>
      <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
        <Text
          i18nKey="Templates"
          style={{
            fontFamily: 'Inter, sans-serif',
            fontStyle: 'normal',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '20px',
            color: '#344054',
          }}
        >
          Templates
        </Text>
        {/* <Input
          type="text"
          variant="filled"
          style={{
            height: '40px',
            marginBlock: '1rem',
            width: '320px',
          }}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          placeholder="Search all templates and tags"
        /> */}
        <Box mb={4}>
          <SearchBar templates={concatenatreTemplate} onSearch={handleSearch} />
        </Box>
        <Tabs
          tabsMode="simple"
          tabsBar={[
            'All templates',
            'Note-taking',
            'Intake',
            'Assessments',
            'Other',
          ]}
          tabClassHeader={classes.headerTab}
          componetAlignedRightWithTabs={
            <Button
              className={classes.addBtn}
              onClick={() => {
                setBlocks(null, false, '');
                handleChangeText('Untitled template');
                handleOpenModal();
                handleSelectedTemplate(selectedTemplate);
              }}
            >
              <AddTaskIcon
                style={{
                  paddingRight: '6px',
                }}
              />
              <Text i18nKey="add">Add</Text>
            </Button>
          }
          customValue={tab}
          setCustomValue={setTab}
          tabsContent={[
            <AllTemplates
              type={TemplateType.all}
              loading={loading}
              data={data?.doctorTemplateByTypeForm?.result}
              handleOpenModal={handleOpenModal}
              page={page}
              setPage={setPage}
              limit={limit}
              count={data?.doctorTemplateByTypeForm?.count}
            />,
            <AllTemplates
              type={TemplateType.notes}
              loading={loading}
              data={data?.doctorTemplateByTypeForm?.result}
              handleOpenModal={handleOpenModal}
              page={page}
              setPage={setPage}
              limit={limit}
              count={data?.doctorTemplateByTypeForm?.count}
            />,
            <AllTemplates
              type={TemplateType.intake}
              loading={loading}
              data={data?.doctorTemplateByTypeForm?.result}
              handleOpenModal={handleOpenModal}
              page={page}
              setPage={setPage}
              limit={limit}
              count={data?.doctorTemplateByTypeForm?.count}
            />,
            <AllTemplates
              type={TemplateType.assessment}
              data={data?.doctorTemplateByTypeForm?.result}
              loading={loading}
              handleOpenModal={handleOpenModal}
              page={page}
              setPage={setPage}
              limit={limit}
              count={data?.doctorTemplateByTypeForm?.count}
            />,
            <AllTemplates
              type={TemplateType.other}
              data={data?.doctorTemplateByTypeForm?.result}
              loading={loading}
              handleOpenModal={handleOpenModal}
              page={page}
              setPage={setPage}
              limit={limit}
              count={data?.doctorTemplateByTypeForm?.count}
            />,
          ]}
        />
      </Grid>
      <NoteTakingModal
        open={open}
        handleClose={() => {
          handleCloseModal();
          handleChangeText('Untitled template');
        }}
      >
        <NewForm
          handleBack={() => {
            handleCloseModal();
            handleChangeText('Untitled template');
          }}
        />
      </NoteTakingModal>
    </Grid>
  );
}

export default ManageTemplates;
export const DOCTOR_TEMPLATES_BY_FORM = gql`
  query doctorTemplateByTypeForm(
    $formType: String
    $type: String
    $isAll: Boolean
    $page: Int
    $limit: Int
  ) {
    doctorTemplateByTypeForm(
      formType: $formType
      type: $type
      isAll: $isAll
      page: $page
      limit: $limit
    ) {
      result {
        _id
        title
        description
        lastOpenedAt
        isDefault
        doctor {
          _id
        }
        formType
        blocks
        type
        isAutoSent
        tags {
          _id
          tag
          color
        }
      }
      count
    }
  }
`;
