import HorizantaleLine from 'pages/FormsPage/HorizantaleLine';
import React from 'react';
import ReactDOM from 'react-dom';

interface TimelineData {
  events?: Array<any>;
  questions: Array<any>;
  question?: any;
}

interface TimelineConfig {}

interface TimelineConstructorProps {
  data: TimelineData;
  config?: TimelineConfig;
  api: any;
  readOnly: boolean;
}

export default class LineBreak {
  private api: any;
  private readOnly: boolean;
  private data: TimelineData;
  private CSS: { wrapper: string };
  private nodes: { holder: HTMLElement | null };

  static get toolbox() {
    return {
      icon: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 9V5.8C3 4.11984 3 3.27976 3.32698 2.63803C3.6146 2.07354 4.07354 1.6146 4.63803 1.32698C5.27976 1 6.11984 1 7.8 1H14.2C15.8802 1 16.7202 1 17.362 1.32698C17.9265 1.6146 18.3854 2.07354 18.673 2.63803C19 3.27976 19 4.11984 19 5.8V9" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19 15L19 16.2C19 17.8802 19 18.7202 18.673 19.362C18.3854 19.9265 17.9265 20.3854 17.362 20.673C16.7202 21 15.8802 21 14.2 21L7.8 21C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2L3 15" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1 12L21 12" stroke="#475467" stroke-width="2" stroke-linecap="round"/>
      </svg>
      `,
      title: 'Horizontal line',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }: TimelineConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    const defaultQuestion = {
      question: 'Untitled question',
      type: 'HorizantaleLine',
      options: ['Option 1', 'Option 2'],
      description: '',
      required: false,
      isDescription: false,
      range: [1, 3],
    };

    this.data = {
      questions: data.questions || [defaultQuestion],
    };

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    };

    this.nodes = {
      holder: null,
    };
  }
  // private setQuestions = (newQuestions: Array<any>) => {
  //   console.log(newQuestions, 'newQuestions');
  //   this.data = {
  //     ...this.data,
  //     questions: newQuestions,
  //   };
  // };

  private setQuestions = (
    newQuestionsOrUpdater: Array<any> | ((questions: Array<any>) => Array<any>)
  ) => {
    if (typeof newQuestionsOrUpdater === 'function') {
      // Handle functional update
      const updatedQuestions = (
        newQuestionsOrUpdater as (questions: Array<any>) => Array<any>
      )(this.data.questions);
      this.data = {
        ...this.data,
        questions: updatedQuestions,
      };
    } else {
      // Handle direct array update
      this.data = {
        ...this.data,
        questions: newQuestionsOrUpdater,
      };
    }
    this.updateDom();
  };
  private deleteBlock = (index: number) => {
    this.api.blocks.delete(index);
  };
  private getBlockIndex = () => {
    return this.api.blocks.getCurrentBlockIndex();
  };
  private updateDom() {
    if (this.nodes.holder) {
      ReactDOM.render(<HorizantaleLine />, this.nodes.holder);
    }
  }
  // private setQuestionssss = (
  //   updateFn: (questions: Array<any>) => Array<any>
  // ) => {
  //   // Check if the input is a function
  //   if (typeof updateFn === 'function') {
  //     // Call the function with the current questions to get the updated questions
  //     const updatedQuestions = updateFn(this.data.questions);
  //     console.log(updatedQuestions, 'updatedQuestions');
  //     // Update the data with the new questions array
  //     this.data = {
  //       ...this.data,
  //       questions: updatedQuestions,
  //     };

  //     console.log(updatedQuestions, 'updated questions');
  //   } else {
  //     console.error('Expected a function that returns an array of questions');
  //   }
  // };

  private changeQuestion = (addedQuestion: string) => {
    this.data = {
      ...this.data,
      question: { ...this.data.question, question: addedQuestion },
    };
  };

  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    // const onDataChange = (newData: TimelineData) => {
    //   this.data = {
    //     ...newData,
    //   };
    // };

    ReactDOM.render(<HorizantaleLine />, rootNode);

    return this.nodes.holder;
  }

  save(): TimelineData {
    return this.data;
  }
}
