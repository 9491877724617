import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import * as yup from 'yup';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ReactComponent as StripePayoutLogo } from 'assets/StripePayout.svg';
import { ReactComponent as BankLogo } from 'assets/BankLogo.svg';
import Button from 'components/ui/Button';
import Skeleton from 'components/ui/Skeleton';
import { ReactComponent as StripeManage } from 'assets/ManageStripeIco.svg';
import { useTranslation } from 'react-i18next';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { GET_DOCTOR } from 'pages/DoctorProfilePage/graphql';
import UnsupportedCountry from './UnsupportedCountry';

export const useStyles = makeStyles<Theme>((theme) => ({
  marginTop: { marginTop: '12.04px' },
  input: {
    width: '100%',
    background: '#F8FAFB',
    borderRadius: '8px',
  },
  label: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '1rem',
    lineHeight: '23px',
    color: '#425364',
  },
  addBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    marginTop: theme.spacing(1),

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  canceledBox: {
    padding: theme.spacing(2),
    backgroundColor: '#FEF3F2',
    borderLeft: '5px solid #B42318',
  },
  trialLabel: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '18px',
    color: '#182230',
  },
  trialDescription: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    color: '#182230',
  },
  textTitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    color: '#344054',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '20px',
    color: '#425364',
  },
  description: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    color: '#425364',
  },
  btnAdd: {
    backgroundColor: '#1F61DC',
    borderRadius: '5px',
    padding: '5px 25px',
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#1D31AA',
    },
  },
  btn: {
    backgroundColor: '#1F61DC',
    borderRadius: '5px',
    padding: '5px 25px',
    marginTop: theme.spacing(2),
    color: 'white',
    textTransform: 'unset',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#1F61DC',
    },
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
    marginLeft: '3px',
  },
  rootRadio: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconRadio: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'white',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIconRadio: {
    backgroundColor: 'white',
    border: '1px solid #39B54A',
    color: 'green',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage: 'radial-gradient(#39B54A,#39B54A 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#f5f8fa',
    },
  },
  table: {
    backgroundColor: 'red',
  },
  title: {
    fontWeight: 700,
    fontSize: '20px',
    color: '#344054',
    fontFamily: 'Inter',
  },
  stripeDescription: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#000000',
    fontFamily: 'Inter',
    marginTop: '16px',
  },
  labelDetails: {
    fontWeight: 500,
    fontSize: '18px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  descriptionText: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
    fontFamily: 'Inter',
  },
  bankName: {
    fontWeight: 600,
    fontSize: '18px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  routing: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
    fontFamily: 'Inter',
  },
  routing_number: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#475467',
    fontFamily: 'Inter',
  },
  payoutStatus: {
    fontWeight: 500,
    fontSize: '18px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  payoutValue: {
    fontWeight: 400,
    fontSize: '16px',
    color: ({ payouts_enabled }: { payouts_enabled: string }) =>
      payouts_enabled ? '#079455' : 'red',
    fontFamily: 'Inter',
  },
  chargeValue: {
    fontWeight: 400,
    fontSize: '16px',
    color: ({ charges_enabled }: { charges_enabled: string }) =>
      charges_enabled ? '#079455' : 'red',
    fontFamily: 'Inter',
  },
  btnStripe: {
    backgroundColor: '#147AF3',
    borderRadius: '8px',
    height: '36px',
    border: '1px solid #147AF3',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    width: '100%',
    color: 'white',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#147AF3',
    },
  },
}));

const Payouts = () => {
  const { doctor } = useAuth();

  const { data, loading } = useQuery(STRIPE_ACCOUNT, {
    variables: { doctor: doctor?._id },
  });
  const { last4, bank_name, routing_number, payouts_enabled, charges_enabled } =
    data?.doctorStripeAccount || {};
  const classes = useStyles({ charges_enabled, payouts_enabled });
  const { t } = useTranslation();
  const [payoutCreation] = useMutation(PAYOUT_CREATION);
  async function handlePayout() {
    const result = await payoutCreation();
    const url = result?.data?.payoutCreation;
    window.location.href = url;
  }
  const theme = useTheme();
  const isLgUnder1300 = useMediaQuery(theme.breakpoints.down(1300));
  const {
    data: dataDoctor,
    loading: loadingDoctor,
    error,
  } = useQuery(GET_DOCTOR, {
    variables: { DoctorId: doctor._id },
  });
  return (
    <Grid container>
      {dataDoctor?.doctor?.stripeAccountId ? (
        <Grid item xl={4} lg={isLgUnder1300 ? 8 : 6} md={12}>
          <Typography className={classes.title}>
            {t('Payout settings')}
          </Typography>
          <Box width="100%" px={4} mt={4}>
            <StripePayoutLogo />
            <Box>
              <Typography className={classes.stripeDescription}>
                {t('stripeProcedure')}
              </Typography>
            </Box>
            <Box mt={4}>
              {bank_name && routing_number && last4 && (
                <>
                  <Typography className={classes.labelDetails}>
                    {t('Payouts details')}
                  </Typography>

                  <Typography className={classes.descriptionText}>
                    {t('Client payments will be sent to this account')}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={2}
                    pb={3}
                    borderBottom="1px solid #F4F2EE"
                  >
                    <BankLogo />
                    <Box
                      ml={1}
                      display="flex"
                      flexDirection="column"
                      width="100%"
                    >
                      <Skeleton loading={loading}>
                        <Typography className={classes.bankName}>
                          {bank_name}
                        </Typography>
                      </Skeleton>
                      <Box display="flex" alignItems="center" width="100%">
                        <Box display="flex" alignItems="center">
                          <Skeleton loading={loading}>
                            <Typography className={classes.routing}>
                              Routing No.{' '}
                            </Typography>
                          </Skeleton>
                          <Skeleton loading={loading}>
                            <Typography className={classes.routing_number}>
                              {routing_number}
                            </Typography>
                          </Skeleton>
                        </Box>
                        <Box display="flex" alignItems="center" ml={1}>
                          <Skeleton loading={loading}>
                            <Typography className={classes.routing}>
                              {t('Account No.')}{' '}
                            </Typography>
                          </Skeleton>
                          <Skeleton loading={loading}>
                            <Typography className={classes.routing_number}>
                              ****{last4}
                            </Typography>
                          </Skeleton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              <Box py={3} borderBottom="1px solid #F4F2EE">
                <Typography className={classes.payoutStatus}>
                  {t('Payout status')}
                </Typography>
                <Skeleton loading={loading}>
                  <Typography className={classes.payoutValue}>
                    {payouts_enabled ? t('Enabled') : t('Disabled')}
                  </Typography>
                </Skeleton>
              </Box>
              <Box py={3} borderBottom="1px solid #F4F2EE">
                <Typography className={classes.payoutStatus}>
                  {t('Charges status')}
                </Typography>
                <Skeleton loading={loading}>
                  <Typography className={classes.chargeValue}>
                    {charges_enabled ? t('Enabled') : t('Disabled')}
                  </Typography>
                </Skeleton>
              </Box>
            </Box>
            <Box mt={4}>
              <Button className={classes.btnStripe} onClick={handlePayout}>
                <StripeManage style={{ marginRight: '8px' }} />
                {t('Manage payments')}
              </Button>
            </Box>
          </Box>
        </Grid>
      ) : (
        <UnsupportedCountry
          classes={classes}
          loading={loadingDoctor}
          data={dataDoctor?.doctor}
        />
      )}
    </Grid>
  );
};

export const STRIPE_ACCOUNT = gql`
  query doctorStripeAccount($doctor: ID) {
    doctorStripeAccount(doctor: $doctor) {
      last4
      bank_name
      routing_number
      payouts_enabled
      charges_enabled
    }
  }
`;

const PAYOUT_CREATION = gql`
  mutation payoutCreation {
    payoutCreation
  }
`;

export default Payouts;
