import { gql, useMutation } from '@apollo/client';
import { GET_PATIENT } from 'pages/AppointmentPage/AppointmentPage';

const EDIT_NOTES = gql`
  mutation editNotes($notesId: ID!, $notesInput: NoteInput!) {
    editNotes(notesId: $notesId, notesInput: $notesInput) {
      _id
    }
  }
`;

const useEditNotes = (patientId, appointmentId) => {
  return useMutation(EDIT_NOTES, {
    refetchQueries: [
      { query: GET_PATIENT, variables: { patientId, appointmentId } },
    ],
    awaitRefetchQueries: false,
  });
};

export default useEditNotes;
