import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from '../styles';
import { Controller } from 'react-hook-form';
import Input from 'components/ui/Inputs';
import PhoneInput from 'react-phone-input-2';

interface IFormPhone {
  register: any;
  control: any;
  errors: any;
  question: string;
  name: string;
  handleInputChange: any;
  questionIndex: number;
  responses?: string;
}

export const FormPhone = ({
  register,
  control,
  errors,
  question,
  name,
  handleInputChange,
  questionIndex,
  responses,
}: IFormPhone) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.formQuestion}>{question}</Typography>
      <PhoneInput
        inputStyle={{
          height: '2.5rem',
          maxWidth: '100%',
          marginBottom: '9px',
          borderRadius: '8px',
        }}
        inputProps={{
          style: {
            fontSize: '14px',
            width: '100%',
            borderRadius: '8px',
            height: '2.5rem',
          },
        }}
        onChange={(value) => {
          handleInputChange(questionIndex, value);
        }}
        value={responses}
      />
    </Box>
  );
};
