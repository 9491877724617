import React from 'react';
import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as ViewIcon } from 'assets/ArrowRightActivity.svg';
import Button from 'components/ui/Button';
import dayjs from 'dayjs';
import { types } from './RecentActivityCard';
import BasicModal from 'components/ui/Modal';
import FormPage from 'pages/FormsPage';

export const FormRecentCard = ({
  id,
  type,
  assignedAt,
  title,
  description,
  questions,
  doctorId,
  patientId,
  patientResponseDate,
  blocks,
  isPatientSigned,
}) => {
  const classes = useStyles({});
  const [openSlide, setOpenSlide] = React.useState(false);
  const [form, setForms] = React.useState(null);

  const handleView = (id: string) => {
    setForms({
      formName: title,
      formDescription: description,
      formQuestions: questions,
      formId: id,
      doctorId: doctorId,
      patientId: patientId,
      previewMode: true,
      blocks: blocks,
      isPatientSigned: isPatientSigned,
    });
    setOpenSlide(true);
  };

  return (
    <>
      <Box className={classes.cardRoot} p={2}>
        <Box textAlign="center" mt={2}>
          <Box mb={1.5}>{types[type]?.icon}</Box>
          <Typography className={classes.cardTitle}>{title}</Typography>
          <Typography className={classes.cardDate}>
            Completed on {dayjs(patientResponseDate).format('D MMM YYYY')}
          </Typography>
        </Box>
        <Button className={classes.viewBtn} onClick={handleView}>
          <ViewIcon />
        </Button>
      </Box>
      <BasicModal
        open={openSlide}
        onClose={() => setOpenSlide(false)}
        handleClose={() => setOpenSlide(false)}
        isRegularModal
        isSlide
        divider
        title={''}
        titlePadding="0rem"
      >
        <FormPage isNotes={false} forms={form} />
      </BasicModal>
    </>
  );
};
